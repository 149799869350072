import { Injectable, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Address, DisputeDrpCategoryCd, DisputeStatusCd } from '@xpo-ltl/sdk-common';
import { Dispute, InterfaceEmployee, UpdateDisputeRqst, UpsertDisputeRqst } from '@xpo-ltl/sdk-disputes';

import * as _ from 'lodash';

import { DisputeRequestReasonHelper } from '../../classes/disputes/dispute-request-reason-helper';
import {
  AddressEntryFormNamesEnum,
  AuditCompanyInformationFormNamesEnum,
  ClaimantInformationFormNamesEnum,
  DisputeDetailsFormLabelsEnum,
  DisputeDetailsFormNamesEnum,
  DisputeInformationFormNamesEnum,
  InvoiceDetailsFormNamesEnum,
} from '../../enums';
import { DebtorDetailsFormNamesEnum } from '../../enums/form-control-names/debtor-details-form-names.enum';
import { FormError } from '../../interfaces/disputes/form-error.interface';
import { DisputesRegistrationService } from './disputes-registration.service';
import { InvoiceDetailsDataSourceService } from './invoice-details-data-source.service';

@Injectable({
  providedIn: 'root',
})
export class DrpDisputesRegistrationService extends DisputesRegistrationService {
  protected invoiceDetailsDataSourceService: InvoiceDetailsDataSourceService;

  constructor(protected injector: Injector) {
    super(injector);
    this.invoiceDetailsDataSourceService = injector.get(InvoiceDetailsDataSourceService);
  }

  loadFormErrorList() {
    const errors = this.findInvalidControls(this.disputeFormGroup);
    this.sanitizeDRPErrors(errors);
    this.disputeDataService.formErrors = errors;
  }

  sanitizeDRPErrors(errors) {
    if (errors && errors.length) {
      const detailErrorExist = errors.find((x) => x.name === DisputeDetailsFormNamesEnum.FORM_GROUP_NAME);
      if (detailErrorExist) {
        let allDetailErrors = [];
        allDetailErrors = detailErrorExist.errors;

        // Excluded form Names, because they will be replaced and re-added. To Avoid duplicate errors in form-card
        const excludedFormNames = [
          DisputeDetailsFormNamesEnum.DRP_SPECIFICATIONS_FORM_GROUP_NAME,
          DisputeDetailsFormNamesEnum.AUTHORIZED_PERSON_EMP_ID,
          DisputeDetailsFormNamesEnum.AUTHORIZED_PERSON,
          DisputeDetailsFormNamesEnum.OPERATING_RATIO,
          DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE,
        ];

        let detailsErrors = allDetailErrors.filter((x) => !excludedFormNames.includes(x.name));

        if (allDetailErrors.some((x) => x.name === DisputeDetailsFormNamesEnum.DRP_SPECIFICATIONS_FORM_GROUP_NAME)) {
          const AENAMEError = {
            description: 'Required',
            errors: [],
            linkText: 'View Error',
            name: DisputeDetailsFormNamesEnum.DRP_FULL_NAME,
            title: 'AE/NAE Name',
          };
          detailsErrors = [...detailsErrors, AENAMEError];
        }

        if (allDetailErrors.some((x) => x.name === DisputeDetailsFormNamesEnum.AUTHORIZED_PERSON_EMP_ID)) {
          const firstLevelApproverError = {
            description: 'Required',
            errors: [],
            linkText: 'View Error',
            name: DisputeDetailsFormNamesEnum.AUTHORIZED_PERSON,
            title: 'First Level Approver',
          };
          detailsErrors = [...detailsErrors, firstLevelApproverError];
        }

        if (allDetailErrors.some((x) => x.name === DisputeDetailsFormNamesEnum.REQUEST_EXPLANATION)) {
          const requestExplanationError = {
            description: 'Required',
            errors: [],
            linkText: 'View Error',
            name: DisputeDetailsFormNamesEnum.REQUEST_EXPLANATION,
            title: DisputeRequestReasonHelper.isValidForClaimIntegration(this.disputeDataService.requestReasonSelected)
              ? DisputeDetailsFormLabelsEnum.OVERALL_JUSTIFICATION
              : DisputeDetailsFormLabelsEnum.REQUEST_EXPLANATION,
          };
          detailsErrors = [...detailsErrors, requestExplanationError];
        }

        if (allDetailErrors.some((x) => x.name === DisputeDetailsFormNamesEnum.OPERATING_RATIO)) {
          const operatingRatioError = {
            description: 'Required',
            errors: [],
            linkText: 'View Error',
            name: DisputeDetailsFormNamesEnum.OPERATING_RATIO,
            title: DisputeRequestReasonHelper.isValidForClaimIntegration(this.disputeDataService.requestReasonSelected)
              ? DisputeDetailsFormLabelsEnum.OPERATING_RATIO_PREV_NINE_MONTHS
              : DisputeDetailsFormLabelsEnum.OPERATING_RATIO,
          };
          detailsErrors = [...detailsErrors, operatingRatioError];
        }

        if (allDetailErrors.some((x) => x.name === DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE)) {
          const averageMonthlyError = {
            description: 'Required',
            errors: [],
            linkText: 'View Error',
            name: DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE,
            title: DisputeRequestReasonHelper.isValidForClaimIntegration(this.disputeDataService.requestReasonSelected)
              ? DisputeDetailsFormLabelsEnum.AVERAGE_MONTHLY_REVENUE_PREV_NINE_MONTHS
              : DisputeDetailsFormLabelsEnum.AVERAGE_MONTHLY_REVENUE,
          };
          detailsErrors = [...detailsErrors, averageMonthlyError];
        }

        errors.find((x) => x.name === 'DisputeDetails').errors = detailsErrors;
      }
    }
  }

  createUpsertDisputesReq(disputeStatus: DisputeStatusCd): UpsertDisputeRqst {
    let isFormValid = false;
    if (disputeStatus === DisputeStatusCd.DRAFT) {
      isFormValid = this.disputesRegistrationAsDraftFormValidity();
    } else {
      isFormValid = this.disputesRegistrationFormValidity();
    }

    const req: UpsertDisputeRqst = new UpsertDisputeRqst();
    if (isFormValid) {
      req.dispute = this.createDispute();
      req.shipments = this.createShipmentForUpsert();
      req.employeeNotify = this.createEmployeeNotifyForUpsert();
      req.itemInstId = this.getItemInstId();
      req.disputeDocuments = this.createDisputeDocuments();

      if (req.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION) {
        req.currentDebtorContact = this.createContact(DebtorDetailsFormNamesEnum.FORM_GROUP_NAME);
        if (req.currentDebtorContact) {
          req.currentDebtorContact.recordVersionNbr =
            this.dispute && this.dispute.currentDebtorContact
              ? this.dispute.currentDebtorContact.recordVersionNbr
              : undefined;
        }
      } else if (req.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_DEBTOR_CHANGE) {
        req.currentDebtorContact = this.createContact(DebtorDetailsFormNamesEnum.FORM_GROUP_NAME);
        if (req.currentDebtorContact) {
          req.currentDebtorContact.recordVersionNbr =
            this.dispute && this.dispute.newDebtorContact ? this.dispute.newDebtorContact.recordVersionNbr : undefined;
        }

        req.newDebtorContact = this.createContact(DebtorDetailsFormNamesEnum.SECONDARY_FORM_GROUP_NAME);
        if (req.newDebtorContact) {
          req.newDebtorContact.recordVersionNbr =
            this.dispute && this.dispute.newDebtorContact ? this.dispute.newDebtorContact.recordVersionNbr : undefined;
        }
      } else {
        req.currentDebtorContact = this.createContact(DebtorDetailsFormNamesEnum.FORM_GROUP_NAME);
        if (req.currentDebtorContact) {
          req.currentDebtorContact.recordVersionNbr =
            this.dispute && this.dispute.newDebtorContact ? this.dispute.newDebtorContact.recordVersionNbr : undefined;
        }
      }
      return req;
    } else {
      throw new Error('Invalid Form');
    }
  }

  createAddress(control: AbstractControl): Address {
    const address: Address = new Address();

    address.name = control.get(AddressEntryFormNamesEnum.FORM_GROUP_NAME).get(AddressEntryFormNamesEnum.NAME).value;
    address.addressLine1 = control
      .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
      .get(AddressEntryFormNamesEnum.MAILING_ADDRESS_1).value;
    address.addressLine2 = control
      .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
      .get(AddressEntryFormNamesEnum.MAILING_ADDRESS_2).value;
    address.cityName = control.get(AddressEntryFormNamesEnum.FORM_GROUP_NAME).get(AddressEntryFormNamesEnum.CITY).value;
    address.stateCd = control
      .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
      .get(AddressEntryFormNamesEnum.PROVINCE_STATE).value;
    address.postalCd = control
      .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
      .get(AddressEntryFormNamesEnum.ZIP_CODE).value;
    address.countryCd = control
      .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
      .get(AddressEntryFormNamesEnum.COUNTRY).value;

    return address;
  }

  private getCurrencyValue(value: any) {
    if (value) {
      return Number(value.replace(/[^0-9.-]+/g, ''));
    } else {
      return value;
    }
  }

  createDispute(): Dispute {
    const dispute: Dispute = super.createDispute();
    const disputeDetailsForm = this.disputeFormGroup.get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME);
    const drpSpecificationForm = this.disputeFormGroup
      .get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME)
      .get(DisputeDetailsFormNamesEnum.DRP_SPECIFICATIONS_FORM_GROUP_NAME);

    dispute.drpCategoryCd = disputeDetailsForm.get(DisputeDetailsFormNamesEnum.DRP_TYPE).value || undefined;
    const drpDebtorDetail = this.disputeFormGroup.get(DebtorDetailsFormNamesEnum.FORM_GROUP_NAME);
    const drpNewDebtorDetail = this.disputeFormGroup.get(DebtorDetailsFormNamesEnum.SECONDARY_FORM_GROUP_NAME);

    dispute.drpRequestIssue = disputeDetailsForm.get(DisputeDetailsFormNamesEnum.PROBLEM).value;
    dispute.drpRequestAction = disputeDetailsForm.get(DisputeDetailsFormNamesEnum.SPECIFIC_ACTIONS).value;
    dispute.drpPreventativeMeasures = disputeDetailsForm.get(DisputeDetailsFormNamesEnum.PREVENTIVE_MEASURES).value;
    dispute.drpOperatingRatioOrPercentage = disputeDetailsForm.get(DisputeDetailsFormNamesEnum.OPERATING_RATIO).value;
    dispute.drpCurrencyCd = disputeDetailsForm.get(DisputeDetailsFormNamesEnum.CURRENCY).value || undefined;
    dispute.drpMonthlyPotentialRevenueAmount = this.getCurrencyValue(
      disputeDetailsForm.get(DisputeDetailsFormNamesEnum.MONTHLY_REVENUE_POTENTIAL).value
    );
    dispute.drpAverageMonthlyRevenueAmount = this.getCurrencyValue(
      disputeDetailsForm.get(DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE).value
    );
    dispute.authorizedApprvrEmployeeId = disputeDetailsForm.get(
      DisputeDetailsFormNamesEnum.AUTHORIZED_PERSON_EMP_ID
    ).value;

    dispute.drpAenaeEmployeeId = drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_EMPLOYEE_ID).value;
    const drpAenaeEmployeeDtl = new InterfaceEmployee();
    dispute.drpAenaeEmployeeDtl = {
      ...drpAenaeEmployeeDtl,
      employeeId: drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_EMPLOYEE_ID).value,
      firstName: drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_FIRST_NAME).value,
      middleName: drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_MIDDLE_NAME).value,
      lastName: drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_LAST_NAME).value,
      jobDescription: drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_JOB_DESCRIPTION).value,
      domicileSicCd: drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_DOMICILE_SIC).value,
      supervisorEmployeeId: drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_SUP_EMPLOYEE_ID).value,
      businessPhoneTxt: drpSpecificationForm.get(DisputeDetailsFormNamesEnum.DRP_BUSINESS_PHONE_TXT).value,
      auditInfo: this.getDocumentAuditInfo(),
    };

    dispute.onBehalfOfSameAsClaimantInd = false;

    if (dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION) {
      dispute.currentDebtorCustomerNbr = drpDebtorDetail
        .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
        .get(AddressEntryFormNamesEnum.CUSTOMER_NUMBER).value;
    } else if (dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_DEBTOR_CHANGE) {
      dispute.currentDebtorCustomerNbr = drpDebtorDetail
        .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
        .get(AddressEntryFormNamesEnum.CUSTOMER_NUMBER).value;
      dispute.newDebtorCustomerNbr = drpNewDebtorDetail
        .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
        .get(AddressEntryFormNamesEnum.CUSTOMER_NUMBER).value;
      // Get new debtor address values
      dispute.newDebtorDtl = this.createAddress(drpNewDebtorDetail);
    } else {
      dispute.currentDebtorCustomerNbr = drpDebtorDetail
        .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME)
        .get(AddressEntryFormNamesEnum.CUSTOMER_NUMBER).value;
    }

    // Get current debtor address values
    dispute.currentDebtorDtl = this.createAddress(drpDebtorDetail);

    return dispute;
  }

  createUpdateDisputeRqst(): UpdateDisputeRqst {
    const req = super.createUpdateDisputeRqst();
    if (req.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION) {
      req.currentDebtorContact = this.createContact(DebtorDetailsFormNamesEnum.FORM_GROUP_NAME);
      if (req.currentDebtorContact) {
        req.currentDebtorContact.recordVersionNbr =
          this.dispute && this.dispute.currentDebtorContact
            ? this.dispute.currentDebtorContact.recordVersionNbr
            : undefined;
      }
    } else if (req.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_DEBTOR_CHANGE) {
      req.currentDebtorContact = this.createContact(DebtorDetailsFormNamesEnum.FORM_GROUP_NAME);
      if (req.currentDebtorContact) {
        req.currentDebtorContact.recordVersionNbr =
          this.dispute && this.dispute.newDebtorContact ? this.dispute.newDebtorContact.recordVersionNbr : undefined;
      }

      req.newDebtorContact = this.createContact(DebtorDetailsFormNamesEnum.SECONDARY_FORM_GROUP_NAME);
      if (req.newDebtorContact) {
        req.newDebtorContact.recordVersionNbr =
          this.dispute && this.dispute.newDebtorContact ? this.dispute.newDebtorContact.recordVersionNbr : undefined;
      }
    } else {
      req.currentDebtorContact = this.createContact(DebtorDetailsFormNamesEnum.FORM_GROUP_NAME);
      if (req.currentDebtorContact) {
        req.currentDebtorContact.recordVersionNbr =
          this.dispute && this.dispute.currentDebtorContact
            ? this.dispute.currentDebtorContact.recordVersionNbr
            : undefined;
      }
    }
    return req;
  }

  disputesRegistrationFormValidity(executeForceValidation: boolean = false): boolean {
    let result: boolean;
    this.purgeDisputeFormGroup();
    if (executeForceValidation) {
      result = this.forceValidation();
    } else {
      result = this.disputeFormGroup.valid;
      this.loadFormErrorList();
    }
    return result;
  }

  forceValidation(): boolean {
    const formGroup = this.disputeFormGroup.get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME);
    let result: boolean;
    const controls = [
      {
        field: formGroup.get(DisputeDetailsFormNamesEnum.OPERATING_RATIO),
        enabled: formGroup.get(DisputeDetailsFormNamesEnum.OPERATING_RATIO).enabled,
      },
      {
        field: formGroup.get(DisputeDetailsFormNamesEnum.CURRENCY),
        enabled: formGroup.get(DisputeDetailsFormNamesEnum.CURRENCY).enabled,
      },
      {
        field: formGroup.get(DisputeDetailsFormNamesEnum.MONTHLY_REVENUE_POTENTIAL),
        enabled: formGroup.get(DisputeDetailsFormNamesEnum.MONTHLY_REVENUE_POTENTIAL).enabled,
      },
      {
        field: formGroup.get(DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE),
        enabled: formGroup.get(DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE).enabled,
      },
    ];

    controls.forEach((control) => {
      control.field.enable();
    });

    const shipmentFormGroup = this.disputeFormGroup
      .get(InvoiceDetailsFormNamesEnum.FORM_GROUP_NAME)
      .get(InvoiceDetailsFormNamesEnum.SHIPMENTS);
    shipmentFormGroup.updateValueAndValidity();

    const usdTotalAmount = this.invoiceDetailsDataSourceService.usdTotalAmount
      ? this.invoiceDetailsDataSourceService.usdTotalAmount
      : this.dispute
      ? this.dispute.totalUSDRequestedAdjustmentAmount
      : 0;

    this.updateDRPDetailValidators(usdTotalAmount);
    this.loadFormErrorList();
    result = this.disputeFormGroup.valid;
    controls.forEach((control) => {
      if (!control.enabled) {
        control.field.disable();
      }
    });

    return result;
  }

  purgeDisputeFormGroup() {
    if (this.disputeFormGroup.get(ClaimantInformationFormNamesEnum.FORM_GROUP_NAME)) {
      this.disputeFormGroup.removeControl(ClaimantInformationFormNamesEnum.FORM_GROUP_NAME);
    }

    if (this.disputeFormGroup.get(AuditCompanyInformationFormNamesEnum.FORM_GROUP_NAME)) {
      this.disputeFormGroup.removeControl(AuditCompanyInformationFormNamesEnum.FORM_GROUP_NAME);
    }
  }

  formIsValidForSaving(): boolean {
    const result = super.formIsValidForSaving();
    const drpTypeControl = this.disputeFormGroup
      .get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME)
      .get(DisputeDetailsFormNamesEnum.DRP_TYPE);

    if (!drpTypeControl.valid) {
      const formErrors = this.findInvalidControls(this.disputeFormGroup);
      const disputeDetailsErrors = this.getDrpTypeFormError(formErrors);

      _.find(formErrors, function(formError) {
        return formError.name === DisputeDetailsFormNamesEnum.FORM_GROUP_NAME;
      }).errors = disputeDetailsErrors;

      _.find(formErrors, function(formError) {
        return formError.name === DebtorDetailsFormNamesEnum.FORM_GROUP_NAME;
      }).errors = [];

      _.find(formErrors, function(formError) {
        return formError.name === DisputeInformationFormNamesEnum.INVOICE_DETAILS;
      }).errors = [];

      this.disputeDataService.formErrors = formErrors;
    }

    return result && drpTypeControl.valid;
  }

  private getDrpTypeFormError(formErrors): FormError {
    const disputeDetailsErrors = _.find(formErrors, function(formError) {
      return formError.name === DisputeDetailsFormNamesEnum.FORM_GROUP_NAME;
    });
    return disputeDetailsErrors.errors.filter((element) => {
      return element.name === DisputeDetailsFormNamesEnum.DRP_TYPE;
    });
  }
}
