import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import {
  EmployeeListItem,
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdQuery,
  HumanResourceApiService,
  ListEmployeesByNameQuery,
  ListEmployeesByNameResp,
} from '@xpo-ltl/sdk-humanresource';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectEmployeeDialogInterface } from '../../interfaces/disputes/select-employee-dialog.interface';
import { InterestedParty } from '../../interfaces/interested-party.interface';

@Injectable({
  providedIn: 'root',
})
export class SelectEmployeeDialogService {
  constructor(private humanResourceApiService: HumanResourceApiService) {}

  getEmployeesByNames(lastName: string, firstName?: string): Observable<SelectEmployeeDialogInterface[]> {
    const queryParams = new ListEmployeesByNameQuery();
    queryParams.lastName = lastName;
    queryParams.firstName = firstName || '';
    const options: DataOptions = {
      toastOnError: false,
    };

    return this.humanResourceApiService.listEmployeesByName(queryParams, options).pipe(
      map((employeeList: ListEmployeesByNameResp) => employeeList.employees),
      map(
        (employeeList: Array<EmployeeListItem>) =>
          employeeList
            ? employeeList.map((data: EmployeeListItem) => {
                let employee: SelectEmployeeDialogInterface;
                employee = {
                  employeeId: data.personalData.employeeId,
                  firstName: data.personalData.firstName,
                  lastName: data.personalData.lastName,
                  sicCode: data.employeeLocation.locationSicCd,
                  locationSicName: data.employeeLocation.locationSicName,
                  jobTitle: data.hsfJobCode.jobDescription,
                  jobCode: data.hsfJobCode.jobcode,
                  sfEmployeeId: data.personalData.sfEmplid,
                  address1: data.employeeLocation.address1,
                  cityName: data.employeeLocation.cityNm,
                  stateCd: data.employeeLocation.stateCd,
                  countryCd: data.employeeLocation.countryCd,
                  zip: data.employeeLocation.zip,
                };
                return employee;
              })
            : []
      )
    );
  }

  getEmployeeByIds(employeeIds: string[]): Observable<InterestedParty[]> {
    const path = new GetEmployeeDetailsByEmpIdPath();
    const query = new GetEmployeeDetailsByEmpIdQuery();
    // query.requestType = 'Basic';

    const employeeListRequest = employeeIds.map((employeeId) => {
      path.employeeId = employeeId;
      return this.humanResourceApiService.getEmployeeDetailsByEmpId(path, query);
    });
    return forkJoin(employeeListRequest).pipe(
      map((employees) => {
        return employees.map((employee) => {
          const interestedParty: InterestedParty = {
            employeeId: employee.employee.basicInfo.employeeId,
            firstName: employee.employee.basicInfo.firstName,
            lastName: employee.employee.basicInfo.lastName,
            emailAddress: employee.employee.emailAddress,
          };
          return interestedParty;
        });
      })
    );
  }
}
