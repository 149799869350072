import { FormBuilder, FormGroup } from '@angular/forms';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { EmailEntryFormBuilder } from '../../../../../../lib/src/lib/notes-emails/public_api';
import { DeclinationEmailDialogFormNamesEnum } from '../../../../enums';

export class DeclinationEmailDialogFormBuilder {
  static create(fb: FormBuilder, formatValidationService: FormatValidationService): FormGroup {
    return fb.group({
      [DeclinationEmailDialogFormNamesEnum.EMAIL]: EmailEntryFormBuilder.create(fb, formatValidationService),
    });
  }
}
