import { Injectable } from '@angular/core';

import { AuditInfo, DisputeReassignReasonCd } from '@xpo-ltl/sdk-common';
import {
  DisputeItem,
  DisputesApiService,
  GrabDisputePath,
  GrabDisputeResp,
  GrabDisputeRqst,
  InterfaceEmployee,
  ReassignDisputesResp,
  ReassignDisputesRqst,
} from '@xpo-ltl/sdk-disputes';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { DialogResponse, ReassignDisputeFormLabelsEnum } from '../../enums';
import { DialogResponseData } from '../../interfaces/dialog-response-data.interface';
import { ReassignDisputeData } from '../../interfaces/disputes/reassign-dispute-data';
import { SelectEmployeeDialogInterface } from '../../interfaces/disputes/select-employee-dialog.interface';
import { UserService } from '../users/user.service';
import { DisputesDataService } from './disputes-data.service';
import { DisputesDialogService } from './disputes-dialog.service';

@Injectable()
export class ReassignService {
  constructor(
    private disputesDialogService: DisputesDialogService,
    private disputesDataService: DisputesDataService,
    private disputesApiService: DisputesApiService,
    private userService: UserService
  ) {}

  reassignCurrentDispute(): Observable<ReassignDisputesResp> {
    return this.disputesDialogService.showSelectEmployeeDialog(ReassignDisputeFormLabelsEnum.TITLE).pipe(
      filter((employee) => !!employee),
      switchMap((employee: SelectEmployeeDialogInterface) =>
        this.disputesDialogService.showReassignDialog().pipe(
          filter((reassignData) => reassignData.response === DialogResponse.REASSIGN),
          switchMap((reassignData) => {
            const disputeItem = this.getCurrentDisputeItem();
            const request = this.createReassignDisputesRqst([disputeItem], reassignData, employee);
            return this.disputesApiService.reassignDisputes(request);
          })
        )
      )
    );
  }

  private getCurrentDisputeItem(): DisputeItem {
    const assignedToEmployeeIDtl = new InterfaceEmployee();
    const disputeItem: DisputeItem = {
      disputeId: this.disputesDataService.dispute.dispute.disputeId,
      itemInstId: this.disputesDataService.dispute.itemInstId,
      assignedToEmployeeIDtl: {
        ...assignedToEmployeeIDtl,
        employeeId: this.userService.getEmployeeId(),
        firstName: this.userService.currentUser.givenName,
        middleName: '',
        lastName: this.userService.currentUser.lastName,
        jobDescription: '',
        domicileSicCd: this.userService.currentUser.sicCode,
        supervisorEmployeeId: '',
        businessPhoneTxt: '',
        auditInfo: new AuditInfo(),
      },
    };
    return disputeItem;
  }

  private createReassignDisputesRqst(
    disputesItems: DisputeItem[],
    reassignData: DialogResponseData<any>,
    employee: SelectEmployeeDialogInterface
  ): ReassignDisputesRqst {
    const reassignDisputeData: ReassignDisputeData = reassignData.data;
    const reassignReasonCode: DisputeReassignReasonCd = reassignDisputeData.reasonCode;
    const reassignReasonText: string = reassignDisputeData.reasonForReassignment;
    const request = new ReassignDisputesRqst();
    request.disputeItems = disputesItems;
    request.reassignToEmployeeId = employee.employeeId;
    request.reassignReasonCd = reassignReasonCode;
    request.reassignReasonTxt = reassignReasonText;
    return request;
  }

  reassignDisputes(disputesItems: DisputeItem[]): Observable<ReassignDisputesResp> {
    return this.disputesDialogService.showSelectEmployeeDialog(ReassignDisputeFormLabelsEnum.TITLE).pipe(
      filter((employee) => !!employee),
      switchMap((employee: SelectEmployeeDialogInterface) =>
        this.disputesDialogService.showReassignDialog().pipe(
          filter((reassignData) => reassignData.response === DialogResponse.REASSIGN),
          map((reassignData) => this.createReassignDisputesRqst(disputesItems, reassignData, employee))
        )
      ),
      switchMap((reassignRequest) => this.disputesApiService.reassignDisputes(reassignRequest))
    );
  }

  grabCurrentDispute(): Observable<GrabDisputeResp> {
    const disputeItem = this.getCurrentDisputeItem();
    const request = new GrabDisputeRqst();
    const grabDisputePath: GrabDisputePath = new GrabDisputePath();
    request.assigneeEmplRole = this.userService.getUserRole(this.disputesDataService.dispute);
    request.itemInstId = disputeItem.itemInstId;
    request.recordVersionNbr = this.disputesDataService.dispute.dispute.recordVersionNbr;
    grabDisputePath.disputeId = disputeItem.disputeId;
    return this.disputesApiService.grabDispute(request, grabDisputePath);
  }
}
