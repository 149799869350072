export enum InvoiceDetailsFormNamesEnum {
  FORM_GROUP_NAME = 'InvoiceDetails',
  SHIPMENTS = 'Shipments',
  ADD_ROW = 'AddRow',
  DELETE_ROW = 'DeleteRow',
  EXPORT_PROS = 'Export',
  IMPORT_PROS = 'Import',
  FILE_NAME = 'FileName',
  FILE_TYPE = 'FileType',
  FILE_BASE_64 = 'FileBase64',
  DISPUTE_ID = 'disputeId',
  ITEM_INST_ID = 'itemInstId',
}
