import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';

import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

import { take, takeUntil } from 'rxjs/operators';

import { HttpStatusCodesEnum } from '../../../../enums';
import { SelectEmployeeDialogFormLabelsEnum, SelectEmployeeDialogFormNamesEnum } from '../../../../enums';
import { SelectEmployeeDialogInterface } from '../../../../interfaces/disputes/select-employee-dialog.interface';
import { SelectEmployeeDialogService } from '../../../../services/disputes/select-employee-dialog.service';

import { SelectEmployeeDialogFormBuilder } from './select-employee-dialog.form-builder';

@Component({
  selector: 'app-select-employee-dialog',
  templateUrl: './select-employee-dialog.component.html',
  styleUrls: ['./select-employee-dialog.component.scss'],
})
export class SelectEmployeeDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly SelectEmployeeDialogFormLabelsEnum = SelectEmployeeDialogFormLabelsEnum;
  readonly SelectEmployeeDialogFormNamesEnum = SelectEmployeeDialogFormNamesEnum;

  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  @ViewChild('lastName', { static: false })
  lastName: ElementRef;

  title: string;
  unsubscriber = new Unsubscriber();
  formGroup: FormGroup;
  dataSource = new MatTableDataSource<SelectEmployeeDialogInterface>([]);
  employeesNotFound = false;
  messageError: string;
  displayedColumns = [];
  selectedEmployee: SelectEmployeeDialogInterface = undefined;

  private _loading: boolean = false;
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this._loading = value;
    if (value) {
      this.disableForm();
    } else {
      this.enableForm();
    }
  }

  get isValidForm(): boolean {
    return this.formGroup.valid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SelectEmployeeDialogComponent>,
    private selectEmployeeDialogService: SelectEmployeeDialogService,
    private snackBar: XpoSnackBar
  ) {
    this.title = this.data.hasOwnProperty('title') ? this.data.title : 'Search Employee';
    this.displayedColumns = this.getColumnsToDisplay(this.data.title);
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  ngAfterViewInit() {
    setTimeout(() => this.lastName.nativeElement.focus(), 600);
  }

  /**
   * Form creation
   */
  initForm() {
    this.formGroup = SelectEmployeeDialogFormBuilder.create(this.fb);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSelectClick() {
    this.dialogRef.close(this.selectedEmployee);
  }

  onSearchClick() {
    const firstNameControl = this.formGroup.get(SelectEmployeeDialogFormNamesEnum.FIRST_NAME);
    const lastNameControl = this.formGroup.get(SelectEmployeeDialogFormNamesEnum.LAST_NAME);
    if (this.formGroup.valid) {
      this.loading = true;
      this.selectEmployeeDialogService
        .getEmployeesByNames(`${lastNameControl.value}%`, `${firstNameControl.value}%`)
        .pipe(
          take(1),
          takeUntil(this.unsubscriber.done$)
        )
        .subscribe(
          (data) => {
            this.dataSource = new MatTableDataSource<SelectEmployeeDialogInterface>(data);
            this.dataSource.sort = this.sort;
            this.loading = false;
          },
          (error) => {
            this.dataSource = new MatTableDataSource<SelectEmployeeDialogInterface>([]);
            this.loading = false;
            this.employeesNotFound = true;
            if (error.code === HttpStatusCodesEnum.NOT_FOUND) {
              this.messageError = SelectEmployeeDialogFormLabelsEnum.NOT_FOUND;
            } else {
              this.snackBar.error(`Error: ${error.error.message}`);
            }
          }
        );
    } else {
      firstNameControl.markAsTouched();
      lastNameControl.markAsTouched();
    }
  }

  selectEmployee(employee: SelectEmployeeDialogInterface) {
    this.selectedEmployee = employee;
  }

  disableForm() {
    this.formGroup.get(SelectEmployeeDialogFormNamesEnum.FIRST_NAME).disable();
    this.formGroup.get(SelectEmployeeDialogFormNamesEnum.LAST_NAME).disable();
  }

  enableForm() {
    this.formGroup.get(SelectEmployeeDialogFormNamesEnum.FIRST_NAME).enable();
    this.formGroup.get(SelectEmployeeDialogFormNamesEnum.LAST_NAME).enable();
  }

  getColumnsToDisplay(title: any): string[] {
    return [
      'select',
      'lastName',
      'firstName',
      'sicCode',
      'locationSicName',
      'jobTitle',
      'jobCode',
      'sfEmployeeId',
      'address1',
      'cityName',
      'stateCd',
      'countryCd',
      'zip',
    ];
  }

  /**
   * Subscribe to non-Form observables (local and Service)
   */
  initSubscriptions() {}

  /**
   * Subscribe to Form control value and status changes
   */
  initWatchers() {}

  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.onSearchClick();
    }
  }
}
