import moment from 'moment-timezone';

const timezone = 'America/Los_Angeles'; // PST PDT

export class DateUtils {
  /**
   * Format date, based on default application timezone
   */
  static getFormatDate(date: Date, format?: string): string {
    return moment(date).format(format);
  }

  static getFormatDateTime(date: Date, format?: string): string {
    return moment(date)
      .tz(timezone)
      .format(format);
  }
}
