export enum DocumentFileTypesEnum {
  DOC = 'doc',
  DOCX = 'docx',
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  MSG = 'msg',
  EML = 'eml',
  XLS = 'xls',
  XSLX = 'xlsx',
  CSV = 'csv',
  PNG = 'png',
}
