export enum AddressEntryFormLabelsEnum {
  NAME = 'Name',
  MAILING_ADDRESS_1 = 'Mailing Address Line 1',
  MAILING_ADDRESS_2 = 'Mailing Address Line 2',
  CITY = 'City',
  PROVINCE_STATE = 'Province/State',
  ZIP_CODE = 'Zip Code',
  COUNTRY = 'Country',
  CONTACT_FIRST_NAME = 'First Name',
  CONTACT_LAST_NAME = 'Last Name',
  CONTACT_EMAIL_ADDRESS = 'Email Address',
  CONTACT_PHONE_NUMBER = 'Phone Number',
  CONTACT_PHONE_EXT_NUMBER = 'Ext.',
  CONTACT_FAX_NUMBER = 'FAX',
  REQUIRED = 'Required',
  EMAIL_NOT_VALID = 'Email not valid',
  MIN_LENGTH = 'Input too short',
  MAX_LENGTH = 'Input too long',
  PHONE_FORMAT = 'Invalid format',
  PHONE_FORMAT_CHARS = '###-###-####',
  VIEW_CONTACTS = 'View Contacts',
  MAD_CODE = 'Customer MAD Code',
  SEARCH_DEBTOR = 'Search Debtor',
  ENTER_DEBTOR_MANUALLY = 'Enter Debtor Manually',
}
