export enum AddressEntryFormPlaceholdersEnum {
  NAME = '',
  MAILING_ADDRESS_1 = '',
  MAILING_ADDRESS_2 = '',
  CITY = '',
  PROVINCE_STATE = '',
  ZIP_CODE = '',
  COUNTRY = '',
  CONTACT_FIRST_NAME = '',
  CONTACT_LAST_NAME = '',
  CONTACT_EMAIL_ADDRESS = '',
  CONTACT_PHONE_NUMBER = '',
  CONTACT_PHONE_EXT_NUMBER = '',
  CONTACT_FAX_NUMBER = '',
  MAD_CODE = '',
}
