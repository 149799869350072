<div class="emails">
    <as-split #splitPanel direction="horizontal" class="emails__content" [ngClass]="{ 'emails__content-compose-only': true }" gutterSize="24">
        <as-split-area class="emails__content-left" [visible]="enabled" size="{{ !config?.composeOnly ? 50 : 100 }}">
            <div class="email-container-inner">
                <xpo-card>
                    <xpo-card-content>
                        <xpo-email-entry [config]="config" [formGroup]="emailFormGroup" [templates]="templates$ | async" [defaultEmailTemplate]="defaultEmailTemplate$ | async">
                        </xpo-email-entry>
                        <div class="action-buttons">
                            <span *ngIf="uploadingFile$ | async" class="spinner"></span>
                            <span [matTooltip]="attachments$ | async">
                                <button id="send" mat-raised-button (click)="handleSendClicked()" [disabled]="emailFormGroup.invalid" data-test="emailSend">
                                    {{ EmailFormLabelsEnum.SEND }}
                                </button>
                                <div class="button-wrapper">
                                    <button id="attach-file" mat-button mat-icon-button aria-label="attach file" [matMenuTriggerFor]="uploadOptions" [disabled]="!!config?.email" *ngIf="!(uploadingFile$ | async)" data-test="emailAttachFile">
                                        <mat-icon>attach_file</mat-icon>
                                    </button>
                                    <div class="xpo-badge" [xpoBadge]="uploadBadgeConfig">
                                    </div>
                                </div>
                            </span>
                        </div>
                        <mat-menu #uploadOptions="matMenu">
                            <button mat-menu-item ngxFilePicker accept="image/*" [readMode]="readMode" [multiple]="true" (readStart)="onReadStart($event)" (readEnd)="onReadEnd($event)" (filePick)="onFilePicked($event)">
                                {{ EmailFormLabelsEnum.ATTACH_FILES }}
                            </button>
                            <!-- dmsDocList$ doesn't exist -->
                            <!-- <button mat-menu-item (click)="dms.open()" *ngIf="(dmsDocList$ | async)?.length > 0">DMS
                                Image</button> -->
                            <button mat-menu-item (click)="handleClearAttachments()" *ngIf="(attachments$ | async)?.length > 0">
                                {{ EmailFormLabelsEnum.CLEAR_ATTACHMENTS }}
                            </button>
                            <button mat-menu-item (click)="downloadAttachments()" *ngIf="(attachments$ | async)?.length > 0">
                                {{ EmailFormLabelsEnum.DOWNLOAD_ATTACHMENTS }}
                            </button>
                        </mat-menu>
                        <mat-select multiple #dms [style.display]="dms.panelOpen ? 'block' : 'none'" style="width: 0;">
                            <!-- dmsDocList$ doesn't exist -->
                            <!-- <mat-option *ngFor="let dmsDoc of dmsDocList$ | async" [value]="dmsDoc">{{ dmsDoc }}</mat-option> -->
                        </mat-select>
                    </xpo-card-content>
                </xpo-card>
            </div>

        </as-split-area>
        <as-split-area class="emails__detail-inner" size="50" [visible]="!config?.composeOnly">
            <xpo-card class="emails__detail-inner-container">
                <xpo-card-header class="emails__detail-header">
                    <xpo-card-title>
                        {{ draftInd ? EmailFormLabelsEnum.DRAFTS : EmailFormLabelsEnum.EMAILS }}
                        <span *ngIf="(filteredEmails$ | async)?.length > 0">({{ (filteredEmails$ | async)?.length }})</span>
                    </xpo-card-title>
                    <xpo-card-actions>
                        <mat-form-field *ngIf="!draftInd" data-test="emailIncomingAndOutgoing">
                            <mat-select #direction disabled="false" placeholder="Filter">
                                <mat-option *ngFor="let direction of directionTypes" [value]="direction">
                                    {{ direction }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="never" data-test="emailNewestToOldest">
                            <mat-select #age [disabled]="false" placeholder="Newest to Oldest">
                                <mat-option *ngFor="let age of ageTypes" [value]="age">
                                    {{ age }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </xpo-card-actions>
                </xpo-card-header>
                <xpo-card-content class="emails__detail-list" data-test="emails">
                    <div class="emails__detail-no-items" *ngIf="!(filteredEmails$ | async)?.length">
                        {{ EmailFormLabelsEnum.EMAIL_EMPTY }}
                    </div>
                    <div class="emails__detail-item" *ngFor="let email of filteredEmails$ | async">
                        <xpo-emails-detail [email]="email" [enabled]="enabled" (emailActionClicked)="emailActionHandler($event)">
                        </xpo-emails-detail>
                    </div>
                </xpo-card-content>
            </xpo-card>
        </as-split-area>
    </as-split>
</div>
