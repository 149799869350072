import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { NotAuthorizedComponent } from '../components/not-authorized/not-authorized.component';
import { DisputeLoginService } from '../services/users/dispute-login.service';
import { UserService } from '../services/users/user.service';
import { XpoDialogManagerService } from '../services/xpo-dialog-manager.service';

export const RoleCheckGuard: CanActivateFn = ():
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const loginService: DisputeLoginService = inject(DisputeLoginService);
    const userService: UserService = inject(UserService);
    const dialogManager: XpoDialogManagerService = inject(XpoDialogManagerService);

    return loginService.getLoggedInUser().pipe(
      map(() => {
        if (userService.isAuthorizedUser()) {
          return true;
        }
        showNotAuthorizedDialog(dialogManager);
        return false;
      }),
      catchError((error) => {
        userService.currentUser = undefined;
        showNotAuthorizedDialog(dialogManager);
        return of(false);
      })
    );
}

const showNotAuthorizedDialog = (dialogManager: XpoDialogManagerService) => {
  dialogManager.alert(
    {
      titleText: 'No Authorization',
      contentComponent: NotAuthorizedComponent,
      injectedData: of({ isSuccess: false }),
      hideDismissButton: true,
    },
    {
      disableClose: true,
      width: '600',
    }
  );
}