<div class="select-pro-dialog">
  <h1 mat-dialog-title class="select-pro-dialog__title">
    {{ title }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div select-pro-dialog__content mat-dialog-content>
    <div class="description">
      <p>
        {{ description }}
      </p>
    </div>
  </div>

  <div mat-dialog-actions class="select-pro-dialog__footer">
    <button mat-button color="primary" (click)="onCancelClick()">
      {{ DisputesExportEnum.CANCEL }}
    </button>
    <button mat-flat-button class="button-select" (click)="onSelectClick()" data-test="select">
      {{ DisputesExportEnum.CONTINUE }}
    </button>
  </div>
</div>
