export enum DisputeFilingFormLabelsEnum {
  TITLE = 'Dispute Filing',
  DISPUTE_TYPE = 'Dispute Type',
  DISPUTE_RECEIVED_DATE = 'Dispute Received Date',
  DISPUTE_CREATED_DATE = 'Dispute Created Date',
  FILED_BY = 'Filed By',
  FILE_METHOD = 'File Method',
  CUSTOMER_REFERENCE_NUMBER = 'Customer Reference Number',
  TOTAL_INVOICE_AMOUNT = 'Total Invoice Amount',
  TOTAL_REQUESTED_AMOUNT = 'Total Requested Amount',
  ASSIGNED_TO = 'Assigned To',
}
