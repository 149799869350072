import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'proDash',
})
export class ProDashPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    const proNbr = value.length === 11 ? parseInt(value, 10).toString() : value;
    return proNbr.slice(0, 3) + '-' + proNbr.slice(3);
  }
}
