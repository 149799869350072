import { Injectable, TemplateRef } from '@angular/core';

import { MediaObserver } from '@angular/flex-layout';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, } from '@angular/material/legacy-dialog';

import { MaterialMediaQueries } from '../enums/material-media-queries.enum';

import { clone, defaults } from 'lodash';

import { AlertWrapperComponent } from '../dialogs/alert-wrapper/alert-wrapper.component';
import { AlertDialogData } from '../interfaces/alert-dialog-data.interface';

@Injectable()
export class XpoDialogManagerService {
  private configDefaults: MatDialogConfig;

  constructor(private matDialog: MatDialog, private mediaQueryService: MediaObserver) {
    this.configDefaults = {
      width: '650px',
    };

    this.initializeWatchers();
  }

  alert(alertOptions: AlertDialogData, configOverrides: MatDialogConfig = {}): MatDialogRef<any> {
    const defaultsWithData = defaults(configOverrides, clone(this.configDefaults));
    defaultsWithData.data = alertOptions;

    return this.matDialog.open(AlertWrapperComponent, defaultsWithData);
  }

  open(dialogComponent: TemplateRef<any>, configOverrides: MatDialogConfig = {}): MatDialogRef<any> {
    const dialogConfig = defaults(configOverrides, clone(this.configDefaults));

    return this.matDialog.open(dialogComponent, dialogConfig);
  }

  private initializeWatchers(): void {
    this.mediaQueryService.asObservable().subscribe((mediaChange) => {
      this.configDefaults.width = this.mediaQueryService.isActive(MaterialMediaQueries.GT_MEDIUM) ? '650px' : '80%';
    });
  }
}
