export enum DisputeDetailsFormPlaceholdersEnum {
  REQUEST_REASON = 'Select one',
  REQUEST_EXPLANATION = 'Enter the request explanation...',
  SHIPPER_WEBSITE = 'Enter the shipper website',
  INSPECTOR_NAME = 'Enter the inspector Name',
  INSPECTOR_SIC_CODE = 'Enter the inspector SIC code',
  INSPECTOR_AREA = 'Enter the inspector area',

  // DRP
  DRP_TYPE = 'Select the DRP Type',
  AE_NAE_NAME = 'Enter the AE/NAE Name',
  AUTHORIZED_PERSON = 'Enter the Authorized Person',
  PROBLEM = 'Enter the problem',
  PREVENTIVE_MEASURES = 'Enter the Preventive Measures',
  SPECIFIC_ACTIONS = 'Enter the Specific Actions',
  OPERATING_RATIO = 'Enter the Operating Ratio',
  CURRENCY = 'Enter the Currency',
  MONTHLY_REVENUE_POTENTIAL = 'Enter the Monthly Revenue Potential',
  AVERAGE_MONTHLY_REVENUE = 'Enter the Average Monthly Revenue',
}
