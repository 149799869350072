import { FormBuilder, FormGroup } from '@angular/forms';

import { NotesEntryFormNamesEnum } from '../enums';
import { NotesEntryFormBuilder } from './notes-entry/notes-entry.form-builder';

export class NotesFormBuilder {
  static create(fb: FormBuilder): FormGroup {
    return fb.group({
      [NotesEntryFormNamesEnum.FORM_GROUP_NAME]: NotesEntryFormBuilder.create(fb),
    });
  }
}
