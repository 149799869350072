export enum DisputeDetailsFormLabelsEnum {
  TITLE = 'Dispute Details',
  REQUEST_REASON = 'Request Reason',
  REQUEST_EXPLANATION = 'Request Explanation',
  REQUIRED = 'Required',
  MIN_NUMBER_ERROR = 'Greater than zero',

  // WNI
  SHIPPER_WEBSITE = 'Shipper Website',
  INSPECTOR_NAME = 'Inspector',
  INSPECTOR_SIC_CODE = 'Inspector SIC Code',
  INSPECTOR_AREA = 'Area',

  // DRP
  DRP_TYPE = 'DRP Type',
  AE_NAE_NAME = 'AE/NAE Name',
  AUTHORIZED_PERSON = 'First Level Approver',
  PROBLEM = 'What caused the problem?',
  PREVENTIVE_MEASURES = 'Preventive Measures Taken',
  SPECIFIC_ACTIONS = 'What specific actions need to be taken?',
  OPERATING_RATIO = 'Operating Ratio (%)',
  CURRENCY = 'Currency',
  MONTHLY_REVENUE_POTENTIAL = 'Monthly Revenue Potential',
  AVERAGE_MONTHLY_REVENUE = 'Average Monthly Revenue',
  OVERALL_JUSTIFICATION = 'Overall Justification',
  OPERATING_RATIO_PREV_NINE_MONTHS = 'Operating Ratio For Previous 9 Months (%)',
  AVERAGE_MONTHLY_REVENUE_PREV_NINE_MONTHS = 'Average Monthly Revenue For Previous 9 Months',
}
