import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { XpoLtlWindowService } from '@xpo-ltl/ngx-ltl';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { EmailDirectionTypeCd } from '@xpo-ltl/sdk-common';
import {
  DocumentManagementApiService,
  GetDocumentQuery,
  GetDocumentResp,
  RetrieveDmsAuthTokenRqst,
} from '@xpo-ltl/sdk-documentmanagement';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { decode } from 'typescript-base64-arraybuffer';
import { ConfigManagerProperties } from '../../../../../../src/app/enums';
import { EmailAttachData } from '../../classes/email-attach-data.class';
import { EmailData } from '../../classes/email-data.class';
import { EmailActionTypes } from '../../email-action-types.enum';
import { EmailActionInterface } from '../../email-action.interface';

@Component({
  selector: 'xpo-emails-detail',
  templateUrl: './emails-detail.component.html',
  styleUrls: ['./emails-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsDetailComponent implements OnInit {
  private badgeHiddenBehaviorSubject = new BehaviorSubject<boolean>(true);
  private badgeBehaviorSubject = new BehaviorSubject<string>(undefined);

  badgeConfig = {
    xpoBadgeHidden: this.badgeHiddenBehaviorSubject,
    xpoBadge: this.badgeBehaviorSubject,
    xpoBadgeFonkgroundColor: 'black',
    xpoBadgetSize: new BehaviorSubject<string>('12px'),
    xpoBadgeBacColor: '#ffffff',
  };

  @Input()
  email: EmailData;
  @Input()
  enabled = true;

  @Output()
  emailActionClicked = new EventEmitter<EmailActionInterface>();

  private isExpandedSubject = new BehaviorSubject<boolean>(true);
  isExpanded$ = this.isExpandedSubject.asObservable();
  EmailDirectionTypeCd = EmailDirectionTypeCd;

  constructor(
    private windowService: XpoLtlWindowService,
    private sanitizer: DomSanitizer,
    private dmsService: DocumentManagementApiService,
    private configManagerService: ConfigManagerService,
    private xpoSnackBar: XpoSnackBar
  ) {}

  ngOnInit() {
    const attachments = _.get(this.email, 'emailAttach', []);

    this.badgeHiddenBehaviorSubject.next(attachments.length === 0);
    this.badgeBehaviorSubject.next(`${attachments.length}`);
  }

  replyClicked(): void {
    this.emailActionClicked.next({ action: EmailActionTypes.Reply, email: this.email });
  }

  replyAllClicked(): void {
    this.emailActionClicked.next({ action: EmailActionTypes.ReplyAll, email: this.email });
  }

  forwardClicked(): void {
    this.emailActionClicked.next({ action: EmailActionTypes.Forward, email: this.email });
  }

  onClickAttachment(emailAttachment: EmailAttachData): void {
    this.dmsService
      .retrieveDmsAuthToken(new RetrieveDmsAuthTokenRqst())
      .pipe(
        take(1),
        switchMap((dmsAuthResponse) => {
          const accessToken: string = dmsAuthResponse.access_token;
          return this.dmsService.getDocument(
            {
              corpCode: this.configManagerService.getSetting<string>(ConfigManagerProperties.dmsCorpCode),
              docClass: this.configManagerService.getSetting<string>(ConfigManagerProperties.dmsDocClass),
              docArchiveTimestamp: emailAttachment.dmsUrl,
              docFormat: 'unknown',
            },
            new GetDocumentQuery(),
            {},
            {
              headers: {
                DMSAuth: accessToken,
              },
            }
          );
        })
      )
      .subscribe(
        (getDocumentResp: GetDocumentResp) => {
          const data = decode(getDocumentResp.documentData) as Uint8Array;
          const fileName = emailAttachment.attachName;
          const contentType = getDocumentResp.contentType;
          this.windowService.generateDownloadFile(contentType, data, fileName);
        },
        () => {
          this.xpoSnackBar.error(`error getting document ${emailAttachment.attachName}`);
        }
      );
  }

  onClickDownloadAllDocuments(): void {
    _.each(this.email.emailAttach, (emailAttachment) => {
      this.dmsService
        .retrieveDmsAuthToken(new RetrieveDmsAuthTokenRqst())
        .pipe(
          take(1),
          switchMap((dmsAuthResponse) => {
            const accessToken: string = dmsAuthResponse.access_token;
            return this.dmsService.getDocument(
              {
                corpCode: this.configManagerService.getSetting<string>(ConfigManagerProperties.dmsCorpCode),
                docClass: this.configManagerService.getSetting<string>(ConfigManagerProperties.dmsDocClass),
                docArchiveTimestamp: emailAttachment.dmsUrl,
                docFormat: 'unknown',
              },
              new GetDocumentQuery(),
              {},
              {
                headers: {
                  DMSAuth: accessToken,
                },
              }
            );
          })
        )
        .subscribe(
          (getDocumentResp: GetDocumentResp) => {
            const data = decode(getDocumentResp.documentData) as Uint8Array;
            const fileName = emailAttachment.attachName;
            const contentType = getDocumentResp.contentType;
            this.windowService.generateDownloadFile(contentType, data, fileName);
          },
          () => {
            this.xpoSnackBar.error(`error getting document ${emailAttachment.attachName}`);
          }
        );
    });
  }

  sanitizeMessage(body: string) {
    return this.sanitizer.bypassSecurityTrustHtml(body);
  }

  isExternalSender(body: string) {
    return body.includes('[Caution: External sender, beware of phishing]');
  }
}
