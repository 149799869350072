export enum DisputeInformationFormLabelsEnum {
  TITLE = 'Dispute Information',
  DISPUTE_TYPE = 'Dispute Type',
  DISPUTE_RECEIVED_DATE = 'Dispute Received Date',
  DISPUTE_CREATED_DATE = 'Dispute Created Date',
  FILED_BY = 'Filed By',
  FILE_METHOD = 'File Method',
  CUSTOMER_REFERENCE_NUMBER = 'Customer Reference Number',
  TOTAL_PRO_COUNT = 'Total Pro Count',
  TOTAL_INVOICE_AMOUNT = 'Total Invoice Amount (at Dispute Creation)',
  TOTAL_REQUESTED_AMOUNT = 'Total Requested Amount',
  ASSIGNED_TO = 'Assigned To',
  REASSIGN = 'Reassign',
  ASSIGN = 'Assign',
  APPROVED_DATE = 'Approved Date',
  APPROVED_BY = 'Approved By',
  AMENDED_DATE = 'Amended Date',
  CLOSED_BY = 'Closed By',
  CLOSED_DATE = 'Closed Date',
  AMENDED_BY = 'Amended By',
  DECLINED_DATE = 'Declined Date',
  DECLINED_BY = 'Declined By',
  CANCELLED_DATE = 'Cancelled Date',
  CANCELLED_BY = 'Cancelled By',
  REASON_CODE = 'Decision Reason Code',
  LAST_APPROVED_BY = 'Last Approved By',
}
