<div class="pro-download-template-dialog">
  <h1 mat-dialog-title class="pro-download-template__title">
    {{ ProDownloadTemplateDialogFormLabelsEnum.TITLE }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div pro-download-template__content mat-dialog-content>
    <div class="description">
      <p>
        Download and use one of the templates provided below, or continue if you are already using them.
        Files that do not use these templates will fail processing.
      </p>
    </div>
    <div class="template-list">
      <div>
        <a href="assets/templates/csv_upload_template.csv" download="pro-list-upload-template.csv" class="csv-template">
          <button mat-icon-button class="full-width" data-test="uploadPros">
            <mat-icon [svgIcon]="SvgIconsEnums.FILE_CSV"> </mat-icon>
          </button>
          <p>CSV Template</p>
          <p><span>Download</span></p>
        </a>
      </div>
      <div>
        <a
          href="assets/templates/excel_upload_template.xlsx"
          download="pro-list-upload-template.xlsx"
          class="excel-template"
        >
          <button mat-icon-button class="full-width" data-test="uploadPros">
            <mat-icon [svgIcon]="SvgIconsEnums.FILE_EXCEL"> </mat-icon>
          </button>
          <p>MS Excel Template</p>
          <p><span>Download</span></p>
        </a>
      </div>
    </div>
  </div>
  <div></div>
  <div mat-dialog-actions class="select-employee-dialog__footer">
    <button mat-button (click)="onCancelClick()" data-test="cancel">
      {{ ProDownloadTemplateDialogFormLabelsEnum.CANCEL }}
    </button>
    <button mat-flat-button class="button-continue" (click)="onContinueClick()" data-test="continue">
      {{ ProDownloadTemplateDialogFormLabelsEnum.CONTINUE }}
    </button>
  </div>
</div>
