import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ActionCd, AuditInfo } from '@xpo-ltl/sdk-common';

import * as _ from 'lodash';
import { filter, map, takeUntil } from 'rxjs/operators';

import { AgeTypes } from '../age-types.enum';
import { FormUtils } from '../classes/form-utils.class';
import { NoteEvent } from '../classes/note-event.class';
import { NoteData } from '../classes/notes-data.class';
import { NotesConstants } from '../constants/notes-constants';
import { NoteEmailEventTypesEnum, NotesEntryFormNamesEnum, NotesFormLabelsEnum } from '../enums';
import { NotesEmailsService } from '../services/notes-emails.service';
import { UserNotesEmailsService } from '../services/user.service';
import { NotesFormBuilder } from './notes.form-builder';

@Component({
  selector: 'xpo-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  readonly NotesFormLabelsEnum = NotesFormLabelsEnum;
  private unsubscriber = new Unsubscriber();
  ageTypes = [AgeTypes.NewestToOldest, AgeTypes.OldestToNewest];
  formGroup: FormGroup;
  defaultNoteType: any[];

  get noteTypes$() {
    return this.notesEmailsService.noteTypes$;
  }

  get filterNoteTypes$() {
    return this.notesEmailsService.filterNoteTypes$;
  }

  get notes$() {
    return this.notesEmailsService.notes$.pipe(
      map((notes) => {
        const filterNotes = this.filterNotes(notes);
        return this.sortNotes(filterNotes);
      })
    );
  }

  get notesCount$() {
    return this.notes$.pipe(map((notes) => notes.length));
  }

  get notesEntryFormGroup() {
    return this.formGroup.get(NotesEntryFormNamesEnum.FORM_GROUP_NAME);
  }

  @ViewChild('splitPanel', { static: true })
  splitPanel;

  @ViewChild('age', { static: true })
  age: MatSelect;

  @ViewChild('filterNoteType', { static: true })
  filterNoteType: MatSelect;

  @Input()
  enabled = true;

  @Input()
  set tabChanged(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.splitPanel.setVisibleAreaSizes([50, 50]);
      });
    }
  }

  @Output()
  notesFormGroup = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private notesEmailsService: NotesEmailsService,
    private userService: UserNotesEmailsService
  ) {}

  ngOnInit() {
    this.notesEmailsService.filterNoteTypes = [NotesConstants.filterAll, ...this.notesEmailsService.filterNoteTypes];
    this.filterNoteType.value = NotesConstants.filterAll;
    // Form creation
    this.formGroup = NotesFormBuilder.create(this.fb);
    this.notesEmailsService.defaultNoteType$.subscribe((defaultNoteType) => {
      this.defaultNoteType = defaultNoteType;
    });
  }

  ngAfterViewInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  /**
   * Subscribe to non-Form observables (local and Service)
   */
  private initSubscriptions() {
    this.notesEmailsService.noteEvents$
      .pipe(
        takeUntil(this.unsubscriber.done$),
        filter((event) => !!event && event.event === NoteEmailEventTypesEnum.SENT)
      )
      .subscribe((event) => {
        const notes = this.notesEmailsService.notes.slice();
        notes.push(event.note);
        this.notesEmailsService.notes = notes;
        this.cleanForm();
      });
  }

  private cleanForm() {
    this.clearFormFields();
  }

  addNoteClicked(): void {
    const note = this.createNote();
    this.notesEmailsService.noteEvents = new NoteEvent({
      note,
      event: NoteEmailEventTypesEnum.ADDED,
    });
  }

  private createNote(): NoteData {
    const newNote = new NoteData();
    newNote.authorName = this.userService.currentUser.displayName;
    newNote.auditInfo = new AuditInfo();
    newNote.auditInfo.createdTimestamp = new Date();
    newNote.typeCd = this.notesEntryFormGroup.get(NotesEntryFormNamesEnum.NOTE_TYPE).value;
    newNote.listActionCd = ActionCd.ADD;
    newNote.note = this.notesEntryFormGroup.get(NotesEntryFormNamesEnum.CONTENT).value;
    newNote.subject = this.notesEntryFormGroup.get(NotesEntryFormNamesEnum.SUBJECT).value;
    return newNote;
  }

  private clearFormFields(): void {
    FormUtils.untouchAllControls(this.formGroup);
    this.formGroup.reset();
  }

  private filterNotes(notes: NoteData[]): NoteData[] {
    if (!notes || !notes.length) {
      return [];
    }
    let filteredNotes = [...notes];
    if (!!this.filterNoteType.value && this.filterNoteType.value !== NotesConstants.filterAll) {
      filteredNotes = notes.filter((element) => {
        return element.typeCd === this.filterNoteType.value;
      });
    }
    return filteredNotes;
  }

  private sortNotes(notes: NoteData[]): NoteData[] {
    if (!notes || !notes.length) {
      return [];
    }
    let sortedNotes = [...notes];
    if (!this.age.value || this.age.value === AgeTypes.NewestToOldest) {
      sortedNotes = _.sortBy(notes, (note: NoteData) =>
        note.auditInfo ? note.auditInfo.createdTimestamp : ''
      ).reverse();
    } else {
      sortedNotes = _.sortBy(notes, (note: NoteData) => (note.auditInfo ? note.auditInfo.createdTimestamp : ''));
    }
    return sortedNotes;
  }

  ngAfterViewChecked(): void {
    this.notesFormGroup.emit(this.formGroup);
  }
}
