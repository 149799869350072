import * as _ from 'lodash';
import { DocumentFileTypesEnum, DocumentMimeType, FileErrorEnum } from '../enums';

export class DocumentUtils {
  static contentTypes = [
    { key: 'html', value: 'text/html' },
    { key: 'htm', value: 'text/html' },
    { key: 'shtml', value: 'text/html' },
    { key: 'css', value: 'text/css' },
    { key: 'xml', value: 'text/xml' },
    { key: 'gif', value: 'image/gif' },
    { key: 'jpeg', value: 'image/jpeg' },
    { key: 'jpg', value: 'image/jpeg' },
    { key: 'js', value: 'application/x-javascript' },
    { key: 'atom', value: 'application/atom+xml' },
    { key: 'rss', value: 'application/rss+xml' },
    { key: 'mml', value: 'text/mathml' },
    { key: 'txt', value: 'text/plain' },
    { key: 'jad', value: 'text/vnd.sun.j2me.app-descriptor' },
    { key: 'wml', value: 'text/vnd.wap.wml' },
    { key: 'htc', value: 'text/x-component' },
    { key: 'png', value: 'image/png' },
    { key: 'tif', value: 'image/tiff' },
    { key: 'tiff', value: 'image/tiff' },
    { key: 'wbmp', value: 'image/vnd.wap.wbmp' },
    { key: 'ico', value: 'image/x-icon' },
    { key: 'jng', value: 'image/x-jng' },
    { key: 'bmp', value: 'image/x-ms-bmp' },
    { key: 'svg', value: 'image/svg+xml' },
    { key: 'webp', value: 'image/webp' },
    { key: 'jar', value: 'application/java-archive' },
    { key: 'war', value: 'application/java-archive' },
    { key: 'ear', value: 'application/java-archive' },
    { key: 'hqx', value: 'application/mac-binhex40' },
    { key: 'doc', value: 'application/msword' },
    { key: 'pdf', value: 'application/pdf' },
    { key: 'ps', value: 'application/postscript' },
    { key: 'eps', value: 'application/postscript' },
    { key: 'ai', value: 'application/postscript' },
    { key: 'rtf', value: 'application/rtf' },
    { key: 'xls', value: 'application/vnd.ms-excel' },
    { key: 'ppt', value: 'application/vnd.ms-powerpoint' },
    { key: 'wmlc', value: 'application/vnd.wap.wmlc' },
    { key: 'kml', value: 'application/vnd.google-earth.kml+xml' },
    { key: 'kmz', value: 'application/vnd.google-earth.kmz' },
    { key: '7z', value: 'application/x-7z-compressed' },
    { key: 'cco', value: 'application/x-cocoa' },
    { key: 'jardiff', value: 'application/x-java-archive-diff' },
    { key: 'jnlp', value: 'application/x-java-jnlp-file' },
    { key: 'run', value: 'application/x-makeself' },
    { key: 'pl', value: 'application/x-perl' },
    { key: 'pm', value: 'application/x-perl' },
    { key: 'prc', value: 'application/x-pilot' },
    { key: 'pdb', value: 'application/x-pilot' },
    { key: 'rar', value: 'application/x-rar-compressed' },
    { key: 'rpm', value: 'application/x-redhat-package-manager' },
    { key: 'sea', value: 'application/x-sea' },
    { key: 'swf', value: 'application/x-shockwave-flash' },
    { key: 'sit', value: 'application/x-stuffit' },
    { key: 'tcl', value: 'application/x-tcl' },
    { key: 'tk', value: 'application/x-tcl' },
    { key: 'der', value: 'application/x-x509-ca-cert' },
    { key: 'pem', value: 'application/x-x509-ca-cert' },
    { key: 'crt', value: 'application/x-x509-ca-cert' },
    { key: 'xpi', value: 'application/x-xpinstall' },
    { key: 'xhtml', value: 'application/xhtml+xml' },
    { key: 'zip', value: 'application/zip' },
    { key: 'bin', value: 'application/octet-stream' },
    { key: 'exe', value: 'application/octet-stream' },
    { key: 'dll', value: 'application/octet-stream' },
    { key: 'deb', value: 'application/octet-stream' },
    { key: 'dmg', value: 'application/octet-stream' },
    { key: 'eot', value: 'application/octet-stream' },
    { key: 'iso', value: 'application/octet-stream' },
    { key: 'img', value: 'application/octet-stream' },
    { key: 'msi', value: 'application/octet-stream' },
    { key: 'msp', value: 'application/octet-stream' },
    { key: 'msm', value: 'application/octet-stream' },
    { key: 'msg', value: 'application/octet-stream' },
    { key: 'eml', value: 'application/octet-stream' },
    { key: 'mid', value: 'audio/midi' },
    { key: 'midi', value: 'audio/midi' },
    { key: 'kar', value: 'audio/midi' },
    { key: 'mp3', value: 'audio/mpeg' },
    { key: 'ogg', value: 'audio/ogg' },
    { key: 'ra', value: 'audio/x-realaudio' },
    { key: '3gpp', value: 'video/3gpp' },
    { key: '3gp', value: 'video/3gpp' },
    { key: 'mpeg', value: 'video/mpeg' },
    { key: 'mpg', value: 'video/mpeg' },
    { key: 'mov', value: 'video/quicktime' },
    { key: 'flv', value: 'video/x-flv' },
    { key: 'mng', value: 'video/x-mng' },
    { key: 'asx', value: 'video/x-ms-asf' },
    { key: 'asf', value: 'video/x-ms-asf' },
    { key: 'wmv', value: 'video/x-ms-wmv' },
    { key: 'avi', value: 'video/x-msvideo' },
    { key: 'm4v', value: 'video/mp4' },
    { key: 'mp4', value: 'video/mp4' },
  ];

  static getContentTypeByExtension(extension: string) {
    const result = this.contentTypes.find((x) => x.key === _.toLower(extension));
    return result ? result.value : 'application/octet-stream';
  }

  static getStringBase64(file, callback: Function) {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result.toString().replace(/^.+?(;base64),/, '');
      callback(result);
    };
    reader.readAsDataURL(file);
  }

  static getFileType(fileName: string): string {
    return _.toLower(fileName.substring(fileName.lastIndexOf('.')));
  }

  static shouldRejectFileUpload(file: File): boolean {
    return this.invalidFileTypeByName(file);
  }

  static getFileError(file: File): string {
    if (this.invalidFileTypeByName(file)) {
      const errorMsg = `${FileErrorEnum.UNSUPPORTED_FILE_TYPE}:  ${file.name}`;
      return errorMsg;
    } else {
      return FileErrorEnum.GENERIC_ERROR;
    }
  }

  static invalidFileType(file: File): boolean {
    const getFileMimeType = () => file.type.split('/')[1];
    return _.every(_.values(DocumentMimeType), (mimeType) => _.toLower(getFileMimeType()) !== _.toLower(mimeType));
  }

  static invalidFileTypeByName(file: File): boolean {
    const getFileMimeTypeByName = () => file.name.substring(file.name.lastIndexOf('.')).split('.')[1];
    return _.every(
      _.values(DocumentFileTypesEnum),
      (mimeType) => _.toLower(getFileMimeTypeByName()) !== _.toLower(mimeType)
    );
  }
}
