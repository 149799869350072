export enum SelectEmployeeDialogFormLabelsEnum {
  LAST_NAME = 'Last Name',
  FIRST_NAME = 'First Name',
  JOB_TITLE = 'Job Title',
  SIC_CODE = 'SIC Code',
  LOCATION_SIC_NAME = 'Location Sic',
  JOB_CODE = 'Job Code',
  SF_EMPLOYEE_ID = 'SF Employee Id',
  ADDRESS_1 = 'Address',
  CITY_NAME = 'City',
  STATE_CD = 'State',
  COUNTRY_CODE = 'Country',
  ZIP = 'Zip',
  SEARCH = 'Search',
  CANCEL = 'Cancel',
  SELECT = 'Select',
  NOT_FOUND = 'No XPO employee was found with your search criteria. Please try a new search.',
  ERROR_API = 'An error happened.',
}
