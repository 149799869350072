import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotesEntryFormNamesEnum, NotesEntryMaxLengthsEnum } from '../../enums';
import { NotesEntryContentMaxLengthValidator } from '../../validators';

export class NotesEntryFormBuilder {
  static create(fb: FormBuilder): FormGroup {
    return fb.group({
      [NotesEntryFormNamesEnum.SUBJECT]: [
        '',
        [Validators.required, Validators.maxLength(NotesEntryMaxLengthsEnum.SUBJECT)],
      ],
      [NotesEntryFormNamesEnum.CONTENT]: ['', [Validators.required, NotesEntryContentMaxLengthValidator]],
      [NotesEntryFormNamesEnum.NOTE_TYPE]: ['', Validators.required],
    });
  }
}
