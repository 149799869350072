<div class="disputes-history">
  <h1 mat-dialog-title>
    {{ title }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div mat-dialog-content>
    <div class="disputes-history__resume" *ngIf="currentStatus" [formGroup]="formGroup">
      <mat-form-field class="no-border" floatLabel="always" data-test="disputes-id">
        <mat-label>{{ DisputesHistoryDialogFormLabelsEnum.DISPUTE_NUMBER }}</mat-label>
        {{ data.disputeId }}
        <input
          hidden="true"
          matInput
          [formControlName]="DisputesHistoryDialogFormControlNamesEnum.DISPUTE_NUMBER"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field class="no-border" floatLabel="always" data-test="disputes-status">
        <mat-label>{{ DisputesHistoryDialogFormLabelsEnum.CURRENT_STATUS }}</mat-label>
        {{ sanitizeDisputeStatus(currentStatus.statusCd) }}
        <input
          hidden="true"
          matInput
          [formControlName]="DisputesHistoryDialogFormControlNamesEnum.CURRENT_STATUS"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field class="no-border" floatLabel="always" data-test="disputes-last-updated-date">
        <mat-label>{{ DisputesHistoryDialogFormLabelsEnum.LAST_UPDATED_DATE }}</mat-label>
        {{ currentStatus[DisputesHistoryDialogFieldNamesEnum.UPDATED_BY] }}
        <input
          hidden="true"
          matInput
          [formControlName]="DisputesHistoryDialogFormControlNamesEnum.LAST_UPDATED_DATE"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field class="no-border" floatLabel="always" data-test="disputes-last-updated-by">
        <mat-label>{{ DisputesHistoryDialogFormLabelsEnum.LAST_UPDATED_BY }}</mat-label>
        {{ currentStatus[DisputesHistoryDialogFieldNamesEnum.UPDATED_DATE] | date: 'MM/dd/yyyy, HH:mm' }}
        <input
          hidden="true"
          matInput
          [formControlName]="DisputesHistoryDialogFormControlNamesEnum.LAST_UPDATED_BY"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <h2>Previous Statuses</h2>
    <span *ngIf="(dataSource$ | async)?.length === 1">There are no previous statuses to show.</span>
    <table
      mat-table
      [dataSource]="dataSource$ | async"
      class="disputes-history__table"
      *ngIf="(dataSource$ | async)?.length > 1"
    >
      <ng-container [matColumnDef]="DisputesHistoryDialogFieldNamesEnum.UPDATED_DATE">
        <th mat-header-cell *matHeaderCellDef>{{ DisputesHistoryDialogFieldLabelsEnum.UPDATED_DATE }}</th>
        <td mat-cell *matCellDef="let row">
          {{ row[DisputesHistoryDialogFieldNamesEnum.UPDATED_DATE] | date: 'MM/dd/yyyy, HH:mm' }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="DisputesHistoryDialogFieldNamesEnum.DISPUTE_STATUS">
        <th mat-header-cell *matHeaderCellDef>{{ DisputesHistoryDialogFieldLabelsEnum.DISPUTE_STATUS }}</th>
        <td mat-cell *matCellDef="let row">
          {{ sanitizeDisputeStatus(row[DisputesHistoryDialogFieldNamesEnum.DISPUTE_STATUS]) }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="DisputesHistoryDialogFieldNamesEnum.UPDATED_BY">
        <th mat-header-cell *matHeaderCellDef>{{ DisputesHistoryDialogFieldLabelsEnum.UPDATED_BY }}</th>
        <td mat-cell *matCellDef="let row">{{ row[DisputesHistoryDialogFieldNamesEnum.UPDATED_BY] }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
