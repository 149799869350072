export enum DisputeDetailsFormNamesEnum {
  // ALL
  FORM_GROUP_NAME = 'DisputeDetails',
  REQUEST_REASON = 'RequestReason',
  REQUEST_EXPLANATION = 'RequestExplanation',

  // DRP
  DRP_TYPE = 'DRPType',
  AUTHORIZED_PERSON_EMP_ID = 'AuthorizedPersonEmpId',
  AUTHORIZED_PERSON = 'AuthorizedPerson',
  PROBLEM = 'Problem',
  PREVENTIVE_MEASURES = 'PreventiveMeasures',
  SPECIFIC_ACTIONS = 'SpecificActions',
  OPERATING_RATIO = 'OperatingRatio',
  CURRENCY = 'Currency',
  MONTHLY_REVENUE_POTENTIAL = 'MonthlyRevenuePotential',
  AVERAGE_MONTHLY_REVENUE = 'AverageMonthlyRevenue',
  DRP_SPECIFICATIONS_FORM_GROUP_NAME = 'drpSpecificationsFormGroup',
  DRP_EMPLOYEE_ID = 'employeeId',
  DRP_FULL_NAME = 'drpFullName',
  DRP_FIRST_NAME = 'firstName',
  DRP_MIDDLE_NAME = 'middleName',
  DRP_LAST_NAME = 'lastName',
  DRP_JOB_DESCRIPTION = 'jobDescription',
  DRP_DOMICILE_SIC = 'domicileSicCd',
  DRP_SUP_EMPLOYEE_ID = 'supervisorEmployeeId',
  DRP_BUSINESS_PHONE_TXT = 'businessPhoneTxt',
  ASSIGN = 'assign',

  // WNI
  SHIPPER_WEBSITE = 'ShipperWebsite',
  INSPECTOR_SIC_CODE = 'InspectorSICCode',
  INSPECTOR_AREA = 'InspectorArea',
  INSPECTOR_FORM_GROUP_NAME = 'InspectorFormGroup',
  INSPECTOR_FULL_NAME = 'inspectorFullName',
  INSPECTOR_EMPLOYEE = 'employeeId',
  INSPECTOR_FIRST_NAME = 'firstName',
  INSPECTOR_MIDDLE_NAME = 'middleName',
  INSPECTOR_LAST_NAME = 'lastName',
  INSPECTOR_JOB_DESCRIPTION = 'jobDescription',
  INSPECTOR_DOMICILE_SIC = 'domicileSicCd',
  INSPECTOR_SUP_EMPLOYEE_ID = 'supervisorEmployeeId',
  INSPECTOR_PHONE = 'businessPhoneTxt',
}
