import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { DisputeStatusCd } from '@xpo-ltl/sdk-common';
import { DisputesApiService, ListStatusLogsForItemQuery, StatusLog } from '@xpo-ltl/sdk-disputes';
import { HumanResourceApiService, ListEmployeesByEmpIdRqst } from '@xpo-ltl/sdk-humanresource';

import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  DisputesHistoryDialogFieldLabelsEnum,
  DisputesHistoryDialogFieldNamesEnum,
  DisputesHistoryDialogFormControlNamesEnum,
  DisputesHistoryDialogFormLabelsEnum,
} from '../../../../enums';
import { HumanizePipe } from '../../../../pipes/humanize.pipe';
import { DisputeStatusHelperService } from '../../../../services/disputes/dispute-status-helper.service';

@Component({
  selector: 'app-disputes-history-dialog',
  templateUrl: './disputes-history-dialog.component.html',
  styleUrls: ['./disputes-history-dialog.component.scss'],
})
export class DisputesHistoryDialogComponent implements OnInit {
  title = DisputesHistoryDialogFormLabelsEnum.TITLE;
  readonly DisputesHistoryDialogFieldNamesEnum = DisputesHistoryDialogFieldNamesEnum;
  readonly DisputesHistoryDialogFieldLabelsEnum = DisputesHistoryDialogFieldLabelsEnum;
  readonly DisputesHistoryDialogFormLabelsEnum = DisputesHistoryDialogFormLabelsEnum;
  readonly DisputesHistoryDialogFormControlNamesEnum = DisputesHistoryDialogFormControlNamesEnum;
  formGroup: FormGroup;
  currentStatus: StatusLog;
  dataSourceSubject = new BehaviorSubject([]);
  dataSource$ = this.dataSourceSubject.asObservable();
  displayedColumns = [
    DisputesHistoryDialogFieldNamesEnum.DISPUTE_STATUS,
    DisputesHistoryDialogFieldNamesEnum.UPDATED_BY,
    DisputesHistoryDialogFieldNamesEnum.UPDATED_DATE,
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private humanize: HumanizePipe,
    public dialogRef: MatDialogRef<DisputesHistoryDialogComponent>,
    private disputesApiService: DisputesApiService,
    private humanResourceApiService: HumanResourceApiService
  ) {}

  ngOnInit() {
    this.formInit();
    this.disputesHistoryInit();
  }

  formInit() {
    this.formGroup = this.fb.group({
      [DisputesHistoryDialogFormControlNamesEnum.DISPUTE_NUMBER]: '',
      [DisputesHistoryDialogFormControlNamesEnum.CURRENT_STATUS]: '',
      [DisputesHistoryDialogFormControlNamesEnum.LAST_UPDATED_BY]: '',
      [DisputesHistoryDialogFormControlNamesEnum.LAST_UPDATED_DATE]: '',
    });
  }

  sanitizeDisputeStatus(disputeStatus: DisputeStatusCd): string {
    return DisputeStatusHelperService.sanitizeDisputeStatus(disputeStatus, this.humanize);
  }

  disputesHistoryInit() {
    const request = new ListStatusLogsForItemQuery();
    request.itemInstId = this.data.itemId;
    this.disputesApiService
      .listStatusLogsForItem(request)
      .pipe(map((listStatusLogsForItemResp) => listStatusLogsForItemResp.statusLogs))
      .subscribe((statuses) => {
        if (statuses && statuses.length) {
          let batchUsers = null;
          batchUsers = statuses.filter(ele => ele.auditInfo.createdById === "BatchDisputesAp");
          if(batchUsers !== null){
            batchUsers.forEach(ele => {
              ele.employeeName = ele.auditInfo.createdById
            });
          }
          const statusesCompleted = [];
          statuses.map((status) => {
            const empId = status.auditInfo.createdById;
            const listEmployeesByEmpIdRqst = new ListEmployeesByEmpIdRqst();
            listEmployeesByEmpIdRqst.employeeId = [empId];
            this.humanResourceApiService.listEmployeesByEmpId(listEmployeesByEmpIdRqst).subscribe((employees:any) => {
              let completeStatus = {};
              if(empId !== 'BatchDisputesAp'){
                let employeeName = employees.employeeNames[0].fullName
                ? employees.employeeNames[0].fullName
                : 'Not Available';
                completeStatus = {
                  ...status,
                  employeeName,
                };
              }else{
                completeStatus = {
                  ...status,
                  batchUsers
                };
              }
              statusesCompleted.push(completeStatus);
              const sortedStatuses = _.sortBy(
                statusesCompleted,
                DisputesHistoryDialogFieldNamesEnum.UPDATED_DATE
              ).reverse();
              this.currentStatus = sortedStatuses[0];
              this.dataSourceSubject.next(sortedStatuses);
            });
          });
        } else {
          this.dataSourceSubject.next([]);
        }
      });
  }
}
