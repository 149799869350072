import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormatValidationService } from '@xpo-ltl/common-services';

import { DisputeRequestReasonHelper } from '../classes/disputes/dispute-request-reason-helper';
import { RowShipment } from '../classes/row-shipment';
import { DisputesDataService } from '../services/disputes/disputes-data.service';

export class ProValidators {
  static format(formatValidationService: FormatValidationService): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control || !control.value) {
        return null;
      }

      if (control.value.length < 9 || !formatValidationService.isValidProNumber(control.value)) {
        return { invalidProFormat: true };
      }
      return null;
    };
  }

  static recycledProArrayValidator(array: FormArray): ValidationErrors {
    if (!array || !array.controls) {
      return null;
    }
    const pendingToFix = array.controls
      .map((control) => control.value)
      .some((value: RowShipment) => value && value.errors && value.errors.needBeFixed);
    return pendingToFix ? { unresolvedConflicts: true } : undefined;
  }

  static relatedClaimArrayValidator(disputesDataService: DisputesDataService) {
    return (array: FormArray): ValidationErrors => {
      const isClaimIntegration = DisputeRequestReasonHelper.isValidForClaimIntegration(
        disputesDataService.requestReasonSelected
      );
      if (!array || !array.controls || !isClaimIntegration) {
        return null;
      }
      const pendingToFix = array.controls
        .map((control) => control.value)
        .some((value: RowShipment) => value && value.errors && value.errors.relatedClaimNotFound);
      return pendingToFix ? { relatedClaimNotFound: true } : null;
    };
  }

  static relatedClaimNotApprovedArrayValidator(disputesDataService: DisputesDataService) {
    return (array: FormArray): ValidationErrors => {
      const isClaimIntegration = DisputeRequestReasonHelper.isValidForClaimIntegration(
        disputesDataService.requestReasonSelected
      );
      if (!array || !array.controls || !isClaimIntegration) {
        return null;
      }
      const pendingToFix = array.controls
        .map((control) => control.value)
        .some((value: RowShipment) => value && value.errors && value.errors.relatedClaimNotApproved);
      return pendingToFix ? { relatedClaimNotApproved: true } : null;
    };
  }

  static multipleRelatedClaimArrayValidator(disputesDataService: DisputesDataService) {
    return (array: FormArray): ValidationErrors => {
      const isClaimIntegration = DisputeRequestReasonHelper.isValidForClaimIntegration(
        disputesDataService.requestReasonSelected
      );
      if (!array || !array.controls || !isClaimIntegration) {
        return null;
      }
      const pendingToFix = array.controls
        .map((control) => control.value)
        .some((value: RowShipment) => value && value.errors && value.errors.multipleRelatedClaims);
      return pendingToFix ? { multipleRelatedClaims: true } : null;
    };
  }

  static relatedClaimWithPendingRebuttalArrayValidator(disputesDataService: DisputesDataService) {
    return (array: FormArray): ValidationErrors => {
      const isClaimIntegration = DisputeRequestReasonHelper.isValidForClaimIntegration(
        disputesDataService.requestReasonSelected
      );
      if (!array || !array.controls || !isClaimIntegration) {
        return null;
      }
      const pendingToFix = array.controls
        .map((control) => control.value)
        .some((value: RowShipment) => value && value.errors && value.errors.relatedClaimWithPendingRebuttal);
      return pendingToFix ? { relatedClaimWithPendingRebuttal: true } : null;
    };
  }
}
