import { DisputeStatusCd } from '@xpo-ltl/sdk-common';
import { GetDisputeResp } from '@xpo-ltl/sdk-disputes';

import { HumanizePipe } from '../../pipes/humanize.pipe';

export class DisputeStatusHelperService {
  static readonly STATUS_OPEN: DisputeStatusCd[] = [
    DisputeStatusCd.SUBMITTED,
    DisputeStatusCd.UNDER_REVIEW,
    DisputeStatusCd.SUBMITTED_FOR_REVIEW,
    DisputeStatusCd.AMENDED,
    DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS,
    DisputeStatusCd.PENDING_DIRECTOR_APPROVAL,
    DisputeStatusCd.PENDING_SUPERVISOR_APPROVAL,
    DisputeStatusCd.PENDING_SVP_APPROVAL,
    DisputeStatusCd.PENDING_VP_APPROVAL,
    DisputeStatusCd.PENDING_FINANCE_APPROVAL,
    DisputeStatusCd.APPROVED,
  ];
  static readonly STATUS_CLOSE: DisputeStatusCd[] = [
    DisputeStatusCd.DECLINED,
    DisputeStatusCd.CANCELLED,
    DisputeStatusCd.DELETED,
    DisputeStatusCd.CLOSED,
  ];

  /**
   * Return if a dispute is some of states in params
   *
   */
  static isAny(dispute: GetDisputeResp, disputeStates: DisputeStatusCd[]): boolean {
    const disputeStatus = dispute ? dispute.dispute.statusCd : undefined;
    if (!disputeStatus) {
      return;
    }
    return disputeStates.includes(disputeStatus);
  }

  /**
   * Concat two dispute status. It's more priority left dispute states
   */
  static concatDisputeStatus(
    disputeStates: DisputeStatusCd[],
    disputeStatesToAdd: DisputeStatusCd[]
  ): DisputeStatusCd[] {
    if (!disputeStatesToAdd) {
      return;
    }
    if (!disputeStates) {
      return disputeStatesToAdd;
    }
    return disputeStates;
  }

  /**
   * Return if a dispute is some of states in params
   */
  static isEqualTo(dispute: GetDisputeResp, disputeStatus: DisputeStatusCd): boolean {
    const status = dispute ? dispute.dispute.statusCd : undefined;
    if (!status) {
      return;
    }
    return status === disputeStatus;
  }

  /**
   * Return if a dispute is open
   * [SUBMITTED,UNDER_REVIEW,SUBMITTED_FOR_REVIEW]
   *
   */
  static isDisputeOpen(dispute: GetDisputeResp): boolean {
    const disputeStatus = dispute ? dispute.dispute.statusCd : undefined;
    if (!disputeStatus) {
      return;
    }
    return this.STATUS_OPEN.includes(disputeStatus);
  }

  /**
   * Return if a dispute is SUBMITTED
   *
   */
  static isDisputeSubmitted(dispute: GetDisputeResp): boolean {
    return this.isEqualTo(dispute, DisputeStatusCd.SUBMITTED);
  }

  /**
   * Return if a dispute is [DRAFT]
   */
  static isDisputeDraft(dispute: GetDisputeResp): boolean {
    return this.isEqualTo(dispute, DisputeStatusCd.DRAFT);
  }

  /**
   * Return if a dispute is [UNDER_REVIEW, COLLECTIONS_ESCALATION_UNDER_REVIEW]
   */
  static isUnderReview(dispute: GetDisputeResp): boolean {
    return this.isAny(dispute, [DisputeStatusCd.UNDER_REVIEW, DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW]);
  }

  /**
   * Return if a dispute is [CORRECTIONS_UNDER_PROGRESS]
   */
  static isCorrectionsUnderProgress(dispute: GetDisputeResp): boolean {
    return this.isEqualTo(dispute, DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS);
  }

  /**
   * Return if a dispute is [SUBMITTED_FOR_REVIEW]
   */
  static isSubmittedForReview(dispute: GetDisputeResp): boolean {
    return this.isEqualTo(dispute, DisputeStatusCd.SUBMITTED_FOR_REVIEW);
  }

  /**
   * Return if a dispute is [PENDING_DIRECTOR_APPROVAL]
   */
  static isPendingDirectorApproval(dispute: GetDisputeResp): boolean {
    return this.isEqualTo(dispute, DisputeStatusCd.PENDING_DIRECTOR_APPROVAL);
  }

  /**
   * Return if a dispute is [PENDING_VP_APPROVAL]
   */
  static isPendingVPApproval(dispute: GetDisputeResp): boolean {
    return this.isEqualTo(dispute, DisputeStatusCd.PENDING_VP_APPROVAL);
  }

  /**
   * Return if a dispute is [PENDING_SVP_APPROVAL]
   */
  static isPendingSVPApproval(dispute: GetDisputeResp): boolean {
    return this.isEqualTo(dispute, DisputeStatusCd.PENDING_SVP_APPROVAL);
  }

  /**
   * Return if a dispute is [PENDING_FINANCE_APPROVAL]
   */
  static isPendingFinanceApproval(dispute: GetDisputeResp): boolean {
    return this.isEqualTo(dispute, DisputeStatusCd.PENDING_FINANCE_APPROVAL);
  }

  /**
   * Return if a dispute is open
   * [APPROVED,DECLINED,CANCELLED,DELETED]
   *
   */
  static isDisputeClose(dispute: GetDisputeResp): boolean {
    const disputeStatus = dispute ? dispute.dispute.statusCd : undefined;
    if (!disputeStatus) {
      return;
    }
    return this.STATUS_CLOSE.includes(disputeStatus);
  }

  static sanitizeDisputeStatus(disputeStatus: DisputeStatusCd, humanize: HumanizePipe): string {
    if (!disputeStatus) {
      return;
    }
    if (disputeStatus === DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW) {
      disputeStatus = DisputeStatusCd.UNDER_REVIEW;
    }
    return humanize.transform(disputeStatus);
  }
}
