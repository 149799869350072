<app-base-dispute-dialog [title]="title" [buttons]="successDisputeDialogButtons" (buttonClick)="handleClick($event)">
    <div class="status">
        <div class="status--message with-icon">
            <mat-icon [ngClass]="statusClass">{{ statusIcon }}</mat-icon>
            {{ statusMessage }}
        </div>

        <div class="status--text with-icon">
            {{ message }}
            <span *ngIf="!!disputeId" class="dispute-id" data-test="disputeId">#{{ disputeId }}</span>
            <a *ngIf="!!additionalTextIcon && !!additionalLinkText" xpo-regular-link (click)="handleAdditionalLinkClick()" data-test="copyLink">
                <mat-icon>{{ additionalTextIcon }}</mat-icon> {{ additionalLinkText }}
            </a>
        </div>
    </div>
</app-base-dispute-dialog>