<div class="emails-detail">
  <div class="emails-detail__actionbar">
    <div class="emails-detail__actionbar-left" *ngIf="enabled">
      <button id="reply" mat-button mat-icon-button aria-label="reply" (click)="replyClicked()" data-test="emailReply">
        <mat-icon matTooltip="Reply">reply</mat-icon>
      </button>
      <button
        id="reply-all"
        mat-button
        mat-icon-button
        aria-label="reply all"
        (click)="replyAllClicked()"
        data-test="emailReplyAll"
      >
        <mat-icon matTooltip="Reply All">reply_all</mat-icon>
      </button>
      <button
        id="forward"
        mat-button
        mat-icon-button
        aria-label="forward"
        (click)="forwardClicked()"
        data-test="emailForward"
      >
        <mat-icon matTooltip="Forward">forward</mat-icon>
      </button>
      <button
        mat-button
        mat-icon-button
        aria-label="print"
        [printSectionId]="'print-section' + email.emailInstId"
        ngxPrint
        [useExistingCss]="true"
      >
        <mat-icon matTooltip="Print">print</mat-icon>
      </button>
    </div>
    <div class="emails-detail__actionbar-right">
      <div class="btn-wrapper" *ngIf="email?.emailAttach?.length > 0">
        <button
          *ngIf="email?.emailAttach?.length === 1"
          id="attachments"
          mat-button
          mat-icon-button
          aria-label="attachments"
          data-test="emailAttachments"
          (click)="onClickAttachment(email.emailAttach[0])"
        >
          <mat-icon>attach_file</mat-icon>
        </button>
        <button
          *ngIf="email?.emailAttach?.length > 1"
          id="attachments"
          mat-button
          mat-icon-button
          aria-label="attachments"
          data-test="emailAttachments"
          [matMenuTriggerFor]="attachmentsMenu"
        >
          <mat-icon>attach_file</mat-icon>
        </button>
        <mat-menu #attachmentsMenu>
          <div [ngClass]="{ 'menu-scrollable': email?.emailAttach?.length > 10 }">
            <button mat-menu-item (click)="onClickDownloadAllDocuments()">
              All Documents
            </button>
            <button mat-menu-item *ngFor="let attach of email.emailAttach" (click)="onClickAttachment(attach)">
              {{ attach.attachName }}
            </button>
          </div>
        </mat-menu>
        <div class="xpo-badge" [xpoBadge]="badgeConfig"></div>
      </div>
    </div>
  </div>
  <div [id]="'print-section' + email.emailInstId">
    <div class="emails-detail__subject" data-test="emailSubject">
      <h3 class="emails-detail__subject-text">{{ email?.subject }}</h3>
      <div class="emails-detail__subject-date">{{ email?.auditInfo?.createdTimestamp | date: 'HH:mm MM/dd/yyyy' }}</div>
    </div>
    <div class="emails-detail__sender" data-test="emailSender">
      <div>Sender:</div>
      <div>{{ email?.senderEmailAddress }}</div>
    </div>
    <div class="emails-detail__recipients" data-test="emailRecipients">
      <div>Recipients:</div>
      <div>{{ email?.recipientEmailAddress }}</div>
    </div>
    <div class="emails-detail__cc" *ngIf="email?.ccEmailAddress" data-test="emailAddress">
      <div>CC:</div>
      <div>{{ email?.ccEmailAddress }}</div>
    </div>
    <div class="emails-detail__body" data-test="emailBody">
      <div *ngIf="!isExternalSender(email?.message)" [innerHTML]="email?.message"></div>
      <pre *ngIf="isExternalSender(email?.message)" [innerHTML]="email?.message"></pre>
    </div>
  </div>
</div>
