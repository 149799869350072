import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

import { Unsubscriber, XpoLtlWindowService } from '@xpo-ltl/ngx-ltl';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { ActionCd, Attachment, AuditInfo, EmailDirectionTypeCd } from '@xpo-ltl/sdk-common';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';

import { BehaviorSubject, Observable, of } from 'rxjs';

import * as _ from 'lodash';

import { SplitComponent } from 'angular-split';

import { filter, map, takeUntil } from 'rxjs/operators';

import { FilePickerDirective, ReadFile, ReadMode } from 'ngx-file-helpers';

import { AgeTypes } from '../age-types.enum';
import { EmailAttachData } from '../classes/email-attach-data.class';
import { EmailData } from '../classes/email-data.class';
import { EmailEvent } from '../classes/email-event.class';
import { DirectionTypes } from '../direction-types.enum';
import { EmailActionTypes } from '../email-action-types.enum';
import { EmailActionInterface } from '../email-action.interface';
import {
  EmailEntryFormNamesEnum,
  EmailFormLabelsEnum,
  EmailSubjectPrefixesEnum,
  NoteEmailEventTypesEnum,
} from '../enums';
import { EmailsConfig } from '../interfaces/email-config.interface';
import { DocumentSearchPipe } from '../pipes/document-search.pipe';
import { NotesEmailsService } from '../services/notes-emails.service';

import { EmailEntryComponent } from './email-entry/email-entry.component';
import { EmailEntryFormBuilder } from './email-entry/email-entry.form-builder';

@Component({
  selector: 'xpo-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  readonly EmailFormLabelsEnum = EmailFormLabelsEnum;
  directionTypes = [DirectionTypes.IncomingAndOutgoing, DirectionTypes.Incoming, DirectionTypes.Outgoing];
  ageTypes = [AgeTypes.NewestToOldest, AgeTypes.OldestToNewest];
  draftInd = false;

  private uploadBadgeHiddenSubject = new BehaviorSubject<boolean>(true);
  uploadBadgeHidden$ = of(this.uploadBadgeHiddenSubject);
  private uploadBadgeSubject = new BehaviorSubject<string>('');

  uploadBadgeConfig = {
    xpoBadgeHidden: this.uploadBadgeHiddenSubject,
    xpoBadge: this.uploadBadgeSubject,
    xpoBadgeFontSize: '12px',
    xpoBadgeBackgroundColor: 'black',
    xpoBadgeColor: '#ffffff',
  };

  get templates$(): Observable<any[]> {
    return this.notesEmailsService.emailTemplateTypes$;
  }

  get defaultEmailTemplate$(): Observable<any> {
    return this.notesEmailsService.defaultEmailTemplateType$;
  }

  get emails$(): Observable<EmailData[]> {
    return this.notesEmailsService.emails$;
  }

  get filteredEmails$() {
    return this.notesEmailsService.emails$.pipe(
      map((emails) => {
        const filteredEmails = this.filterEmails(emails);
        return this.sortEmails(filteredEmails);
      })
    );
  }

  private fileList = new Array<ReadFile>();
  private attachmentsSubject = new BehaviorSubject<string>(undefined);
  attachments$ = this.attachmentsSubject.asObservable();

  private uploadingFileSubject = new BehaviorSubject<boolean>(false);
  uploadingFile$ = this.uploadingFileSubject.asObservable();

  emailFormGroup: FormGroup;

  private unsubscriber = new Unsubscriber();

  readMode = ReadMode.BinaryString;

  @ViewChild('splitPanel', { static: true })
  splitPanel: SplitComponent;

  @ViewChild('direction', { static: false })
  direction: MatSelect;

  @ViewChild('age', { static: false })
  age: MatSelect;

  @ViewChild('dms', { static: true })
  dms: MatSelect;

  @ViewChild(EmailEntryComponent, { static: true })
  emailEntryComponent: EmailEntryComponent;

  @ViewChild(FilePickerDirective, { static: true })
  private filePicker;

  @Input()
  enabled = true;

  @Output()
  emailsForm = new EventEmitter<FormGroup>();

  @Input()
  set tabChanged(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.splitPanel.setVisibleAreaSizes([50, 50]);
      });
    }
  }

  @Input()
  config: EmailsConfig = {};

  constructor(
    private fb: FormBuilder,
    private formatValidationService: FormatValidationService,
    private documentSearchPipe: DocumentSearchPipe,
    private notesEmailsService: NotesEmailsService,
    private windowService: XpoLtlWindowService
  ) {}

  ngOnInit() {
    this.initForm();
    this.initSubscriptions();
    this.updateBadge();
    this.updateAttachments();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  /**
   * Form creation and ste values
   */
  private initForm() {
    // Form Creation
    this.emailFormGroup = EmailEntryFormBuilder.create(this.fb, this.formatValidationService);
  }

  /**
   * Subscribe to non-Form observables (local and Service)
   */
  private initSubscriptions() {
    this.dms.valueChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe((value) => {
      this.updateBadge();

      this.updateAttachments();
    });

    this.notesEmailsService.emailEvents$
      .pipe(
        takeUntil(this.unsubscriber.done$),
        filter((event) => !!event && event.event === NoteEmailEventTypesEnum.SENT)
      )
      .subscribe((event) => {
        const email = this.notesEmailsService.emails.slice();
        email.push(event.email);
        this.notesEmailsService.emails = email;
        this.cleanForm();
      });
  }

  private cleanForm() {
    this.resetForm();
    this.handleClearAttachments();
  }

  handleSendClicked(): void {
    !!this.config.email
      ? // updating an existing email
        this.updateEmail()
      : // sending a new email
        this.sendNewEmail();
  }

  private updateEmail() {
    // find the email
    const emailToUpdate = {
      ...this.config.email,
      recipientEmailAddressTxt: this.recipientControl.value,
      ccEmailAddressTxt: this.ccControl.value,
      subjectTxt: this.subjectControl.value,
      messageTxt: this.bodyControl.value,
      directionTypeCd: EmailDirectionTypeCd.OUTBOUND,
      draftEmailInd: _.get(this.config, 'markAsDraft', false),
      listActionCd: ActionCd.UPDATE,

      // TODO: - we have no way of updloading new attachments to an existing email!
    };

    this.notesEmailsService.emailEvents = new EmailEvent({
      email: emailToUpdate,
      event: NoteEmailEventTypesEnum.UPDATED,
    });
  }

  private sendNewEmail() {
    const email = new EmailData();
    email.auditInfo = new AuditInfo();
    email.auditInfo.createdTimestamp = new Date();
    email.ccEmailAddress = this.ccControl.value;
    email.directionTypeCd = EmailDirectionTypeCd.OUTBOUND;
    // Set to null  due to LSS-1057
    email.senderEmailAddress = null;
    email.message = this.bodyControl.value;
    email.subject = this.subjectControl.value;
    email.recipientEmailAddress = this.recipientControl.value;
    email.readInd = false;
    email.draftEmailInd = false;
    email.listActionCd = ActionCd.ADD;
    email.emailAttach = this.buildAttachments().map((attach) => {
      const emailAttach = new EmailAttachData();
      emailAttach.attachmentDataTxt = attach;
      emailAttach.listActionCd = ActionCd.ADD;
      emailAttach.attachName = attach.fileName;
      emailAttach.attachMimeType = attach.contentType;
      return emailAttach;
    });

    this.notesEmailsService.emailEvents = new EmailEvent({
      email,
      event: NoteEmailEventTypesEnum.ADDED,
    });
  }

  private resetForm() {
    this.fileList = new Array<ReadFile>();
    this.attachmentsSubject.next(undefined);
    this.uploadingFileSubject.next(false);
    this.filePicker.reset();
    this.uploadBadgeSubject.next(undefined);
    this.clearFormFields();
  }

  startUpload(): void {
    this.uploadingFileSubject.next(true);
  }

  cancelUpload(): void {
    this.uploadingFileSubject.next(false);
  }

  onReadStart(fileCount: number): void {
    this.uploadingFileSubject.next(true);
  }

  onReadEnd(fileCount: number): void {
    this.uploadingFileSubject.next(false);
  }

  onFilePicked(file: ReadFile): void {
    this.fileList.push(file);
    this.updateBadge();
    this.updateAttachments();
    this.uploadingFileSubject.next(false);
  }

  private get subjectControl(): AbstractControl {
    return this.emailFormGroup.get(EmailEntryFormNamesEnum.SUBJECT);
  }

  private get recipientControl(): AbstractControl {
    return this.emailFormGroup.get(EmailEntryFormNamesEnum.RECIPIENTS);
  }

  private get ccControl(): AbstractControl {
    return this.emailFormGroup.get(EmailEntryFormNamesEnum.CC);
  }

  private get bodyControl(): AbstractControl {
    return this.emailFormGroup.get(EmailEntryFormNamesEnum.EMAIL_CONTENT);
  }

  emailActionHandler($event: EmailActionInterface): void {
    switch ($event.action) {
      case EmailActionTypes.Reply:
        this.reply($event.email);
        break;
      case EmailActionTypes.ReplyAll:
        this.replyAll($event.email);
        break;
      case EmailActionTypes.Forward:
        this.forward($event.email);
        break;
    }
  }

  private reply(email: EmailData): void {
    this.emailEntryComponent.setDefaultTemplate();
    this.subjectControl.setValue(
      email.subject.includes(EmailSubjectPrefixesEnum.RE)
        ? email.subject
        : `${EmailSubjectPrefixesEnum.RE} ${email.subject}`
    );
    this.recipientControl.setValue(email.senderEmailAddress);
    this.bodyControl.setValue(
      `${this.getReplyHeader(
        email.subject,
        email.auditInfo.createdTimestamp,
        email.senderEmailAddress,
        email.recipientEmailAddress,
        email.ccEmailAddress
      )}${email.message}`
    );
  }

  private replyAll(email: EmailData): void {
    this.emailEntryComponent.setDefaultTemplate();
    this.subjectControl.setValue(`${EmailSubjectPrefixesEnum.RE} ${email.subject}`);
    this.recipientControl.setValue(`${email.senderEmailAddress}; ${email.recipientEmailAddress}`);
    this.ccControl.setValue(email.ccEmailAddress);
    this.bodyControl.setValue(
      `${this.getReplyHeader(
        email.subject,
        email.auditInfo.createdTimestamp,
        email.senderEmailAddress,
        email.recipientEmailAddress,
        email.ccEmailAddress
      )}${email.message}`
    );
  }

  private forward(email: EmailData): void {
    this.emailEntryComponent.setDefaultTemplate();
    this.subjectControl.setValue(`${EmailSubjectPrefixesEnum.FWD} ${email.subject}`);
    this.bodyControl.setValue(
      `${this.getReplyHeader(
        email.subject,
        email.auditInfo.createdTimestamp,
        email.senderEmailAddress,
        email.recipientEmailAddress,
        email.ccEmailAddress
      )}${email.message}`
    );
  }

  private filterEmails(emails: EmailData[]): EmailData[] {
    if (!emails || !emails.length) {
      return [];
    }
    const filterFunc = (email: EmailData) => {
      return (
        !_.get(this.direction, 'value') ||
        this.direction.value === DirectionTypes.IncomingAndOutgoing ||
        (this.direction.value === DirectionTypes.Incoming && email.directionTypeCd === EmailDirectionTypeCd.INBOUND) ||
        (this.direction.value === DirectionTypes.Outgoing && email.directionTypeCd === EmailDirectionTypeCd.OUTBOUND)
      );
    };
    return _.filter(emails, filterFunc);
  }

  private sortEmails(emails: EmailData[]): EmailData[] {
    if (!emails || !emails.length) {
      return [];
    }
    if (this.config.composeOnly) {
      // don't load emails if we are only composing
      return emails;
    }
    let filteredEmails = [...emails];
    if (!this.age.value || this.age.value === AgeTypes.NewestToOldest) {
      filteredEmails = _.sortBy(emails, (note: EmailData) =>
        note.auditInfo ? note.auditInfo.createdTimestamp : ''
      ).reverse();
    } else {
      filteredEmails = _.sortBy(emails, (note: EmailData) => (note.auditInfo ? note.auditInfo.createdTimestamp : ''));
    }
    return filteredEmails;
  }

  private buildAttachments(): Array<Attachment> {
    const attachments = new Array<Attachment>();

    this.fileList.forEach((readFile) => {
      const attachment = new Attachment();
      attachment.contentType = readFile.underlyingFile.type;
      attachment.fileName = readFile.underlyingFile.name;
      attachment.base64Data = btoa(readFile.content);
      attachments.push(attachment);
    });

    if (this.dms && this.dms.value) {
      this.dms.value.forEach((dmsDoc: DocumentSearch) => {
        const attachment = new Attachment();
        attachment.contentType = [dmsDoc.cdt.docClass, dmsDoc.cdt.corpCode].join(',');
        attachment.fileName = dmsDoc.cdt.timestamp;
        attachments.push(attachment);
      });
    }
    return attachments;
  }

  handleClearAttachments(): void {
    this.fileList = [];
    this.dms.value = [];
    if (this.config.email && this.config.email.emailAttach) {
      this.config.email.emailAttach = [];
    }
    this.updateBadge();
    this.updateAttachments();
    this.filePicker.reset();
  }
  downloadAttachments(): void {
    _.each(this.fileList, (attachment) => {
      const data = attachment.content;
      const fileName = attachment.name;
      const contentType = attachment.type;
      this.windowService.generateDownloadFile(contentType, data, fileName);
    });
  }
  private updateBadge(): void {
    const emailConfigCount = this.getActiveEmailConfigAttachments().length;
    const fileCount = _.get(this.fileList, 'length', 0);
    const dmsCount = _.get(this.dms, 'value.length', 0);
    const totalCount = emailConfigCount + fileCount + dmsCount;
    this.uploadBadgeHiddenSubject.next(totalCount === 0);
    this.uploadBadgeSubject.next(`${totalCount}`);
  }
  private getActiveEmailConfigAttachments(): any[] {
    return _.get(this.config, 'email.emailAttachment', []);
  }
  private clearFormFields(): void {
    this.emailEntryComponent.clearFormFields();
  }

  private updateAttachments(): void {
    let fileNames: any[] = [];
    if (this.config.email && this.config.email.emailAttach) {
      fileNames = this.config.email.emailAttach.map((emailAttach) => emailAttach.attachName);
    }
    if (this.dms && this.dms.value) {
      fileNames.push(this.dms.value.map((doc) => this.documentSearchPipe.transform(doc)));
    }
    fileNames.push(this.fileList.map((file) => file.name));
    this.attachmentsSubject.next(_.flatten(fileNames).join(', '));
  }

  getReplyHeader(subject, createdTimestamp, senderEmailAddress, recipientEmailAddress, ccEmailAddress) {
    const result = `<br>
      <hr>
      <div class="emails-detail__subject">
          <h3 class="emails-detail__subject-text"> ${subject}</h3>
      </div>
      <div class="emails-detail__sender">
          <b>Sender:</b> ${senderEmailAddress}
      </div>
      <div class="emails-detail__recipients">
          <b>Recipients:</b> ${recipientEmailAddress}
      </div>`;
    return !!ccEmailAddress
      ? result.concat(
          `<div class="emails-detail__cc">
          <b>CC: </b>${ccEmailAddress}
      </div>`
        )
      : result;
  }

  ngAfterViewChecked(): void {
    this.emailsForm.emit(this.emailFormGroup);
  }
}
