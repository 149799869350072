import { NoteEmailEventTypesEnum } from '../enums';
import { NoteData } from './notes-data.class';

export class NoteEvent {
  note: NoteData;
  event: NoteEmailEventTypesEnum;

  /**
   *
   */
  constructor(data?: NoteEvent) {
    if (data) {
      this.note = data.note;
      this.event = data.event;
    }
  }
}
