import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DialogEventKeysEnum } from '../../../../enums';
import { BaseDisputeDialogButton } from '../../../../interfaces/base-dispute-dialog-button.interface';

@Component({
  selector: 'app-base-dispute-dialog',
  templateUrl: './base-dispute-dialog.component.html',
  styleUrls: ['./base-dispute-dialog.component.scss'],
})
export class BaseDisputeDialogComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  buttons: BaseDisputeDialogButton[];

  @Input()
  hideCloseDialogButton: boolean = false;

  @Output()
  closeButtonClick = new EventEmitter();

  @Output()
  buttonClick: EventEmitter<DialogEventKeysEnum> = new EventEmitter<DialogEventKeysEnum>();

  constructor() {}

  ngOnInit() {}

  handleCloseButtonClick(event) {
    this.closeButtonClick.emit(event);
  }

  onButtonClick(eventKey: DialogEventKeysEnum) {
    this.buttonClick.emit(eventKey);
  }
}
