import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';

import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FormGroup } from '@angular/forms';
import { EmailData } from './classes/email-data.class';
import { NoteData } from './classes/notes-data.class';
import { EmailsComponent } from './emails/emails.component';
import { NotesEmailsFormLabelsEnum } from './enums';
import { EmailsConfig } from './interfaces/email-config.interface';
import { NotesComponent } from './notes/notes.component';
import { NotesEmailsService } from './services/notes-emails.service';

@Component({
  selector: 'xpo-notes-emails',
  templateUrl: './notes-emails.component.html',
  styleUrls: ['./notes-emails.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesEmailsComponent implements OnInit, OnDestroy {
  readonly NotesEmailsFormLabelsEnum = NotesEmailsFormLabelsEnum;

  @Input()
  addNotesEnabled = true;
  @Input()
  sendEmailEnabled = true;

  @Output()
  closeClickedHandler = new EventEmitter<void>();

  @ViewChild('tabGroup', { static: true })
  tabGroup: MatTabGroup;

  @Output()
  notesFormGroup = new EventEmitter<FormGroup>();

  @Output()
  emailsFormGroup = new EventEmitter<FormGroup>();

  private isInternalNotesActiveSubject = new BehaviorSubject<boolean>(true);
  isInternalNotesActive$ = this.isInternalNotesActiveSubject.asObservable();

  private isEmailActiveSubject = new BehaviorSubject<boolean>(false);
  isEmailActive$ = this.isEmailActiveSubject.asObservable();

  get notes$(): Observable<NoteData[]> {
    return this.notesEmailsService.notes$;
  }

  get notesTotalCount$(): Observable<number> {
    return this.notesEmailsService.notes$.pipe(map((notes) => notes.length));
  }

  get emails$(): Observable<EmailData[]> {
    return this.notesEmailsService.emails$;
  }

  get emailsTotalCount$(): Observable<number> {
    return this.notesEmailsService.emails$.pipe(map((emails) => emails.length));
  }
  @Input()
  emailConfig: EmailsConfig = {
    subject: '',
    recipient: '',
  };

  private emailConfigSubject = new BehaviorSubject<EmailsConfig>(undefined);
  emailConfig$ = this.emailConfigSubject.asObservable();

  private unsubscriber = new Unsubscriber();

  constructor(private notesEmailsService: NotesEmailsService) {}

  ngOnInit() {
    this.tabGroup.selectedIndexChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedIndex) => {
      this.isInternalNotesActiveSubject.next(selectedIndex === 0);
      this.isEmailActiveSubject.next(selectedIndex === 1);
    });
    this.emailConfigSubject.next(this.emailConfig);
  }

  ngOnDestroy() {
    this.notesEmailsService.cleanNotesAndEmails();
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  closeClicked() {
    this.closeClickedHandler.emit();
  }

  updateNotesFormGroup(formGroup) {
    this.notesFormGroup.emit(formGroup);
  }

  updateEmailsForm(formGroup) {
    this.emailsFormGroup.emit(formGroup);
  }
}
