<h1 mat-dialog-title>
  {{ title }}
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>

<div mat-dialog-content>
  <div *ngIf="loading && currentPage === 0" class="party-search-spinner-container">
    <div class="party-search__loading">
      <mat-progress-spinner
        class="party-search__loading-spinner"
        data-test="match-parties-spinner"
        mode="indeterminate"
        color="primary"
        [diameter]="50"
      >
      </mat-progress-spinner>
      <div>Loading ...</div>
    </div>
  </div>

  <div *ngIf="!loading && !dataSource?.data?.length">
    {{ MatchDialogFormLabelsEnum.NOT_FOUND }}
  </div>

  <div [hidden]="(loading && currentPage === 0) || !dataSource?.data?.length">
    <div class="legend-box">
      <p>{{ explanation }}</p>
    </div>
    <div class="party-search__table_container" (scroll)="onTableScroll($event)">
      <table mat-table matSort [dataSource]="dataSource" class="row party-search__table">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-radio-button value="row.id" (change)="selectParty(row)" data-test="selectParty"></mat-radio-button>
          </td>
        </ng-container>
        <ng-container matColumnDef="name1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ MatchDialogFormLabelsEnum.NAME }}</th>
          <td mat-cell *matCellDef="let row">{{ row.name1 }}</td>
        </ng-container>
        <ng-container matColumnDef="addr1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ MatchDialogFormLabelsEnum.ADDRESS }}</th>
          <td mat-cell *matCellDef="let row">{{ row.addr1 }}</td>
        </ng-container>
        <ng-container matColumnDef="cityName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ MatchDialogFormLabelsEnum.CITY }}</th>
          <td mat-cell *matCellDef="let row">{{ row.cityName }}</td>
        </ng-container>
        <ng-container matColumnDef="stateCd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ MatchDialogFormLabelsEnum.PROVINCE_STATE }}</th>
          <td mat-cell *matCellDef="let row">{{ row.stateCd }}</td>
        </ng-container>
        <ng-container matColumnDef="postalCd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ MatchDialogFormLabelsEnum.ZIP_CODE }}</th>
          <td mat-cell *matCellDef="let row">{{ row.postalCd }}</td>
        </ng-container>
        <ng-container matColumnDef="countryCd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ MatchDialogFormLabelsEnum.COUNTRY }}</th>
          <td mat-cell *matCellDef="let row">{{ row.countryCd }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="legend-box" data-test="resultCount">
      <p>Showing {{ dataSource?.data?.length }} out of {{ totalCountRow }} results</p>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="onNoMatch()" #btnNoMatch data-test="noMatch">No Match</button>
  <button mat-flat-button (click)="onMatch()" [disabled]="!selectedParty" data-test="match">Match</button>
</div>
