import { ActionCd, AuditInfo } from '@xpo-ltl/sdk-common';

export class NoteData {
  /**
   * System generated unique ID for each note
   */
  noteId: number;
  /**
   * Describes the type of note for the dispute.
   * Valid List of values: TBD
   * Required: false
   * Array: false
   */
  typeCd: any;
  /**
   * The text of the note
   */
  note: string;
  /**
   * Name of the author who entered the note.  This can be a non-employee representing a customer.
   */
  authorName: string;
  /**
   * Same value for event id is assigned to various rows from different tables that form a single database transactions.
   * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
   * In other words, this groups multipple rows across various tables for a single database transaction.
   * Typically this is used to publish a message to a queue for consumption or a workflow.
   * Required: false
   * Array: false
   */
  dspEventId: number;
  /**
   * Indicates whether to add, update or delete this entry. This will be used for merging lists.
   * A - Add entry to list
   * D - Delete entry from list
   * U - Update entry
   */
  listActionCd: ActionCd;
  /**
   * Audit information such as create and/or update transaction details - who, when and which program/transaction
   */
  auditInfo: AuditInfo;
  /**
   * An update operation uses this to version check the record to ensure another transaction has not updated
   * the row after this transaction had read the row and is now about to update it.
   */
  recordVersionNbr: number;
  /**
   * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.
   * In other words, this groups multipple rows across various tables for a single business transaction.
   */
  correlationId: string;
  /**
   * The subject line for the note.
   */
  subject: string;

  constructor() {}
}

export class InterfaceEmployee {
  /**
   * Employee Id of the Claims employee.
   */
  employeeId: string;
  /**
   * First Name of the Claims employee.
   */
  firstName: string;
  /**
   * Middle Name of the Supervisor of the Claims employee.
   */
  middleName: string;
  /**
   * Last Name of the Supervisor of the Claims employee.
   */
  lastName: string;
  /**
   * Job Description of the Supervisor of the Claims employee.
   */
  jobDescription: string;
  /**
   * The domicile SIC location for the employee.
   */
  domicileSicCd: string;
  /**
   * The employee id of this employee's supervisor.
   */
  supervisorEmployeeId: string;
  /**
   * Desc: The business phone number of the employee. It is sourced from HRS_EMPL_PHONE_VW in DB2 for typ_cd = 'B'. 01/10/2019
   */
  businessPhoneTxt: string;
  /**
   * Audit information such as create and/or update transaction details - who, when and which program/transaction
   */
  auditInfo: AuditInfo;
}
