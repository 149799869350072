export enum SelectProDialogFormLabelsEnum {
  TITLE = 'Select Correct PRO',
  SELECT = 'Select',

  // Column names

  PICKUP_DATE = 'Pickup Date',
  SHIPPER = 'Shipper',
  BILL_TO = 'Bill To',
  CONSIGNEE = 'Consignee',
}
