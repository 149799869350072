import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { DisputeDebtorHelper } from '../classes/disputes/dispute-debtor-helper';
import { DisputesDataService } from '../services/disputes/disputes-data.service';

export class EntryAddressValidators {
  static isMadCodeRequired(disputesDataService: DisputesDataService): boolean {
    if (!disputesDataService) {
      return false;
    }
    return DisputeDebtorHelper.isMadCodeRequired(disputesDataService.debtorMethod);
  }

  static madCodeRequired(disputesDataService: DisputesDataService): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (this.isMadCodeRequired(disputesDataService) && !control.value) {
        return { required: true };
      }
      return null;
    };
  }

  static customerNumberRequired(disputesDataService: DisputesDataService): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (this.isMadCodeRequired(disputesDataService) && !control.value) {
        return { required: true };
      }
      return null;
    };
  }

  static zipCodeRequired(disputesDataService: DisputesDataService): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (this.isMadCodeRequired(disputesDataService) && !control.value) {
        return { required: true };
      }
      return null;
    };
  }

  static addressRequired(disputesDataService: DisputesDataService): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (disputesDataService.addressRequired && !control.value) {
        return { required: true };
      }
      return null;
    };
  }
}
