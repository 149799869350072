import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { takeUntil } from 'rxjs/operators';
import { FormUtils } from '../../classes/form-utils.class';
import { NotesEntryFormNamesEnum, NotesEntryLabelsEnum, NotesEntryMaxLengthsEnum } from '../../enums';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'xpo-notes-entry',
  templateUrl: './notes-entry.component.html',
  styleUrls: ['./notes-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesEntryComponent implements OnInit, OnDestroy {
  readonly NotesEntryMaxLengthsEnum = NotesEntryMaxLengthsEnum;
  readonly NotesEntryFormNamesEnum = NotesEntryFormNamesEnum;
  readonly NotesEntryLabelsEnum = NotesEntryLabelsEnum;
  unsubscriber = new Unsubscriber();

  @Input()
  formGroup: FormGroup;
  @Input()
  noteTypes: string[] = [];
  @Input()
  defaultNoteType: string;
  @Input('editorConfig')
  editorConfigInput;

  editorConfig = {};
  defaultEditorConfig: AngularEditorConfig = {
    editable: true,
    height: '250px',
    minHeight: '250px',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyCenter',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  }
  noteType;
  notesEntryLength: number = 0;

  get contentField(): AbstractControl {
    return this.formGroup.get(NotesEntryFormNamesEnum.CONTENT);
  }

  get showRequiredError(): boolean {
    return this.contentField.touched && this.notesEntryLength === 0;
  }

  get showContentExceededError(): boolean {
    return this.contentField.hasError('maxLength');
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.editorConfigInit();
    this.formGroup
      .get(NotesEntryFormNamesEnum.NOTE_TYPE)
      .setValue(this.defaultNoteType !== null ? this.defaultNoteType : '');

    this.contentField.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((value) => {
      this.notesEntryLength = FormUtils.getDecodedCharacterCount(value);
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  editorConfigInit() {
    this.editorConfig = {
      ...this.defaultEditorConfig,
      ...this.editorConfigInput,
    };
  }
}
