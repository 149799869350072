import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatButton } from '@angular/material/button';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { DisputesApiService, MatchDisputePartyRqst } from '@xpo-ltl/sdk-disputes';
import { Party } from '@xpo-ltl/sdk-disputes';
import { ElasticSearchApiService } from '@xpo-ltl/sdk-elasticsearch';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

import { Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { DisputePartySearchHelper } from '../../../../classes/dispute-party-search.helper';
import { MatchDialogFormLabelsEnum } from '../../../../enums';

@Component({
  selector: 'app-match-dialog',
  templateUrl: './match-dialog.component.html',
  styleUrls: ['./match-dialog.component.scss'],
})
export class MatchDialogComponent implements OnInit {
  readonly MatchDialogFormLabelsEnum = MatchDialogFormLabelsEnum;
  private unsubscriber = new Unsubscriber();
  currentPage = 0;
  totalCountRow: number;

  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  @ViewChild('btnNoMatch', { static: false })
  btnNoMatch: MatButton;

  loading: boolean;
  selectedParty: Party = undefined;
  dataSource = new MatTableDataSource<Party>([]);
  title: string;
  explanation: string;
  displayedColumns = ['select', 'name1', 'addr1', 'cityName', 'stateCd', 'postalCd', 'countryCd'];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<MatchDialogComponent>,
    private disputesApiService: DisputesApiService,
    private elasticSearchApiService: ElasticSearchApiService,
    private snackbar: XpoSnackBar
  ) {
    this.title = this.data.title;
    this.explanation = this.data.explanation;
  }

  onMatch() {
    this.dialogRef.close({ action: 'MATCH', party: this.selectedParty });
  }

  onNoMatch() {
    this.data.onNoMatchCallback();
    this.selectedParty = undefined;
    this.dialogRef.close({ action: 'NO_MATCH', party: this.selectedParty });
  }

  ngOnInit() {
    this.getParties();
  }

  getParties() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.currentPage++;
    this.getPartiesFromXrtDisputesPartyApi(this.data.party, this.currentPage)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(
        (parties: Array<Party>) => {
          let data = [...this.dataSource.data];
          if (this.currentPage > 1) {
            data = data.concat(parties);
          } else {
            data = parties;
          }
          this.dataSource = new MatTableDataSource<Party>(data);
          this.dataSource.sort = this.sort;
        },
        (error) => {
          const message = error && error.error && error.error.message ? error.error.message : '';
          this.snackbar.error(`Error: ${message}`);
        },
        () => {
          this.loading = false;
        }
      );
  }

  selectParty(party: Party) {
    this.selectedParty = party;
  }

  private getPartiesFromXrtDisputesPartyApi(partyData: Party, pageNumber: number): Observable<Array<Party>> {
    const request = DisputePartySearchHelper.getRequestForXrtDisputePartySearch(partyData, pageNumber);

    return this.elasticSearchApiService
      .xrtDisputePartySearch(request, {
        loadingOverlayEnabled: false,
      })
      .pipe(
        take(1),
        map((response) => {
          if (response && response.result && response.result.length) {
            this.totalCountRow = response.header.resultCount;
            this.currentPage = response.header.pageNumber;
            return response.result.map((matchedParty) => {
              const party = new Party();
              party.name1 = matchedParty.name1;
              party.addr1 = matchedParty.addr1;
              party.cityName = matchedParty.cityName;
              party.stateCd = matchedParty.stateCd;
              party.postalCd = matchedParty.postalCd;
              party.countryCd = matchedParty.countryCd;
              party.dspPartyId = matchedParty.disputePartyId;
              return party;
            });
          } else {
            this.btnNoMatch.focus();
            return [];
          }
        })
      );
  }

  onTableScroll(e) {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    if (tableViewHeight + scrollLocation >= tableScrollHeight) {
      this.getNextPage();
    }
  }

  getNextPage() {
    if (!this.dataSource.data || !this.dataSource.data.length) {
      return;
    }
    const startAt = this.dataSource.data.length;
    if (startAt < this.totalCountRow) {
      this.getParties();
    }
  }
}
