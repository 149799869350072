<div class="select-pro-dialog">
    <h1 mat-dialog-title class="select-pro-dialog__title">
        {{ SelectProDialogFormLabelsEnum.TITLE }}
        <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
    <div select-pro-dialog__content mat-dialog-content>

        <div class="description">
            <p>PRO# <span>{{ proNumber | proDash}}</span></p>
        </div>
    </div>
    <div>
        <div class="table-box" [hidden]="!(dataSource?.data.length > 0)">
            <div class="select-pro-dialog__table_container">
                <table mat-table matSort [dataSource]="dataSource" class="select-pro-dialog__table">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <mat-radio-button (change)="selectPro(row)" data-test="selectPro"></mat-radio-button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pkupDt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ SelectProDialogFormLabelsEnum.PICKUP_DATE }}</th>
                        <td mat-cell *matCellDef="let row">{{ row.pkupDt | date: 'MM/dd/yyyy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="shipperName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ SelectProDialogFormLabelsEnum.SHIPPER }}</th>
                        <td mat-cell *matCellDef="let row">{{ row.shipperName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="billToName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ SelectProDialogFormLabelsEnum.BILL_TO }}</th>
                        <td mat-cell *matCellDef="let row">{{ row.billToName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="consigneeName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ SelectProDialogFormLabelsEnum.CONSIGNEE }}</th>
                        <td mat-cell *matCellDef="let row">{{ row.consigneeName }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
            <p class="total-result" data-test="resultCount">
                Showing {{dataSource?.data.length}} out of {{dataSource?.data.length}} Results
            </p>
        </div>
    </div>
    <div mat-dialog-actions class="select-pro-dialog__footer">
        <button mat-flat-button class="button-select" (click)="onSelectClick()" [disabled]="!selectedPro" data-test="select">
            {{ SelectProDialogFormLabelsEnum.SELECT }}
        </button>
    </div>
</div>