import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import * as _ from 'lodash';

import { DisputeDecisionReasonTypeCd, DisputeStatusCd, DisputeTypeCd } from '@xpo-ltl/sdk-common';

import { FormUtils } from '../../../../classes/form-utils.class';
import { DisputeDecisionReasonApprovalHelper } from '../../../../classes/reason-codes/dispute-decision-reason-approval-helper.class';
import { DisputeDecisionReasonCancelHelper } from '../../../../classes/reason-codes/dispute-decision-reason-cancel-helper.class';
import { DisputeDecisionReasonDeclineHelper } from '../../../../classes/reason-codes/dispute-decision-reason-decline-helper.class';
import { ActionBarButtonTypesCd, DialogResponse } from '../../../../enums';
import { DisputesDataService } from '../../../../services/disputes/disputes-data.service';

@Component({
  selector: 'app-explanation-dialog',
  templateUrl: './explanation-dialog.component.html',
  styleUrls: ['./explanation-dialog.component.scss'],
})
export class DisputeExplanationDialogComponent implements OnInit {
  loading: boolean;
  private _actionType: ActionBarButtonTypesCd;
  private _buttonColor: string;
  private _showCCSection: boolean;

  private _explanationDialogFormLabelsEnum;
  private _explanationDialogMaxLenghtsEnum;
  private _explanationDialogFormNamesEnum;
  private _explanationDialogFormBuilder;

  formGroup: FormGroup;

  get title() {
    return this._explanationDialogFormLabelsEnum.TITLE;
  }

  get description() {
    return this._explanationDialogFormLabelsEnum.DESCRIPTION;
  }

  get buttonColor() {
    return this._buttonColor;
  }

  get reasons() {
    switch (this._actionType) {
      case ActionBarButtonTypesCd.APPROVE:
        switch (this.disputesDataService.dispute.dispute.typeCd) {
          case DisputeTypeCd.OVERCHARGE_CLAIMS:
            return DisputeDecisionReasonApprovalHelper.getOCCApprovalReasons();
          case DisputeTypeCd.INSPECTION_APPEALS:
            return DisputeDecisionReasonApprovalHelper.getWNIApprovalReasons();
          case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
            return DisputeDecisionReasonApprovalHelper.getDRPApprovalReasons();
        }
        break;
      case ActionBarButtonTypesCd.APPROVE_AND_CLOSE:
        switch (this.disputesDataService.dispute.dispute.typeCd) {
          // Only OCC can be Approve and Close
          case DisputeTypeCd.OVERCHARGE_CLAIMS:
            return DisputeDecisionReasonApprovalHelper.getOCCApprovalReasons();
          default:
            return [];
        }
        break;
      case ActionBarButtonTypesCd.CANCEL:
        switch (this.disputesDataService.dispute.dispute.typeCd) {
          case DisputeTypeCd.OVERCHARGE_CLAIMS:
            return DisputeDecisionReasonCancelHelper.getOCCCancelReasons();
          case DisputeTypeCd.INSPECTION_APPEALS:
            return DisputeDecisionReasonCancelHelper.getWNICancelReasons();
          case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
            return DisputeDecisionReasonCancelHelper.getDRPCancelReasons();
        }
        break;
      case ActionBarButtonTypesCd.DECLINE:
        switch (this.disputesDataService.dispute.dispute.typeCd) {
          case DisputeTypeCd.OVERCHARGE_CLAIMS:
            return DisputeDecisionReasonDeclineHelper.getOCCDeclineReasons();
          case DisputeTypeCd.INSPECTION_APPEALS:
            return DisputeDecisionReasonDeclineHelper.getWNIDeclineReasons();
          case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
            return DisputeDecisionReasonDeclineHelper.getDRPDeclineReasons();
        }
        break;
      case ActionBarButtonTypesCd.AMEND:
        // Same as Approve
        switch (this.disputesDataService.dispute.dispute.typeCd) {
          case DisputeTypeCd.OVERCHARGE_CLAIMS:
            return DisputeDecisionReasonApprovalHelper.getOCCApprovalReasons();
          case DisputeTypeCd.INSPECTION_APPEALS:
            return DisputeDecisionReasonApprovalHelper.getWNIApprovalReasons();
          case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
            return DisputeDecisionReasonApprovalHelper.getDRPApprovalReasons();
        }
        break;
    }
  }

  get explanationDialogFormLabelsEnum() {
    return this._explanationDialogFormLabelsEnum;
  }
  get explanationDialogMaxLenghtsEnum() {
    return this._explanationDialogMaxLenghtsEnum;
  }
  get explanationDialogFormNamesEnum() {
    return this._explanationDialogFormNamesEnum;
  }

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogRef: MatDialogRef<DisputeExplanationDialogComponent>,
    private disputesDataService: DisputesDataService
  ) {
    this._actionType = dialogData.action;
    this._buttonColor = dialogData.buttonColor;
    this._explanationDialogFormLabelsEnum = dialogData.enums.explanationDialogFormLabelsEnum;
    this._explanationDialogMaxLenghtsEnum = dialogData.enums.explanationDialogMaxLenghtsEnum;
    this._explanationDialogFormNamesEnum = dialogData.enums.explanationDialogFormNamesEnum;
    this._explanationDialogFormBuilder = dialogData.form.formBuilder;
  }

  ngOnInit() {
    this.formInit();
  }

  /**
   */
  formInit() {
    this.formGroup = this._explanationDialogFormBuilder.create(this.fb);
  }

  onSubmit() {
    switch (this._actionType) {
      case ActionBarButtonTypesCd.APPROVE:
        this.execute(DialogResponse.APPROVE);
        break;
      case ActionBarButtonTypesCd.APPROVE_AND_CLOSE:
        this.execute(DialogResponse.APPROVE_AND_CLOSE);
        break;
      case ActionBarButtonTypesCd.DECLINE:
        this.execute(DialogResponse.DECLINE);
        break;
      case ActionBarButtonTypesCd.CANCEL:
        this.execute(DialogResponse.CANCEL);
        break;
      case ActionBarButtonTypesCd.AMEND:
        this.execute(DialogResponse.AMEND);
        break;
    }
  }

  getDisputeStatus(): DisputeStatusCd {
    switch (this._actionType) {
      case ActionBarButtonTypesCd.APPROVE:
        return DisputeStatusCd.APPROVED;
      case ActionBarButtonTypesCd.APPROVE_AND_CLOSE:
        return DisputeStatusCd.CLOSED;
      case ActionBarButtonTypesCd.DECLINE:
        return DisputeStatusCd.DECLINED;
      case ActionBarButtonTypesCd.CANCEL:
        return DisputeStatusCd.CANCELLED;
      case ActionBarButtonTypesCd.AMEND:
        return DisputeStatusCd.AMENDED;
    }
  }

  getDisputeDecisionReasonType(): DisputeDecisionReasonTypeCd {
    switch (this._actionType) {
      case ActionBarButtonTypesCd.APPROVE:
        return DisputeDecisionReasonTypeCd.APPROVE;
      case ActionBarButtonTypesCd.APPROVE_AND_CLOSE:
        return DisputeDecisionReasonTypeCd.APPROVE;
      case ActionBarButtonTypesCd.DECLINE:
        return DisputeDecisionReasonTypeCd.DECLINE;
      case ActionBarButtonTypesCd.CANCEL:
        return DisputeDecisionReasonTypeCd.CANCEL;
      case ActionBarButtonTypesCd.AMEND:
        return DisputeDecisionReasonTypeCd.AMEND;
    }
  }

  execute(response: DialogResponse) {
    if (this.formGroup.valid) {
      this.disputesDataService.approvalDecision = {
        decision: this.getDisputeStatus(),
        decisionReasonCd: this.getDisputeDecisionReasonType(),
        decisionReasonSubtypeCd: this.formGroup.get(this.explanationDialogFormNamesEnum.REASON_CODE).value,
        decisionReason: this.formGroup.get(this.explanationDialogFormNamesEnum.REASON_EXPLANATION).value,
      };

      if (this._actionType === ActionBarButtonTypesCd.AMEND) {
        this.disputesDataService.approvalDecision.ccMailReason = this.formGroup.get(
          this.explanationDialogFormNamesEnum.CC_EMAILS
        ).value;
      }

      this.dialogRef.close(response);
    } else {
      FormUtils.markAsTouched(this.formGroup);
    }
  }

  cancel() {
    this.dialogRef.close(DialogResponse.CLOSE);
  }
}
