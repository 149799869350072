<div class="relatedDisputes">
    <h3 mat-dialog-title>
        Related Issues
        <xpo-dialog-close-button></xpo-dialog-close-button>
    </h3>
    <div mat-dialog-content>
        <h2>Related Disputes</h2>
        <ng-container class="mat-elevation-z8" *ngIf="(relatedDisputesCount$ | async) > 0; else noRelatedDisputes">
            <mat-table [dataSource]="relatedDisputes$ | async">

                <ng-container matColumnDef="disputeNum">
                    <mat-header-cell *matHeaderCellDef> Dispute # </mat-header-cell>
                    <mat-cell *matCellDef="let element"><a xpo-regular-link (click)="onRelatedDisputeClick(element)">{{ element.disputeNum }}</a></mat-cell>
                </ng-container>

                <ng-container matColumnDef="disputeType">
                    <mat-header-cell *matHeaderCellDef> Dispute Type </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.disputeType }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="disputeStatus">
                    <mat-header-cell *matHeaderCellDef> Dispute Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.disputeStatus | humanize }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="assignedTo">
                    <mat-header-cell *matHeaderCellDef> Assigned To </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.assignedTo | employee }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoiceAmount">
                    <mat-header-cell *matHeaderCellDef> Invoiced Amount (at dispute creation) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.invoiceAmount }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reqAdjAmt">
                    <mat-header-cell *matHeaderCellDef> PRO Requested Adjustment Amount </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.reqAdjAmt }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastUpdate">
                    <mat-header-cell *matHeaderCellDef> Last Updated Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.lastUpdate }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                    <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.createdAt }} </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

            </mat-table>
            <span class="relatedDisputes__showing-result" data-test="disputeResultCount">Showing {{ relatedDisputesCount$ | async }} Results</span>
        </ng-container>
        <ng-template #noRelatedDisputes>
            <div class="relatedDisputes__empty">
                <h4 class="relatedDisputes__empty__description">
                    There are no related disputes to show.
                </h4>
            </div>
        </ng-template>
        <hr>
        <h2>Related Claims</h2>
        <ng-container class="mat-elevation-z8">
            <mat-table [dataSource]="relatedClaims$ | async" *ngIf="(relatedClaimsCount$ | async) > 0; else noRelatedClaims">

                <ng-container matColumnDef="claimId">
                    <mat-header-cell *matHeaderCellDef> Claim # </mat-header-cell>
                    <mat-cell *matCellDef="let element"><a xpo-regular-link (click)="onRelatedClaimClick(element)">{{ element.claimId }}</a></mat-cell>
                </ng-container>

                <ng-container matColumnDef="claimType">
                    <mat-header-cell *matHeaderCellDef> Claim Type </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.claimType }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="internalStatus">
                    <mat-header-cell *matHeaderCellDef> Internal Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.internalStatus }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="filingDate">
                    <mat-header-cell *matHeaderCellDef> Filing Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.filingDate }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="examinerName">
                    <mat-header-cell *matHeaderCellDef> Examiner Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.examinerName }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="claimedAmount">
                    <mat-header-cell *matHeaderCellDef> Claimed Amount </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.claimedAmount }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="approvedAmount">
                    <mat-header-cell *matHeaderCellDef> Approved Amount </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.approvedAmount }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="totalPaymentAmount">
                    <mat-header-cell *matHeaderCellDef> Total Payment Amount </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.totalPaymentAmount }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsClaims;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsClaims"></mat-row>

            </mat-table>
            <span class="relatedDisputes__showing-result" data-test="claimResultCount">Showing {{ relatedClaimsCount$ | async }} Results</span>
        </ng-container>

        <ng-template #noRelatedClaims>
            <div class="relatedDisputes__empty">
                <h4 class="relatedDisputes__empty__description">
                    There are no related claims to show.
                </h4>
            </div>
        </ng-template>
    </div>
</div>