import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  Contact,
  DisputesApiService,
  ListContactsForPartyQuery,
  ListContactsForPartyResp,
} from '@xpo-ltl/sdk-disputes';
import { ContactsListDialogFormLabelsEnum, DialogResponse } from '../../../../enums';

@Component({
  selector: 'app-contacts-list-dialog',
  templateUrl: './contacts-list-dialog.component.html',
  styleUrls: ['./contacts-list-dialog.component.scss'],
})
export class ContactsListDialogComponent implements OnInit, AfterViewInit {
  readonly ContactsListDialogFormLabelsEnum = ContactsListDialogFormLabelsEnum;
  readonly title = ContactsListDialogFormLabelsEnum.TITLE;

  @ViewChild(MatSort, { static: false })
  sort: MatSort;
  @ViewChild('filterInput', { static: false })
  filterInput: ElementRef;

  loading = false;
  contactsNotFound = false;
  disableSelectContactButton = true;
  displayedColumns = ['select', 'firstName', 'lastName', 'emailAddress', 'phoneNbr', 'phoneExt', 'faxNbr'];
  contacts: MatTableDataSource<Contact>;
  selectedContact: Contact;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ContactsListDialogComponent, Contact | DialogResponse>,
    private disputesApiService: DisputesApiService
  ) {}

  ngOnInit() {
    this.contactsInit();
  }

  ngAfterViewInit() {
    setTimeout(() => this.filterInput.nativeElement.focus());
  }

  /**
   * Get contacts by party
   */
  contactsInit() {
    this.loading = true;
    this.contacts = new MatTableDataSource<Contact>([]);
    const request = new ListContactsForPartyQuery();
    request.partyId = this.data.partyId;
    request.cisCustNbr = this.data.cisCustNbr;

    this.disputesApiService.listContactsForParty(request).subscribe(
      (data: ListContactsForPartyResp) => {
        this.loading = false;
        if (!data.contacts || !data.contacts.length) {
          this.contactsNotFound = true;
          data.contacts = [];
        }
        this.disableSelectContactButton = this.contactsNotFound;
        this.contacts = new MatTableDataSource<Contact>(
          data.contacts.map((value) => {
            const disputeContact = new Contact();
            disputeContact.cntctId = value.cntctId;
            disputeContact.firstName = value.firstName;
            disputeContact.lastName = value.lastName;
            disputeContact.phoneNbr = value.phoneNbr;
            disputeContact.phoneExt = value.phoneExt;
            disputeContact.emailAddress = value.emailAddress;
            disputeContact.faxNbr = value.faxNbr;
            return disputeContact;
          })
        );
        this.contacts.sort = this.sort;
      },
      (error) => {
        this.contactsNotFound = true;
        this.loading = false;
      }
    );
  }

  applyFilter(filterValue: string) {
    this.contacts.filter = filterValue.trim().toLowerCase();
  }

  contactSelected(contact: Contact) {
    this.selectedContact = contact;
  }

  cancel() {
    this.dialogRef.close(DialogResponse.CLOSE);
  }

  returnContactSelected() {
    if (this.selectedContact) {
      this.dialogRef.close(this.selectedContact);
    }
  }
}
