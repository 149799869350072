export enum ApprovalProcessFormLabels {
  APPROVE_TITLE = 'Approval',
  CONFIRM_APPROVE_TITLE = 'Approve Dispute?',
  CONFIRM_APPROVE_DESCRIPTION = 'Once approved adjustment will be processed.',
  CONFIRM_APPROVE_OK_BUTTON = 'Approve',
  CONFIRM_APPROVE_GO_BACK_BUTTON = 'Go Back',

  APPROVE_AND_CLOSE_TITLE = 'Close',
  CONFIRM_APPROVE_AND_CLOSE_TITLE = 'Close Dispute',
  // tslint:disable-next-line:max-line-length
  CONFIRM_APPROVE_AND_CLOSE_DESCRIPTION = 'Once closed, the claimant will be notified of this decision and the refund process will initiate.',
  CONFIRM_APPROVE_AND_CLOSE_OK_BUTTON = 'Close Dispute',
  CONFIRM_APPROVE_AND_CLOSE_GO_BACK_BUTTON = 'Go Back',

  AMEND_TITLE = 'Amend',
  CONFIRM_AMEND_TITLE = 'Amend Dispute?',
  CONFIRM_AMEND_DESCRIPTION = 'Are you sure you want to amend this dispute?',
  CONFIRM_AMEND_OK_BUTTON = 'Amend',
  CONFIRM_AMEND_GO_BACK_BUTTON = 'Go Back',

  DECLINATION_TITLE = 'Declination',
  CONFIRM_DECLINATION_TITLE = 'Decline Dispute?',
  CONFIRM_DECLINATION_DESCRIPTION = 'Once declined, the system will notify the requestor that the dispute has been declined.',
  CONFIRM_DECLINATION_OK_BUTTON = 'Decline',
  CONFIRM_DECLINATION_GO_BACK_BUTTON = 'Go Back',

  CANCELATION_TITLE = 'Cancellation',
  CONFIRM_CANCELATION_TITLE = 'Cancel Dispute?',
  // tslint:disable-next-line:max-line-length
  CONFIRM_CANCELATION_DESCRIPTION = 'If you continue, the dispute will be archived as cancelled, and you will have to address a reason for cancellation.',
  CONFIRM_CANCELATION_OK_BUTTON = 'Cancel',
  CONFIRM_CANCELATION_GO_BACK_BUTTON = 'Go Back',
}
