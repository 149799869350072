import { Injectable, OnDestroy } from '@angular/core';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
// import { LoginService } from '@xpo-ltl/login';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';

import { BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ConfigManagerProperties } from '../../../../../src/app/enums';

@Injectable()
export class UserNotesEmailsService implements OnDestroy {
  unsubscriber = new Unsubscriber();
  currentUserSubject = new BehaviorSubject<User>(undefined);
  currentUser$ = this.currentUserSubject.asObservable();

  get currentUser(): User {
    return this.currentUserSubject.value;
  }
  set currentUser(types: User) {
    this.currentUserSubject.next(types);
  }

  constructor(configManager: ConfigManagerService,
    loginService: XpoLtlLoggedInUserService
    ) {
    loginService
      .getLoggedInUser(configManager.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        takeUntil(this.unsubscriber.done$),
        map((user: User) => user as User)
      )
      .subscribe((user) => (this.currentUser = user));
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }
}
