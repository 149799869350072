export enum DisputePermissionsEnum {
  // #region General Permissions
  DASHBOARD = 'Dashboard',
  CREATE_DISPUTE = 'CreateDispute',
  DUPLICATE_DISPUTE = 'DuplicateDispute',
  SAVE_AS_DRAFT = 'SaveAsDraft',
  SUBMIT_DISPUTE = 'SubmitDispute',
  SUBMIT_UNDER_REVIEW_DISPUTE = 'SubmitUnderReviewDispute',
  SUBMIT_DISPUTE_FOR_REVIEW = 'SubmitDisputeForReview',
  SUBMIT_UNDER_REVIEW_DISPUTE_FOR_REVIEW = 'SubmitUnderReviewDisputeForReview',
  SUBMIT_DISPUTE_FOR_APPROVE = 'SubmitDisputeForApprove',
  DELETE_DRAFT_DISPUTE = 'DeleteDraftDispute',
  EDIT_DRAFT_DISPUTE = 'EditDraftDispute',
  EDIT_UNDER_REVIEW_DISPUTE = 'EditUnderReviewDispute',
  EDIT_SUBMITTED_DISPUTE = 'EditSubmittedDispute',
  VIEW_ALL_DISPUTE = 'ViewAllDisputes',
  CAN_START_CORRECTIONS = 'CanStartCorrections',
  CAN_CORRECTIONS_COMPLETE = 'CanCorrectionsComplete',
  // #endregion

  // #region Approval Process
  GRAB_SUBMITTED_DISPUTE_OCC = 'GrabSubmittedDisputeOCC',
  GRAB_MY_SUBMITTED_DISPUTE_OCC = 'GrabSubmittedDisputeOCCCreatedByMe',
  GRAB_SUBMITTED_DISPUTE_WNI = 'GrabSubmittedDisputeWNI',
  GRAB_MY_SUBMITTED_DISPUTE_WNI = 'GrabSubmittedDisputeWNICreatedByMe',
  GRAB_SUBMITTED_DISPUTE_MRRA = 'GrabSubmittedDisputeMRRA',
  GRAB_MY_SUBMITTED_DISPUTE_MRRA = 'GrabSubmittedDisputeMRRACreatedByMe',
  // DRP
  GRAB_SUBMITTED_DISPUTE_DRP = 'GrabSubmittedDisputeDRP',
  GRAB_SUBMITTED_DISPUTE_DRP_EQUAL_TO_ZERO = 'GrabSubmittedDisputeDRPEqualToZero',
  GRAB_SUBMITTED_DISPUTE_DRP_UP_TO_300 = 'GrabSubmittedDisputeDRPUpTo300',
  GRAB_SUBMITTED_DISPUTE_DRP_UP_TO_500 = 'GrabSubmittedDisputeDRPUpTo500',
  GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_2000 = 'GrabSubmittedDisputeDRPLessThan2000',
  GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_10000 = 'GrabSubmittedDisputeDRPLessThan10000',
  GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_25000 = 'GrabSubmittedDisputeDRPLessThan25000',
  GRAB_SUBMITTED_DISPUTE_DRP_ANY_AMT = 'GrabSubmittedDisputeDRPAnyAmt',
  GRAB_MY_SUBMITTED_DISPUTE_DRP = 'GrabSubmittedDisputeDRPCreatedByMe',
  GRAB_SUBMITTED_FOR_REVIEW_DISPUTE_DRP = 'GrabSubmittedForReviewDisputeDRP',
  GRAB_MY_SUBMITTED_FOR_REVIEW_DISPUTE_DRP = 'GrabSubmittedForReviewDisputeDRPCreatedBeMe',
  GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP = 'GrabPendingDirectorApprovalDisputeDRP',
  GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP = 'GrabVPApprovalDisputeDRP',
  GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP = 'GrabVPSApprovalDisputeDRP',
  GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP = 'GrabSVPApprovalDisputeDRP',
  GRAB_PENDING_FINANCE_APPROVAL_DISPUTE_DRP = 'GrabFinanceApprovalDisputeDRP',
  DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_DRP = 'DeclineCorrectionsUnderProgressDisputeDRP',

  AUTO_ASSIGN_DISPUTE = 'AutoAssignDispute',
  REASSIGN_DISPUTE = 'ReassignDispute',
  APPROVE_DISPUTE_OCC = 'ApproveDisputeOCC',
  DECLINE_DISPUTE_OCC = 'DeclineDisputeOCC',
  CANCEL_DISPUTE_OCC = 'CancelDisputeOCC',
  AMEND_DISPUTE_OCC = 'AmendDisputeOCC',
  APPROVE_DISPUTE_WNI = 'ApproveDisputeWNI',
  DECLINE_DISPUTE_WNI = 'DeclineDisputeWNI',
  CANCEL_DISPUTE_WNI = 'CancelDisputeWNI',
  AMEND_DISPUTE_WNI = 'AmendDisputeWNI',
  APPROVE_DISPUTE_MRRA = 'ApproveDisputeMRRA',
  DECLINE_DISPUTE_MRRA = 'DeclineDisputeMRRA',
  CANCEL_DISPUTE_MRRA = 'CancelDisputeMRRA',
  AMEND_DISPUTE_MRRA = 'AmendDisputeMRRA',
  UPDATE_OVERCHARGE_CLAIMS = 'UpdateOCC',
  UPDATE_OVERCHARGE_CLAIMS_CORRECTIONS_UNDER_PROGRESS = 'UpdateOCCCorrectionsUnderProgress',

  // DRP
  UPDATE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP = 'UpdatePendingDirectorApprovalDisputeDRP',
  UPDATE_PENDING_VP_APPROVAL_DISPUTE_DRP = 'UpdatePendingVPApprovalDisputeDRP',
  UPDATE_PENDING_VPS_APPROVAL_DISPUTE_DRP = 'UpdatePendingVPSApprovalDisputeDRP',
  UPDATE_PENDING_VPAS_APPROVAL_DISPUTE_DRP = 'UpdatePendingVPASApprovalDisputeDRP',
  UPDATE_PENDING_VPAO_APPROVAL_DISPUTE_DRP = 'UpdatePendingVPAOApprovalDisputeDRP',
  UPDATE_PENDING_DOO_APPROVAL_DISPUTE_DRP = 'UpdatePendingDOOApprovalDisputeDRP',
  UPDATE_PENDING_SVP_APPROVAL_DISPUTE_DRP = 'UpdatePendingSVPApprovalDisputeDRP',
  UPDATE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP = 'UpdatePendingFinanceApprovalDisputeSRP',

  APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000 = 'ApproveUnderReviewDisputeDRPCollectionExcalationMoreThanEqual1000',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000 = 'ApproveUnderReviewDisputeDRPCollectionExcalationLessThan1000',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO = 'ApproveUnderReviewDisputeDRPEqualToZero',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300 = 'ApproveUnderReviewDisputeDRPUpTo300',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500 = 'ApproveUnderReviewDisputeDRPUpTo500',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000 = 'ApproveUnderReviewDisputeDRPLessThan2000',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000 = 'ApproveUnderReviewDisputeDRPLessThan10000',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000 = 'ApproveUnderReviewDisputeDRPLessThan25000',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_ANY_AMT = 'ApproveUnderReviewDisputeDRPAnyAmt',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP = 'ApproveUnderReviewDisputeDRP',
  APPROVE_UNDER_REVIEW_DISPUTE_DRP_CE = 'ApproveUnderReviewDisputeDRPCE',
  APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP = 'ApprovePendingDirectorApprovalDisputeDRP',
  APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP = 'ApproveVPApprovalDisputeDRP',
  APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP = 'ApproveVPSApprovalDisputeDRP',
  APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP = 'ApproveSVPApprovalDisputeDRP',
  APPROVE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP = 'ApproveFinanceApprovalDisputeDRP',

  DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000 = 'ApproveUnderReviewDisputeDRPCollectionExcalationMoreThanEqual1000',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000 = 'ApproveUnderReviewDisputeDRPCollectionExcalationLessThan1000',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO = 'DeclineUnderReviewDisputeDRPEqualToZero',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300 = 'DeclineUnderReviewDisputeDRPUpTo300',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500 = 'DeclineUnderReviewDisputeDRPUpTo500',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000 = 'DeclineUnderReviewDisputeDRPLessThan2000',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000 = 'DeclineUnderReviewDisputeDRPLessThan10000',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000 = 'DeclineUnderReviewDisputeDRPLessThan25000',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_ANY_AMT = 'DeclineUnderReviewDisputeDRPAnyAmt',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC = 'DeclineUnderReviewDisputeDRPCollectionExcalation',
  DECLINE_UNDER_REVIEW_DISPUTE_DRP = 'DeclineUnderReviewDisputeDRP',
  DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP = 'DeclinePendingDirectorApprovalDisputeDRP',
  DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP = 'DeclineVPApprovalDisputeDRP',
  DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP = 'DeclineVPApprovalDisputeDRP',
  DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP = 'DeclineSVPApprovalDisputeDRP',
  DECLINE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP = 'DeclineFinanceApprovalDisputeDRP',

  CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000 = 'ApproveUnderReviewDisputeDRPCollectionExcalationMoreThanEqual1000',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000 = 'ApproveUnderReviewDisputeDRPCollectionExcalationLessThan1000',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO = 'CancelUnderReviewDisputeDRPEqualToZero',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300 = 'CancelUnderReviewDisputeDRPUpTo300',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500 = 'CancelUnderReviewDisputeDRPUpTo500',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000 = 'CancelUnderReviewDisputeDRPLessThan2000',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000 = 'CancelUnderReviewDisputeDRPLessThan10000',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000 = 'CancelUnderReviewDisputeDRPLessThan25000',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_ANY_AMT = 'CancelUnderReviewDisputeDRPAnyAmt',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC = 'CancelUnderReviewDisputeDRPCollectionExcalation',
  CANCEL_UNDER_REVIEW_DISPUTE_DRP = 'CancelUnderReviewDisputeDRP',
  CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP = 'CancelPendingDirectorApprovalDisputeDRP',
  CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP = 'CancelVPApprovalDisputeDRP',
  CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP = 'CancelVPSApprovalDisputeDRP',
  CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP = 'CancelSVPApprovalDisputeDRP',
  CANCEL_PENDING_FINANCE_APPROVAL_DISPUTE_DRP = 'CancelFinanceApprovalDisputeDRP',

  EDIT_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO = 'EditUnderReviewDisputeDRPEqualToZero',
  EDIT_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300 = 'EditUnderReviewDisputeDRPUpTo300',
  EDIT_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500 = 'EditUnderReviewDisputeDRPUpTo500',
  EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000 = 'EditUnderReviewDisputeDRPLessThan2000',
  EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000 = 'EditUnderReviewDisputeDRPLessThan10000',
  EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000 = 'EditUnderReviewDisputeDRPLessThan25000',
  EDIT_UNDER_REVIEW_DISPUTE_DRP_ANY_AMT = 'EditUnderReviewDisputeDRPAnyAmt',

  AMEND_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC = 'AmendUnderReviewDisputeDRPCollectionExcalation',
  AMEND_UNDER_REVIEW_DISPUTE_DRP = 'AmendUnderReviewDisputeDRP',
  AMEND_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP = 'AmendPendingDirectorApprovalDisputeDRP',
  AMEND_PENDING_VP_APPROVAL_DISPUTE_DRP = 'AmendVPApprovalDisputeDRP',
  AMEND_PENDING_VPS_APPROVAL_DISPUTE_DRP = 'AmendVPApprovalDisputeDRP',
  AMEND_PENDING_SVP_APPROVAL_DISPUTE_DRP = 'AmendSVPApprovalDisputeDRP',
  AMEND_PENDING_FINANCE_APPROVAL_DISPUTE_DRP = 'AmendFinanceApprovalDisputeDRP',

  REASSIGN_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP = 'ReAssignPendingDirectorApprovalDisputeDRP',

  UN_ASSIGN_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP = 'UnAssignPendingDirectorApprovalDisputeDRP',

  // #endregion

  // If Add, Remove and Edit are not handle as unit, they should me separated

  // #region Common Sections
  ADD_REMOVE_DOCUMENTS = 'AddRemoveDocuments',
  ADD_REMOVE_WATCHERS = 'AddRemoveWatchers',
  ADD_REMOVE_PROS = 'AddRemovePros',
  // Requested reason
  ADD_REMOVE_EDIT_REQUEST_EXPLANATION = 'AddRemoveEditRequestExplanation',
  EDIT_REQUEST_REASON = 'EditRequestReason',
  // Dispute filing
  EDIT_RECEIVED_DATE = 'EditReceivedDate',
  EDIT_FILE_METHOD = 'EditFileMethod',
  // Notes & Emails
  ADD_NOTES = 'AddNotes',
  SEND_EMAIL = 'SendEmail',
  // #endregion

  // #region OCC Sections
  ADD_REMOVE_EDIT_CLAIMANT = 'AddRemoveEditClaimant',
  ADD_REMOVE_EDIT_CLAIMANT_CONTACT = 'AddRemoveEditClaimantContact',
  ADD_REMOVE_EDIT_ON_BEHALF_OF = 'AddRemoveEditOnBehalfOf',
  ADD_REMOVE_EDIT_ON_BEHALF_OF_CONTACT = 'AddRemoveEditOnBehalfOfContact',
  REASSIGN_DISPUTE_OCC = 'ReassignDisputeOCC',
  REASSIGN_DISPUTE_OCC_CORRECTIONS_UNDER_PROGRESS = 'ReassignDisputeOCCCorrectionsUnderProgress',
  UN_ASSIGN_DISPUTE_OCC = 'UnAssignDisputeOCC',
  UN_ASSIGN_DISPUTE_OCC_CORRECTIONS_UNDER_PROGRESS = 'UnAssignDisputeOCCCorrectionsUnderProgress',
  DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_OCC = 'DeclineCorrectionsUnderProgressDisputeOCC',
  // #endregion

  // #region WNI Section
  EDIT_SHIPPER_WEBSITE = 'EditShipperWebSite',
  EDIT_INSPECTOR = 'EditInspector',
  UPDATE_INSPECTION_APPEALS = 'UpdateInspectionAppeals',
  UPDATE_INSPECTION_APPEALS_CORRECTIONS_UNDER_PROGRESS = 'UpdateInspectionAppealsCorrectionsUnderProgress',
  REASSIGN_DISPUTE_WNI = 'ReassignDisputeWNI',
  REASSIGN_DISPUTE_WNI_CORRECTIONS_UNDER_PROGRESS = 'ReassignDisputeWNICorrectionsUnderProgress',
  UN_ASSIGN_DISPUTE_WNI = 'UnAssignDisputeWNI',
  UN_ASSIGN_DISPUTE_WNI_CORRECTIONS_UNDER_PROGRESS = 'UnAssignDisputeWNICorrectionsUnderProgress',
  DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_WNI = 'DeclineCorrectionsUnderProgressDisputeWNI',
  // #endregion

  // #region DRP
  EDIT_AE_NAE = 'EditAeNae',
  EDIT_AUTHORIZED_APPROVER = 'EditAuthorizedApprover',
  ADD_REMOVE_EDIT_DEBTOR = 'AddRemoveEditDebtor',
  ADD_REMOVE_EDIT_DEBTOR_CONTACT = 'AddRemoveEditDebtorContact',
  ADD_REMOVE_EDIT_NEW_DEBTOR = 'AddRemoveEditNewDebtor',
  ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT = 'AddRemoveEditNewDebtorContact',
  UPDATE_DISPUTE_RESOLUTION_PROCESS = 'UpdateDisputeResolutionProcess',
  UPDATE_DISPUTE_RESOLUTION_PROCESS_CE = 'UpdateDisputeResolutionProcessCE',
  GRAB_SUBMITTED_FOR_REVIEW_DISPUTE_CE = 'GrabSubmittedForReviewDisputeCE',
  GRAB_SUBMITTED_DISPUTE_CE = 'GrabSubmittedDisputeCE',
  REASSIGN_DISPUTE_DRP_CE = 'ReassignDisputeDRPCE',
  REASSIGN_DISPUTE_DRP_CE_CORRECTIONS_UNDER_PROGRESS = 'ReassignDisputeDRPCECorrectionsUnderProgress',
  UN_ASSIGN_DISPUTE_DRP_CE = 'UnAssignDisputeDRPCE',
  REASSIGN_DISPUTE_DRP = 'ReassignDisputeDRP',
  REASSIGN_DISPUTE_DRP_CORRECTIONS_UNDER_PROGRESS = 'ReassignDisputeDRPCorrectionsUnderProgress',
  UN_ASSIGN_DISPUTE_DRP = 'UnAssignDisputeDRP',
  // #endregion
}
