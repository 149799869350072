import { Injectable } from '@angular/core';

import { DisputeTypeCd, User } from '@xpo-ltl/sdk-common';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DisputesFilingService {
  private disputeTypeSelectedSubject = new BehaviorSubject<DisputeTypeCd | string>('Not Selected any type yet!!');
  disputeTypeSelected$ = this.disputeTypeSelectedSubject.asObservable();

  private fileMethodSubject = new BehaviorSubject<string>('Not Selected any type yet!!');
  fileMethod$ = this.fileMethodSubject.asObservable();

  private loggedUserSubject = new BehaviorSubject<User>(undefined);

  setDisputeType(newDisputeType: DisputeTypeCd, fromSelect = false): void {
    if (fromSelect && this.disputeTypeSelectedSubject.value === newDisputeType) {
      return;
    }
    this.disputeTypeSelectedSubject.next(newDisputeType);
  }

  getDisputeType(): DisputeTypeCd {
    return <DisputeTypeCd>this.disputeTypeSelectedSubject.value;
  }

  setFileMethod(newFileMethod: string): void {
    this.fileMethodSubject.next(newFileMethod);
  }

  getFileMethod(): string {
    return this.fileMethodSubject.value;
  }

  setUserLogged(user: User): void {
    this.loggedUserSubject.next(user);
  }

  getUserLogged(): User {
    return this.loggedUserSubject.value;
  }

  resetDisputeType(defaultValue?: string): void {
    this.disputeTypeSelectedSubject.next(defaultValue);
  }
}
