import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ButtonType, DialogEventKeysEnum, DialogResponse } from '../../../../enums';
import { BaseDisputeDialogButton } from '../../../../interfaces/base-dispute-dialog-button.interface';

@Component({
  selector: 'app-dispute-route-guard-dialog',
  templateUrl: './dispute-route-guard-dialog.component.html',
})
export class DisputeRouteGuardDialogComponent implements OnInit {
  private _title: string;
  private _message: string;
  private _confirmText: string;
  private _cancelText: string;
  private _buttonColor: string;
  private _hideCloseDialogButton: boolean;

  disputeRouteGuardDialogButtons: BaseDisputeDialogButton[];

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogRef: MatDialogRef<DisputeRouteGuardDialogComponent>
  ) {
    this._title = dialogData.title;
    this._message = dialogData.message;
    this._confirmText = dialogData.confirmText;
    this._cancelText = dialogData.cancelText;
    this._buttonColor = dialogData.buttonColor;
    this._hideCloseDialogButton = dialogData.hideCloseDialogButton;
  }

  ngOnInit() {
    this.disputeRouteGuardDialogButtons = [
      {
        displayText: this._cancelText,
        buttonType: ButtonType.STROKED,
        eventKey: DialogEventKeysEnum.STAY_ON_THIS_PAGE,
        color: this._buttonColor,
      },
      {
        displayText: this._confirmText,
        buttonType: ButtonType.FLAT,
        eventKey: DialogEventKeysEnum.EXIT_THIS_PAGE,
        color: this._buttonColor,
      },
    ];
  }

  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get hideCloseDialogButton(): boolean {
    return this._hideCloseDialogButton;
  }

  handleCloseButtonClick() {
    this.dialogRef.close(DialogResponse.DIALOG_CLOSED);
  }

  handleClick(eventKey: DialogEventKeysEnum) {
    switch (eventKey) {
      case DialogEventKeysEnum.EXIT_THIS_PAGE:
        this.handleExitThisPageButtonClicked();
        break;
      case DialogEventKeysEnum.STAY_ON_THIS_PAGE:
        this.handleStayOnThisPageButtonClicked();
        break;
    }
  }

  private handleExitThisPageButtonClicked() {
    this.dialogRef.close(DialogResponse.EXIT_THIS_PAGE);
  }

  private handleStayOnThisPageButtonClicked() {
    this.dialogRef.close(DialogResponse.STAY_ON_THIS_PAGE);
  }
}
