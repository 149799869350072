import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { DialogEventKeysEnum } from '../../../../enums';
import { ButtonType } from '../../../../enums/dialogs/button-type.enum';
import { DialogResponse } from '../../../../enums/dialogs/dialog-response.enum';
import { BaseDisputeDialogButton } from '../../../../interfaces/base-dispute-dialog-button.interface';

@Component({
  selector: 'app-confirm-new-dispute-dialog',
  templateUrl: './confirm-new-dispute-dialog.component.html',
  styleUrls: ['./confirm-new-dispute-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmNewDisputeDialogComponent implements OnInit {
  private _title: string;
  private _message: string;
  private _confirmText: string;
  private _cancelText: string;
  private _buttonColor: string;
  private _hideCloseDialogButton: boolean;

  confirmDisputeDialogButtons: BaseDisputeDialogButton[];

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogRef: MatDialogRef<ConfirmNewDisputeDialogComponent>
  ) {
    this._title = dialogData.title;
    this._message = dialogData.message;
    this._confirmText = dialogData.confirmText;
    this._cancelText = dialogData.cancelText;
    this._buttonColor = dialogData.buttonColor;
    this._hideCloseDialogButton = dialogData.hideCloseDialogButton;
  }

  ngOnInit() {
    this.confirmDisputeDialogButtons = [
      {
        displayText: this._cancelText,
        buttonType: ButtonType.STROKED,
        eventKey: DialogEventKeysEnum.GO_BACK,
        color: this._buttonColor,
      },
      {
        displayText: this._confirmText,
        buttonType: ButtonType.FLAT,
        eventKey: DialogEventKeysEnum.SUBMIT,
        color: this._buttonColor,
      },
    ];
  }

  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get hideCloseDialogButton(): boolean {
    return this._hideCloseDialogButton;
  }

  handleCloseButtonClick() {
    this.dialogRef.close(DialogResponse.DIALOG_CLOSED);
  }

  handleClick(eventKey: DialogEventKeysEnum) {
    switch (eventKey) {
      case DialogEventKeysEnum.GO_BACK:
        this.handleGoBackClicked();
        break;
      case DialogEventKeysEnum.SUBMIT:
        this.handleSubmitClicked();
        break;
    }
  }

  private handleGoBackClicked() {
    this.dialogRef.close(DialogResponse.GO_BACK);
  }

  private handleSubmitClicked() {
    this.dialogRef.close(DialogResponse.SUBMIT);
  }
}
