import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { DialogEventKeysEnum } from '../../../../enums';
import { ButtonType } from '../../../../enums/dialogs/button-type.enum';
import { DialogResponse } from '../../../../enums/dialogs/dialog-response.enum';
import { BaseDisputeDialogButton } from '../../../../interfaces/base-dispute-dialog-button.interface';

@Component({
  selector: 'app-success-dispute-dialog',
  templateUrl: './success-dispute-dialog.component.html',
  styleUrls: ['./success-dispute-dialog.component.scss'],
})
export class SuccessDisputeDialogComponent implements OnInit {
  private _title: string;
  private _message: string;
  private _status: string;
  private _statusMessage: string;
  private _confirmText: string;
  private _cancelText: string;
  private _additionalLinkText: string;
  private _additionalTextIcon: string;
  private _disputeId: string;
  private _additionalTextFunctionality;

  successDisputeDialogButtons: BaseDisputeDialogButton[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogRef: MatDialogRef<SuccessDisputeDialogComponent>
  ) {
    this._title = dialogData.title;
    this._message = dialogData.message;
    this._status = dialogData.status;
    this._statusMessage = dialogData.statusMessage;
    this._confirmText = dialogData.confirmText;
    this._cancelText = dialogData.cancelText;
    this._additionalLinkText = dialogData.additionalLinkText;
    this._additionalTextIcon = dialogData.additionalTextIcon;
    this._disputeId = dialogData.disputeId;
    this._additionalTextFunctionality = dialogData.additionalTextFunctionality;
  }

  ngOnInit() {
    if (!!this.cancelText) {
      this.successDisputeDialogButtons.push({
        displayText: this.cancelText,
        buttonType: ButtonType.STROKED,
        eventKey: DialogEventKeysEnum.GOTO_NEW_DISPUTE,
      });
    }
    if (!!this.confirmText) {
      this.successDisputeDialogButtons.push({
        displayText: this.confirmText,
        buttonType: ButtonType.FLAT,
        eventKey: DialogEventKeysEnum.GOTO_DASOBOARD,
      });
    }
  }

  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get disputeId(): string {
    return this._disputeId;
  }

  get statusIcon(): string {
    if (this._status === 'SUCCESS') {
      return 'check_circle';
    } else if (this._status === 'ERROR') {
      return 'highlight_off';
    } else if (this._status === 'WARNING') {
      return 'warning';
    } else {
      return 'info';
    }
  }

  get statusClass(): string {
    const status = this._status || '';
    return `status-icon--${status.toLowerCase()}`;
  }

  get statusMessage(): string {
    return this._statusMessage;
  }

  get confirmText(): string {
    return this._confirmText;
  }

  get cancelText(): string {
    return this._cancelText;
  }

  get additionalLinkText(): string {
    return this._additionalLinkText;
  }

  get additionalTextIcon(): string {
    return this._additionalTextIcon;
  }

  handleClick(eventKey: DialogEventKeysEnum) {
    switch (eventKey) {
      case DialogEventKeysEnum.GOTO_DASOBOARD:
        this.handleGoToDashboardClicked();
        break;
      case DialogEventKeysEnum.GOTO_NEW_DISPUTE:
        this.handleGoToNewDisputeClicked();
        break;
    }
  }

  handleAdditionalLinkClick() {
    this._additionalTextFunctionality();
  }

  private handleGoToDashboardClicked() {
    this.dialogRef.close(DialogResponse.DASHBOARD);
  }

  private handleGoToNewDisputeClicked() {
    this.dialogRef.close(DialogResponse.NEW_DISPUTE);
  }
}
