<div class="notes-emails">
  <mat-tab-group #tabGroup class="notes-emails__tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon matTooltip="Notes" data-test="notesTab">note</mat-icon>
        {{ NotesEmailsFormLabelsEnum.NOTES }} ({{ notesTotalCount$ | async }})
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon matTooltip="Emails" data-test="emailsTab">mail</mat-icon>
        {{ NotesEmailsFormLabelsEnum.EMAILS }} ({{ emailsTotalCount$ | async }})
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div class="notes-emails__closecont">
    <div class="notes-emails__text"><span>Press Alt-N to expand or collapse this section</span></div>

    <div class="notes-emails__close">
      <button
        id="close"
        mat-button
        mat-icon-button
        aria-label="close"
        (click)="closeClicked()"
        data-test="closeNotesEmails"
      >
        <mat-icon matTooltip="Close">arrow_downward</mat-icon>
      </button>
    </div>
  </div>

  <div class="notes-emails__content" [hidden]="!(isInternalNotesActive$ | async)">
    <xpo-notes
      (notesFormGroup)="updateNotesFormGroup($event)"
      [tabChanged]="isInternalNotesActive$ | async"
      [enabled]="addNotesEnabled"
    ></xpo-notes>
  </div>
  <div class="notes-emails__content" [hidden]="!(isEmailActive$ | async)">
    <xpo-emails
      (emailsForm)="updateEmailsForm($event)"
      [tabChanged]="isEmailActive$ | async"
      [config]="emailConfig$ | async"
      [enabled]="sendEmailEnabled"
    ></xpo-emails>
  </div>
</div>
