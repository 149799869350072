import { DisputeTypeCd } from '@xpo-ltl/sdk-common';

import { DashboardTabsEnum } from './dashboard-tabs.enum';

export class DashboardTabsHelperService {
  static values(): string[] {
    const values = [];
    for (const tab in DashboardTabsEnum) {
      if (tab) {
        values.push(DashboardTabsEnum[tab]);
      }
    }
    return values;
  }

  static getDisputeTab(disputeType: DisputeTypeCd): DashboardTabsEnum {
    if (!disputeType) {
      return;
    }
    switch (disputeType) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return DashboardTabsEnum.OCC;
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return DashboardTabsEnum.DRP;
      case DisputeTypeCd.INSPECTION_APPEALS:
        return DashboardTabsEnum.WNI;
    }
  }

  static getDisputeType(disputeTab: DashboardTabsEnum): DisputeTypeCd {
    if (!disputeTab) {
      return;
    }
    switch (disputeTab) {
      case DashboardTabsEnum.OCC:
        return DisputeTypeCd.OVERCHARGE_CLAIMS;
      case DashboardTabsEnum.DRP:
        return DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS;
      case DashboardTabsEnum.WNI:
        return DisputeTypeCd.INSPECTION_APPEALS;
    }
  }
}
