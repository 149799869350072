import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

import * as _ from 'lodash';
import { NotesEntryMaxLengthsEnum } from '../enums';

interface FormUtilsControlValues {
  [control: string]: any;
}

export interface FormUtilsControlState {
  name: string;
  status: string;
  valid: boolean;
  pending: boolean;
  enabled: boolean;
  dirty: boolean;
  touched: boolean;
  errors: ValidationErrors;
  warnings: any;
  value?: any;
  children?: FormUtilsControlState[];
}

/**
 * Various Angular Form utilities
 */
export class FormUtils {
  static defaultEmitterOptions: any = { onlySelf: true, emitEvent: false };

  /**
   * Returns object representing the control's state
   */
  static getControlState(
    control: AbstractControl,
    controlName: any = null,
    recursive = false
  ): FormUtilsControlState {
    const result: FormUtilsControlState = {
      name: controlName,
      status: control.status,
      value: undefined,
      valid: control.valid,
      pending: control.pending,
      enabled: control.enabled,
      errors: control.errors,
      warnings: control['warnings'],
      dirty: control.dirty,
      touched: control.touched,
    };
    if (control instanceof FormControl) {
      _.set(result, 'value', control.value);
    }

    if (recursive) {
      _.set(
        result,
        'children',
        _.map(_.get(control, 'controls', []), (c, name) => this.getControlState(c, name, recursive))
      );
    }
    return result;
  }

  /**
   * Sets the specified control in the group to enabled/disabled without emitting events
   */
  static setEnabled(group: FormGroup, field: string, isEnabled: boolean = true) {
    if (group) {
      const control = group.get(field);
      if (control) {
        if (isEnabled) {
          FormUtils.enable(control);
        } else {
          FormUtils.disable(control);
        }
      }
    }
  }

  static setControlEnabled(control: AbstractControl, isEnabled: boolean = true) {
    if (isEnabled) {
      FormUtils.enable(control);
    } else {
      FormUtils.disable(control);
    }
  }

  static enable(control: AbstractControl, opts: any = this.defaultEmitterOptions) {
    if (control && control.disabled) {
      control.enable(opts);
    }
  }

  static disable(control: AbstractControl, opts: any = this.defaultEmitterOptions) {
    if (control && control.enabled) {
      control.disable(opts);
    }
  }

  /**
   * Set the values for the named controls if it is different, without emitting statusChanges
   * and valueChanges events
   */
  static setValues(form: AbstractControl, values: FormUtilsControlValues, options: Object = {}) {
    if (form) {
      _.forOwn(values, (value, controlKey) => {
        const control = form.get(controlKey);
        FormUtils.setValue(control, value, options);
      });
    }
  }

  /**
   * Set the value for the control if it is different, without emitting statusChanges
   * and valueChanges events
   */
  static setValue(control: AbstractControl, value: any, options: Object = {}) {
    if (control) {
      if (!_.isEqual(control.value, value)) {
        control.patchValue(value, { emitEvent: false, ...options }); // don't emit change events
        control.markAsDirty();
      }
      control.markAsTouched();
    }
  }

  static getNestedControl(rootControl: AbstractControl, ...nestedControlNames: string[]) {
    if (rootControl) {
      return _.reduce(
        nestedControlNames,
        (target, name) => {
          return target ? target.get(name) : undefined;
        },
        rootControl
      );
    } else {
      return undefined;
    }
  }

  /**
   * Set the value of the nested control specified.
   */
  static setNestedValue(value: any, rootControl: AbstractControl, ...nestedControlNames: string[]) {
    const control = FormUtils.getNestedControl(rootControl, ...nestedControlNames);
    FormUtils.setValue(control, value);
  }

  /**
   * Get the value of the nested control specified.
   */
  static getNestedValue<T>(rootControl: AbstractControl, ...nestedControlNames: string[]): T {
    const control = FormUtils.getNestedControl(rootControl, ...nestedControlNames);
    return control ? (control.value as T) : undefined;
  }

  /**
   * Mark the passed control as touched. If recursive, mark all of its children as touched
   */
  static markAsTouched(control: AbstractControl, recursive: boolean = true) {
    if (control) {
      control.markAsTouched();
      if (recursive) {
        _.forEach(_.get(control, 'controls', []), (c) => FormUtils.markAsTouched(c, recursive));
      }
    }
  }

  /**
   * Mark the passed control as dirty. If recursive, mark all of its children as touched
   */
  static markAsDirty(control: AbstractControl, recursive: boolean = true) {
    if (control) {
      control.markAsDirty();
      if (recursive) {
        _.forEach(_.get(control, 'controls', []), (c) => FormUtils.markAsDirty(c, recursive));
      }
    }
  }

  /**
   * Mark the passed control as dirty. If recursive, mark all of its children as touched
   */
  static markAsTouchedAndDirty(control: AbstractControl, recursive: boolean = true) {
    if (control) {
      control.markAsDirty();
      control.markAsTouched();
      if (recursive) {
        _.forEach(_.get(control, 'controls', []), (c) => FormUtils.markAsTouchedAndDirty(c, recursive));
      }
    }
  }

  /** touch all controls and update value and validity*/
  static touchAllControls(control: AbstractControl, onlyPristine: boolean = false) {
    if (control) {
      if (onlyPristine) {
        if (!control.touched) {
          control.markAsTouched();
          control.updateValueAndValidity();
        }
      } else {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
      _.forEach(_.get(control, 'controls', []), (c) => FormUtils.touchAllControls(c, onlyPristine));
    }
  }

  /** touch all controls and update value and validity*/
  static untouchAllControls(control: AbstractControl) {
    if (control) {
      control.markAsUntouched();
      control.markAsPristine();
      _.unset(control, 'warnings');
      _.forEach(_.get(control, 'controls', []), (c) => FormUtils.untouchAllControls(c));
    }
  }

  /** touch all controls and update value and validity*/
  static updateDescendantControlsValueAndValidity(control: AbstractControl, options: Object = {}) {
    if (control) {
      control.updateValueAndValidity({ onlySelf: true, ...options });
      _.forEach(_.get(control, 'controls', []), (c) => FormUtils.updateDescendantControlsValueAndValidity(c, options));
    }
  }

  /**
   * Return true if the passed control or named control in the passed group has warnings
   * @param group control or group to test
   * @param field name of control in group to test. If not provided, returns if group has warnings
   */
  static hasWarnings(group: AbstractControl, field?: string): boolean {
    if (group && group.enabled) {
      const control = field ? group.get(field) : group;
      return !!control && control.enabled && !_.isEmpty(_.get(control, 'warnings'));
    }
    return false;
  }

  static hasWarning(control: AbstractControl, warning: string, field?: string): boolean {
    if (control && control.enabled) {
      control = field ? control.get(field) : control;
      return control && control.enabled && !!_.get(control, `warnings[${warning}]`);
    }
    return false;
  }

  static getWarning(group: FormGroup, field: string, warning: string): any {
    if (group) {
      const control = field ? group.get(field) : group;
      return _.get(control, `warnings[${warning}]`);
    }
  }

  static setWarning(control: AbstractControl, warning: string): void {
    _.set(control, `warnings`, { ...control['warnings'], [warning]: true });
  }

  static removeWarning(control: AbstractControl, warning: string): void {
    _.unset(control, `warnings['${warning}']`);
  }

  static clearWarnings(control: AbstractControl, warnings: string[] = null) {
    if (control && _.hasIn(control, 'warnings')) {
      if (warnings) {
        _.forEach(_.castArray(warnings), (warning) => {
          delete control['warnings'][warning];
        });
      } else {
        delete control['warnings'];
      }
    }
  }

  static resetControl(control: AbstractControl, defaultValue?: any): void {
    control.reset(defaultValue);
    this.clearWarnings(control);
  }

  static hasErrors(group: FormGroup, field: string): boolean {
    if (group && group.enabled) {
      const control = group.get(field);
      return control && control.enabled && control.invalid;
    }
    return false;
  }

  static hasError(group: FormGroup, field: string, error: string): boolean {
    if (group && group.enabled) {
      const control = group.get(field);
      return control && control.enabled && control.hasError(error);
    }
    return false;
  }

  static getError(group: FormGroup, field: string, error: string): any {
    if (group) {
      const control = field ? group.get(field) : group;
      return _.get(control, `errors[${error}]`);
    } else {
      return undefined;
    }
  }

  static getDecodedCharacterCount(encodedValue: string): number {
    const doc = new DOMParser().parseFromString(encodedValue, 'text/html');
    const text = doc.body.textContent || '';
    return text.length;
  }
}
