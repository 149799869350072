import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DisputesExportEnum } from '../../../../enums/dialogs/disputes-export-enum';

@Component({
  selector: 'app-disputes-export-dialog',
  templateUrl: './disputes-export-dialog.component.html',
  styleUrls: ['./disputes-export-dialog.component.scss'],
})
export class DisputesExportDialogComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DisputesExportDialogComponent>
  ) {}
  DisputesExportEnum = DisputesExportEnum;
  title: string;
  description: string;
  ngOnInit(): void {
    this.title = this.data.title;
    this.description = this.data.description;
  }

  ngOnDestroy(): void {}

  onSelectClick() {
    this.dialogRef.close(DisputesExportEnum.CONTINUE);
  }
  onCancelClick() {
    this.dialogRef.close();
  }
}
