import { DisputeDrpCategoryCd, DisputeRequestReasonCd } from '@xpo-ltl/sdk-common';

import { AddressEntryFormLabelsEnum } from '../../enums';
import { DisputeRequestReasonHelper } from './dispute-request-reason-helper';

export class DisputeDebtorHelper {
  /**
   * Return if debtor should have mad code.
   */
  static isMadCodeMandatory(drpCategory: DisputeDrpCategoryCd, requestReason: DisputeRequestReasonCd) {
    return (
      drpCategory === DisputeDrpCategoryCd.DRP_DEBTOR_CHANGE ||
      DisputeRequestReasonHelper.isValidForClaimIntegration(requestReason)
    );
  }

  /**
   * Return when mad code field is required
   */
  static isMadCodeRequired(debtorMethod) {
    return debtorMethod === AddressEntryFormLabelsEnum.SEARCH_DEBTOR;
  }
}
