import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-claims-dispute-dialog-row',
  templateUrl: './claims-dispute-dialog-row.component.html',
  styleUrls: ['./claims-dispute-dialog-row.component.scss'],
})
export class ClaimsDisputeDialogRowComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() value: string;
  @Input() suffix: string;
  constructor() {}

  ngOnInit() {}
}
