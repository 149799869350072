import { Injectable } from '@angular/core';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import {
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdQuery,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';

import { tap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { ConfigManagerProperties } from '../../enums';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class DisputeLoginService {
  storedLoggedInUser: Observable<User> = undefined;

  constructor(
    private loginService: XpoLtlLoggedInUserService,
    private config: ConfigManagerService,
    private humanResourceApiService: HumanResourceApiService,
    private userService: UserService
  ) {}

  /**
   * Wrapper for logger user
   * This set current user in UserService
   */
  getLoggedInUser(): Observable<User> {
    if (!this.storedLoggedInUser) {
      return this.loginService.getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot)).pipe(
        tap((user: User) => {
          this.loggedInEmplData = user;
          this.storedLoggedInUser = of(user);
        }),
        tap((user: User) => (this.userService.currentUser = user))
      );
    }
    return this.storedLoggedInUser;
  }

  set loggedInEmplData(user) {
    const request = new GetEmployeeDetailsByEmpIdPath();
    const query = new GetEmployeeDetailsByEmpIdQuery();
    request.employeeId = `${user.employeeId}`;
    // TODO: temporary fix for the demo and UAT only. Then after this should be removed and
    this.humanResourceApiService.getEmployeeDetailsByEmpId(request, query).subscribe((employee) => {
      this.userService.loggedInEmplData = employee;
      return this.userService.currentUser;
    });
  }
}
