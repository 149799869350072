<div class="notes-detail">
    <div class="notes-detail__subject">
        <div class="notes-detail__subject-text" data-test="noteSubject">{{ note?.subject }}</div>
        <div class="notes-detail__subject-date" data-test="noteDate">{{ note?.auditInfo?.createdTimestamp | date:'HH:mm, MM/dd/yyyy' }}</div>
    </div>
    <div class="notes-detail__from">
        <div class="notes-detail__from-name" data-test="noteAuthor">{{ note.authorName || 'No author' }}</div>
        <div class="notes-detail__from-type" data-test="noteType">{{ note.typeCd | humanize }}</div>

    </div>
    <div class="notes-detail__detail">
        <div [innerHTML]="escapeEmails(note?.note)" data-test="noteDetail"></div>
    </div>
</div>