import { NoteEmailEventTypesEnum } from '../enums';
import { EmailData } from './email-data.class';

export class EmailEvent {
  email: EmailData;
  event: NoteEmailEventTypesEnum;

  constructor(data?: EmailEvent) {
    if (data) {
      this.email = data.email;
      this.event = data.event;
    }
  }
}
