<!-- <div [formGroup]="formGroup" class="declination-explanation"> -->
<h1 mat-dialog-title>
    {{ title }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>
<div mat-dialog-content>
    <xpo-email-entry [formGroup]="emailFormGroup" [templates]="templates" [config]="emailConfig" [ccEmails]="disputeWatchers"></xpo-email-entry>
</div>
<div mat-dialog-actions>
    <button mat-button color="primary" (click)="cancel()">
        {{ emailDialogFormLabelsEnum.CANCEL }}
    </button>
    <button mat-button color="primary" (click)="skipEmail()">
        {{ emailDialogFormLabelsEnum.SKIP }}
    </button>
    <button mat-flat-button (click)="sendEmail()">
        {{ emailDialogFormLabelsEnum.SEND }}
    </button>
</div>
<!-- </div> -->