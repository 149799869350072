import { Injectable } from '@angular/core';

import { DisputeDrpCategoryCd, DisputeRequestReasonCd, DisputeStatusCd, DisputeTypeCd } from '@xpo-ltl/sdk-common';
import {
  AsEnteredParty,
  EmailInteraction,
  GetDisputeResp,
  ListDisputeShipmentsResp,
  Shipment,
} from '@xpo-ltl/sdk-disputes';

import { BehaviorSubject } from 'rxjs';

import { ActionBarButtonTypesCd, AddressEntryFormLabelsEnum, DisputeEditModesEnum } from '../../enums';
import { DisputeApprovalDecision } from '../../interfaces/disputes/dispute-approval-decision.interface';
import { FormError } from '../../interfaces/disputes/form-error.interface';

@Injectable({
  providedIn: 'root',
})
export class DisputesDataService {
  canReset = true;

  disputeSubmissionInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

  // #region Dispute Type
  private disputeTypeSubject: BehaviorSubject<DisputeTypeCd> = new BehaviorSubject<DisputeTypeCd>(undefined);
  disputeType$ = this.disputeTypeSubject.asObservable();

  get disputeType(): DisputeTypeCd {
    return this.disputeTypeSubject.value;
  }

  set disputeType(dispute: DisputeTypeCd) {
    this.disputeTypeSubject.next(dispute);
  }
  //#endregion

  // #region DRP Category
  private drpCategorySubject: BehaviorSubject<DisputeDrpCategoryCd> = new BehaviorSubject<DisputeDrpCategoryCd>(
    undefined
  );
  drpCategory$ = this.drpCategorySubject.asObservable();

  get drpCategory(): DisputeDrpCategoryCd {
    return this.drpCategorySubject.value;
  }

  set drpCategory(dispute: DisputeDrpCategoryCd) {
    this.drpCategorySubject.next(dispute);
  }
  //#endregion

  // #region Debtor Method
  private debtorMethodSubject: BehaviorSubject<AddressEntryFormLabelsEnum> = new BehaviorSubject<
    AddressEntryFormLabelsEnum
  >(undefined);
  debtorMethod$ = this.debtorMethodSubject.asObservable();

  get debtorMethod(): AddressEntryFormLabelsEnum {
    return this.debtorMethodSubject.value;
  }

  set debtorMethod(dispute: AddressEntryFormLabelsEnum) {
    this.debtorMethodSubject.next(dispute);
  }
  //#endregion

  // #region Debtor Address Required
  private addressRequiredSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  addressRequired$ = this.addressRequiredSubject.asObservable();

  get addressRequired(): boolean {
    return this.addressRequiredSubject.value;
  }

  set addressRequired(dispute: boolean) {
    this.addressRequiredSubject.next(dispute);
  }
  //#endregion

  // #region Request Reason Selected
  private requestReasonSelectedSubject: BehaviorSubject<DisputeRequestReasonCd> = new BehaviorSubject<
    DisputeRequestReasonCd
  >(undefined);
  requestReasonSelected$ = this.requestReasonSelectedSubject.asObservable();

  get requestReasonSelected(): DisputeRequestReasonCd {
    return this.requestReasonSelectedSubject.value;
  }

  set requestReasonSelected(dispute: DisputeRequestReasonCd) {
    this.requestReasonSelectedSubject.next(dispute);
  }
  //#endregion

  // #region PRO bulk error
  proBulkErrorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  proBulkError$ = this.proBulkErrorSubject.asObservable();

  get proBulkError(): boolean {
    return this.proBulkErrorSubject.value;
  }

  set proBulkError(dispute: boolean) {
    this.proBulkErrorSubject.next(dispute);
  }
  // #endregion

  // #region Approval Decision
  approvalDecisionSubject: BehaviorSubject<DisputeApprovalDecision> = new BehaviorSubject<DisputeApprovalDecision>(
    undefined
  );
  approvalDecision$ = this.approvalDecisionSubject.asObservable();

  get approvalDecision(): DisputeApprovalDecision {
    return this.approvalDecisionSubject.value;
  }
  set approvalDecision(approvalDecision: DisputeApprovalDecision) {
    this.approvalDecisionSubject.next(approvalDecision);
  }
  // #endregion

  // #region Dispute

  disputeSubject: BehaviorSubject<GetDisputeResp> = new BehaviorSubject<GetDisputeResp>(undefined);
  dispute$ = this.disputeSubject.asObservable();

  get dispute(): GetDisputeResp {
    return this.disputeSubject.value;
  }
  set dispute(dispute: GetDisputeResp) {
    this.disputeSubject.next(dispute);
  }

  // #endregion

  // #region Dispute Status

  disputeStatusSubject: BehaviorSubject<DisputeStatusCd> = new BehaviorSubject<DisputeStatusCd>(undefined);
  disputeStatus$ = this.disputeStatusSubject.asObservable();

  get disputeStatus(): DisputeStatusCd {
    return this.disputeStatusSubject.value;
  }
  set disputeStatus(disputeStatus: DisputeStatusCd) {
    this.disputeStatusSubject.next(disputeStatus);
  }
  // #endregion

  // #region Shipments

  listDisputeShipmentsSubject: BehaviorSubject<ListDisputeShipmentsResp> = new BehaviorSubject<
    ListDisputeShipmentsResp
  >(undefined);
  listDisputeShipments$ = this.listDisputeShipmentsSubject.asObservable();

  get listDisputeShipments(): ListDisputeShipmentsResp {
    return this.listDisputeShipmentsSubject.value;
  }
  set listDisputeShipments(shipments: ListDisputeShipmentsResp) {
    this.listDisputeShipmentsSubject.next(shipments);
  }

  shipmentsSubject: BehaviorSubject<Array<Shipment>> = new BehaviorSubject<Array<Shipment>>([]);
  shipments$ = this.shipmentsSubject.asObservable();
  get shipments(): Array<Shipment> {
    return this.shipmentsSubject.value;
  }
  set shipments(value: Array<Shipment>) {
    this.shipmentsSubject.next(value);
  }

  // #endregion

  // #region Emails

  emailsSubject: BehaviorSubject<EmailInteraction[]> = new BehaviorSubject<EmailInteraction[]>([]);
  emails$ = this.emailsSubject.asObservable();

  get emails(): EmailInteraction[] {
    return this.emailsSubject.value;
  }
  set emails(emails: EmailInteraction[]) {
    this.emailsSubject.next(emails);
  }

  // #endregion

  // #region Edit Mode

  editModeSubject: BehaviorSubject<DisputeEditModesEnum> = new BehaviorSubject<DisputeEditModesEnum>(
    DisputeEditModesEnum.CREATE_DISPUTE
  );
  editMode$ = this.editModeSubject.asObservable();
  get editMode(): DisputeEditModesEnum {
    return this.editModeSubject.value;
  }
  set editMode(value: DisputeEditModesEnum) {
    this.editModeSubject.next(value);
  }

  // #endregion

  // #region Button Action Selected

  buttonActionSelectedSubject: BehaviorSubject<ActionBarButtonTypesCd> = new BehaviorSubject<ActionBarButtonTypesCd>(
    undefined
  );
  buttonActionSelected$ = this.buttonActionSelectedSubject.asObservable();

  get buttonActionSelected(): ActionBarButtonTypesCd {
    return this.buttonActionSelectedSubject.value;
  }
  set buttonActionSelected(button: ActionBarButtonTypesCd) {
    this.buttonActionSelectedSubject.next(button);
  }

  // #endregion

  // #region Claimant

  /**
   * claimantAsEnteredParty is used to save the data before match
   * and send this data into the AsEnteredParty array
   */
  claimantAsEnteredPartySubject: BehaviorSubject<AsEnteredParty> = new BehaviorSubject<AsEnteredParty>(undefined);
  claimantAsEnteredParty$ = this.claimantAsEnteredPartySubject.asObservable();

  get claimantAsEnteredParty(): AsEnteredParty {
    return this.claimantAsEnteredPartySubject.value;
  }
  set claimantAsEnteredParty(parties: AsEnteredParty) {
    this.claimantAsEnteredPartySubject.next(parties);
  }

  // #endregion

  // #region On Behalf of
  /**
   * onBeHalfOfAsEnteredParty is used to save the data before match
   * and send this data into the AsEnteredParty array
   */
  onBeHalfOfAsEnteredPartySubject: BehaviorSubject<AsEnteredParty> = new BehaviorSubject<AsEnteredParty>(undefined);
  onBeHalfOfAsEnteredParty$ = this.onBeHalfOfAsEnteredPartySubject.asObservable();

  get onBeHalfOfAsEnteredParty(): AsEnteredParty {
    return this.onBeHalfOfAsEnteredPartySubject.value;
  }
  set onBeHalfOfAsEnteredParty(parties: AsEnteredParty) {
    this.onBeHalfOfAsEnteredPartySubject.next(parties);
  }
  // #endregion

  // #region Form Errors

  formErrorsSubject: BehaviorSubject<FormError[]> = new BehaviorSubject<FormError[]>(undefined);
  formErrors$ = this.formErrorsSubject.asObservable();

  get formErrors(): FormError[] {
    if (this.proBulkError && !this.isProErrorInList()) {
      this.insertProBulkError();
    }
    return this.formErrorsSubject.value;
  }

  set formErrors(errors: FormError[]) {
    this.formErrorsSubject.next(errors);
  }

  // #endregion
  constructor() {}

  isProErrorInList() {
    const section = this.formErrorsSubject.value.filter((e) => e.name === 'InvoiceDetails');
    return section && section.some((e) => e.errors.some((err) => err.name === 'PRO Import Failed'));
  }
  insertProBulkError() {
    const result: FormError = {
      description: '',
      errors: [
        {
          description: 'One or more PROs on the uploaded spreadsheet had errors.',
          linkText: 'View Error',
          name: 'PRO Import Failed',
          title: 'PRO Import Failed',
        },
      ],
      linkText: 'View Error',
      name: 'InvoiceDetails',
      title: 'Invoice Details',
    };

    this.formErrorsSubject.value.push(result);
  }

  reset() {
    this.editMode = DisputeEditModesEnum.CREATE_DISPUTE;
    this.dispute = undefined;
    this.disputeStatus = undefined;
    this.drpCategory = undefined;
    this.approvalDecision = undefined;
    this.shipments = [];
    this.listDisputeShipments = undefined;
    this.claimantAsEnteredParty = undefined;
    this.onBeHalfOfAsEnteredParty = undefined;
    this.buttonActionSelected = undefined;
    this.debtorMethod = undefined;
    this.requestReasonSelected = undefined;
    this.emails = [];
    this.formErrors = [];
    this.proBulkError = false;
  }

  get disputeSubmissionInProgress(): boolean {
    return this.disputeSubmissionInProgressSubject.value;
  }

  set disputeSubmissionInProgress(value: boolean) {
    this.disputeSubmissionInProgressSubject.next(value);
  }
}
