import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EmailData } from '../classes/email-data.class';
import { EmailEvent } from '../classes/email-event.class';
import { NoteEvent } from '../classes/note-event.class';

@Injectable({
  providedIn: 'root',
})
export class NotesEmailsService {
  // #region Note Types

  noteTypesSubject = new BehaviorSubject<any[]>([]);
  noteTypes$ = this.noteTypesSubject.asObservable();

  get noteTypes(): any[] {
    return this.noteTypesSubject.value;
  }
  // used for note entry
  set noteTypes(types: any[]) {
    this.noteTypesSubject.next(types);
  }

  filterNoteTypesSubject = new BehaviorSubject<any[]>([]);
  filterNoteTypes$ = this.filterNoteTypesSubject.asObservable();

  get filterNoteTypes(): any[] {
    return this.filterNoteTypesSubject.value;
  }
  // used to filter created notes
  set filterNoteTypes(types: any[]) {
    this.filterNoteTypesSubject.next(types);
  }

  // #endregion

  // #region Default Note Types
  defaultNoteTypeSubject = new BehaviorSubject<any>(null);
  defaultNoteType$ = this.defaultNoteTypeSubject.asObservable();

  get defaultNoteType(): any {
    return this.defaultNoteTypeSubject.value;
  }
  set defaultNoteType(type: any) {
    this.defaultNoteTypeSubject.next(type);
  }

  // #endregion

  // #region Email Template Types
  emailTemplateTypesSubject = new BehaviorSubject<any[]>([]);
  emailTemplateTypes$ = this.emailTemplateTypesSubject.asObservable();

  get emailTemplateTypes(): any[] {
    return this.emailTemplateTypesSubject.value;
  }
  set emailTemplateTypes(types: any[]) {
    this.emailTemplateTypesSubject.next(types);
  }
  // #endregion

  // #region Default Email Template Type
  defaultEmailTemplateTypeSubject = new BehaviorSubject<any>(null);
  defaultEmailTemplateType$ = this.defaultEmailTemplateTypeSubject.asObservable();

  get defaultEmailTemplateType(): any {
    return this.defaultEmailTemplateTypeSubject.value;
  }
  set defaultEmailTemplateType(type: any) {
    this.defaultEmailTemplateTypeSubject.next(type);
  }

  // #endregion

  // #region Internal Emails
  emailsSubject = new BehaviorSubject<EmailData[]>([]);
  emails$ = this.emailsSubject.asObservable();

  get emails(): EmailData[] {
    return this.emailsSubject.value;
  }
  set emails(types: EmailData[]) {
    this.emailsSubject.next(types);
  }
  // #endregion

  // #region Notes
  notesSubject = new BehaviorSubject<any[]>([]);
  notes$ = this.notesSubject.asObservable();

  get notes(): any[] {
    return this.notesSubject.value;
  }
  set notes(types: any[]) {
    this.notesSubject.next(types);
  }
  // #endregion

  // #region Note Event
  noteEventsSubject = new BehaviorSubject<NoteEvent>(undefined);
  noteEvents$ = this.noteEventsSubject.asObservable();

  get noteEvents(): NoteEvent {
    return this.noteEventsSubject.value;
  }
  set noteEvents(types: NoteEvent) {
    this.noteEventsSubject.next(types);
  }
  // #endregion

  // #region Email Event
  emailEventsSubject = new BehaviorSubject<EmailEvent>(undefined);
  emailEvents$ = this.emailEventsSubject.asObservable();

  get emailEvents(): EmailEvent {
    return this.emailEventsSubject.value;
  }
  set emailEvents(types: EmailEvent) {
    this.emailEventsSubject.next(types);
  }
  // #endregion

  // #region Show
  showNotesEmailsSubject = new BehaviorSubject<boolean>(false);
  showNotesEmails$ = this.showNotesEmailsSubject.asObservable();

  get showNotesEmails(): boolean {
    return this.showNotesEmailsSubject.value;
  }
  set showNotesEmails(show: boolean) {
    this.showNotesEmailsSubject.next(show);
  }
  // #endregion

  constructor() {}

  cleanNotesAndEmails() {
    this.notes = [];
    this.emails = [];
    this.emailTemplateTypes = [];
    this.noteTypes = [];
    this.defaultNoteType = undefined;
    this.noteEvents = undefined;
    this.emailEvents = undefined;
    this.showNotesEmails = false;
  }
}
