import { DisputeDecisionReasonSubTypeCd } from '@xpo-ltl/sdk-common';

export class DisputeDecisionReasonCancelHelper {
  readonly DisputeDecisionReasonSubTypeCd: DisputeDecisionReasonSubTypeCd;

  static getOCCCancelReasons(): string[] {
    const reasons = [
      DisputeDecisionReasonSubTypeCd.AUDIT_COMPANY_REQUESTED_CLAIM_CANCELLATION,
      DisputeDecisionReasonSubTypeCd.DUPLICATE_CLAIM,
      DisputeDecisionReasonSubTypeCd.WRONG_AUDIT_COMPANY,
      DisputeDecisionReasonSubTypeCd.WRONG_CODE_USED_WRONG_ADDRESS,
      DisputeDecisionReasonSubTypeCd.OTHER,
      DisputeDecisionReasonSubTypeCd.APPROVED_PROCESSED_SUBMITTED_IN_ERROR,
      DisputeDecisionReasonSubTypeCd.CORRECTED_VIA_ANOTHER_PROCESS,
      DisputeDecisionReasonSubTypeCd.EMPLOYEE_ERROR,
      DisputeDecisionReasonSubTypeCd.EMPLOYEE_NO_LONGER_WITH_XPO,
      DisputeDecisionReasonSubTypeCd.INCORRECT_ADJUSTMENT_AMOUNT,
      DisputeDecisionReasonSubTypeCd.INCORRECT_INVOICE_NUMBER,
      DisputeDecisionReasonSubTypeCd.REALLOCATE_WORKLOAD,
    ];
    return reasons.map((code) => code.valueOf());
  }

  static getWNICancelReasons(): string[] {
    const reasons = [
      DisputeDecisionReasonSubTypeCd.EMPLOYEE_ERROR,
      DisputeDecisionReasonSubTypeCd.APPROVED_PROCESSED_SUBMITTED_IN_ERROR,
      DisputeDecisionReasonSubTypeCd.INCORRECT_INVOICE_NUMBER,
      DisputeDecisionReasonSubTypeCd.CORRECTED_VIA_ANOTHER_PROCESS,
      DisputeDecisionReasonSubTypeCd.OTHER,
      DisputeDecisionReasonSubTypeCd.DUPLICATE_CLAIM,
      DisputeDecisionReasonSubTypeCd.EMPLOYEE_NO_LONGER_WITH_XPO,
      DisputeDecisionReasonSubTypeCd.REALLOCATE_WORKLOAD,
      DisputeDecisionReasonSubTypeCd.INCORRECT_ADJUSTMENT_AMOUNT,
    ];
    return reasons.map((code) => code.valueOf());
  }

  static getDRPCancelReasons(): string[] {
    const reasons = [
      DisputeDecisionReasonSubTypeCd.PURSUING_COLLECTION_EFFORT,
      DisputeDecisionReasonSubTypeCd.REWEIGH_ADJUSTMENT,
      DisputeDecisionReasonSubTypeCd.BAD_DEBT_WRITE_OFF,
      DisputeDecisionReasonSubTypeCd.EMPLOYEE_ERROR,
      DisputeDecisionReasonSubTypeCd.APPROVED_PROCESSED_SUBMITTED_IN_ERROR,
      DisputeDecisionReasonSubTypeCd.INCORRECT_INVOICE_NUMBER,
      DisputeDecisionReasonSubTypeCd.DUPLICATE_CLAIM,
      DisputeDecisionReasonSubTypeCd.EMPLOYEE_NO_LONGER_WITH_XPO,
      DisputeDecisionReasonSubTypeCd.REALLOCATE_WORKLOAD,
      DisputeDecisionReasonSubTypeCd.CORRECTED_VIA_ANOTHER_PROCESS,
      DisputeDecisionReasonSubTypeCd.INCORRECT_ADJUSTMENT_AMOUNT,
      DisputeDecisionReasonSubTypeCd.OTHER,
    ];
    return reasons.map((code) => code.valueOf());
  }
}
