export enum AmendExplanationDialogFormLabelsEnum {
  TITLE = 'Amend Details',
  DESCRIPTION = 'Please explain the reason for amendment',
  REASON_CODE = 'Decision Reason Code',
  REASON_EXPLANATION = 'Reason for Amendment',
  ADD_EMAIL_DESCRIPTION = 'Include more people on the amendment email.',
  ADD_EMAIL_FIELD = 'cc',
  SUBMIT = 'Amend',
  CANCEL = 'Cancel',
}
