import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CancelationExplanationDialogFormNamesEnum,
  CancelationExplanationDialogMaxLenghtsEnum,
} from '../../../../enums';

export class CancelationExplanationDialogFormBuilder {
  static create(fb: FormBuilder): FormGroup {
    return fb.group({
      [CancelationExplanationDialogFormNamesEnum.REASON_CODE]: ['', Validators.required],
      [CancelationExplanationDialogFormNamesEnum.REASON_EXPLANATION]: [
        '',
        Validators.maxLength(CancelationExplanationDialogMaxLenghtsEnum.REASON_EXPLANATION),
      ],
    });
  }
}
