import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { DisputePermissionsEnum, DisputeRolesEnum } from '../../enums';
import { UserDomain } from '../../enums/users/user-domain.enum';

@Injectable({
  providedIn: 'root',
})
export class DisputesRolesPermissionsService {
  constructor(private configManagerService: ConfigManagerService) {}

  getRoleFromConfig(role): string {
    return this.configManagerService.getSetting(role);
  }

  getPermissionsByRoles(roles: any): DisputePermissionsEnum[] {
    const hasRoles = (role) => {
      return (
        roles.includes(`${UserDomain.CONWAY}/${role}`) ||
        roles.includes(`${UserDomain.CONWAY_AD}/${role}`) ||
        roles.includes(`${UserDomain.CTS}/${role}`) ||
        roles.includes(`${UserDomain.CTS_AD}/${role}`) ||
        roles.includes(`${UserDomain.CFI}/${role}`) ||
        roles.includes(`${UserDomain.CFI_AD}/${role}`)
      );
    };
    if (!roles || !roles.length) {
      return [];
    }
    let permissions: DisputePermissionsEnum[] = [];
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.VIEW_ONLY))) {
      permissions = permissions.concat(this.getViewOnlyPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.REQUESTER))) {
      permissions = permissions.concat(this.getRequestorPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.OCC_APPROVER))) {
      permissions = permissions.concat(this.getOccApproverPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.WNI_APPROVER))) {
      permissions = permissions.concat(this.getWniApproverPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.CORRECTIONS_AUDITOR))) {
      permissions = permissions.concat(this.getCorrectionsAuditorPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.NAE))) {
      permissions = permissions.concat(this.getNAEPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.CUSTOMER_CARE_SUPERVISOR))) {
      permissions = permissions.concat(this.getCustomerCareSupervisorPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.SERVICE_CENTER_MANAGER))) {
      permissions = permissions.concat(this.getServiceCenterManagerPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.SMRS))) {
      permissions = permissions.concat(this.getSMRSPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.DNS))) {
      permissions = permissions.concat(this.getDNSPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.DOO))) {
      permissions = permissions.concat(this.getDOOPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.DIRECTOR_OF_CUSTOMER_CARE))) {
      permissions = permissions.concat(this.getDirectorOfCustomerCarePermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.DISPUTE_DIRECTOR))) {
      permissions = permissions.concat(this.getDisputeDirectorPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.VPAS))) {
      permissions = permissions.concat(this.getVPASPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.VPAO))) {
      permissions = permissions.concat(this.getVPAOPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.VP_MARKETING))) {
      permissions = permissions.concat(this.getVPMarketingPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.SVP_SALES))) {
      permissions = permissions.concat(this.getSVPSalesPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.SVP_OPS))) {
      permissions = permissions.concat(this.getSVPOpsPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.DISPUTE_SVP))) {
      permissions = permissions.concat(this.getDisputeSVPPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.VP_FINANCE))) {
      permissions = permissions.concat(this.getVPFinancePermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.DISPUTE_VP))) {
      permissions = permissions.concat(this.getDisputeVPPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.COLL_REVIEWER))) {
      permissions = permissions.concat(this.getCollReviewerPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.COLL_APPROVER))) {
      permissions = permissions.concat(this.getCollApproverPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.ADMIN))) {
      permissions = permissions.concat(this.getAdminPermissions());
    }
    if (hasRoles(this.getRoleFromConfig(DisputeRolesEnum.DISPUTE_VPS))) {
      permissions = permissions.concat(this.getDisputesVPSPermissions());
    }
    // Remove duplicates
    return permissions.filter((permission, index) => permissions.indexOf(permission) === index);
  }

  getViewOnlyPermissions(): DisputePermissionsEnum[] {
    return [DisputePermissionsEnum.DASHBOARD, DisputePermissionsEnum.VIEW_ALL_DISPUTE];
  }

  getRequestorPermissions(): DisputePermissionsEnum[] {
    return [
      // OCC
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.CREATE_DISPUTE,
      DisputePermissionsEnum.DUPLICATE_DISPUTE,
      DisputePermissionsEnum.SAVE_AS_DRAFT,
      DisputePermissionsEnum.SUBMIT_DISPUTE,
      DisputePermissionsEnum.SUBMIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.SUBMIT_DISPUTE_FOR_REVIEW,
      DisputePermissionsEnum.SUBMIT_UNDER_REVIEW_DISPUTE_FOR_REVIEW,
      DisputePermissionsEnum.DELETE_DRAFT_DISPUTE,
      DisputePermissionsEnum.EDIT_DRAFT_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.GRAB_MY_SUBMITTED_DISPUTE_OCC,
      DisputePermissionsEnum.GRAB_MY_SUBMITTED_DISPUTE_WNI,
      DisputePermissionsEnum.GRAB_MY_SUBMITTED_DISPUTE_MRRA,
      DisputePermissionsEnum.GRAB_MY_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_OCC,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_WNI,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      // Edit common
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      // OCC Edit
      DisputePermissionsEnum.ADD_REMOVE_EDIT_CLAIMANT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_CLAIMANT_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_ON_BEHALF_OF,
      DisputePermissionsEnum.UPDATE_OVERCHARGE_CLAIMS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_OCC,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_OCC,
      // WNI Edit
      DisputePermissionsEnum.ADD_REMOVE_EDIT_ON_BEHALF_OF_CONTACT,
      DisputePermissionsEnum.EDIT_SHIPPER_WEBSITE,
      DisputePermissionsEnum.EDIT_INSPECTOR,
      DisputePermissionsEnum.UPDATE_INSPECTION_APPEALS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_WNI,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_WNI,
      // DRP Edit
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,
      DisputePermissionsEnum.GRAB_MY_SUBMITTED_FOR_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.GRAB_SUBMITTED_FOR_REVIEW_DISPUTE_CE,
    ];
  }

  getOccApproverPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_OCC,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.APPROVE_DISPUTE_OCC,
      DisputePermissionsEnum.DECLINE_DISPUTE_OCC,
      DisputePermissionsEnum.CANCEL_DISPUTE_OCC,
      DisputePermissionsEnum.AMEND_DISPUTE_OCC,
      DisputePermissionsEnum.UPDATE_OVERCHARGE_CLAIMS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_OCC,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_OCC,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_CLAIMANT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_CLAIMANT_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_ON_BEHALF_OF,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_ON_BEHALF_OF_CONTACT,
      // Edit PRO List
      DisputePermissionsEnum.ADD_REMOVE_PROS,
    ];
  }

  getWniApproverPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.CREATE_DISPUTE,
      DisputePermissionsEnum.DUPLICATE_DISPUTE,
      DisputePermissionsEnum.SAVE_AS_DRAFT,
      DisputePermissionsEnum.SUBMIT_DISPUTE,
      DisputePermissionsEnum.DELETE_DRAFT_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_WNI,
      DisputePermissionsEnum.APPROVE_DISPUTE_WNI,
      DisputePermissionsEnum.DECLINE_DISPUTE_WNI,
      DisputePermissionsEnum.CANCEL_DISPUTE_WNI,
      DisputePermissionsEnum.AMEND_DISPUTE_WNI,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.EDIT_SHIPPER_WEBSITE,
      DisputePermissionsEnum.EDIT_INSPECTOR,
      // Edit PRO List
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_WNI,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_WNI,
      DisputePermissionsEnum.UPDATE_INSPECTION_APPEALS,
    ];
  }

  getCorrectionsAuditorPermissions(): DisputePermissionsEnum[] {
    return [
      // Generic
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.CAN_START_CORRECTIONS,
      DisputePermissionsEnum.CAN_CORRECTIONS_COMPLETE,
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,

      // OCC
      DisputePermissionsEnum.UPDATE_OVERCHARGE_CLAIMS_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_OCC_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_OCC_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_OCC,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_CLAIMANT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_CLAIMANT_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_ON_BEHALF_OF,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_ON_BEHALF_OF_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,

      // WNI
      DisputePermissionsEnum.UPDATE_INSPECTION_APPEALS_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_WNI_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_WNI_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_WNI,
      DisputePermissionsEnum.DECLINE_DISPUTE_WNI,
      DisputePermissionsEnum.EDIT_INSPECTOR,
      DisputePermissionsEnum.EDIT_SHIPPER_WEBSITE,

      // DRP CE
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE,

      // DRP Debtor and Regular
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getNAEPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,

      //  DRP
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_UP_TO_300,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getDisputeDirectorPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getCustomerCareSupervisorPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,

      //  DRP
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_UP_TO_500,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getServiceCenterManagerPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,

      //  DRP
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_EQUAL_TO_ZERO,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getSMRSPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getDNSPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getDOOPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getDirectorOfCustomerCarePermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getVPASPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.AUTO_ASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
    ];
  }

  getVPAOPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.AUTO_ASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
    ];
  }

  getVPMarketingPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.AUTO_ASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
    ];
  }

  getSVPSalesPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.AUTO_ASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_25000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
    ];
  }

  getSVPOpsPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.AUTO_ASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      // DisputePermissionsEnum.REASSIGN_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      // DisputePermissionsEnum.UN_ASSIGN_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      // DisputePermissionsEnum.UPDATE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getDisputeSVPPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.AUTO_ASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      // DisputePermissionsEnum.REASSIGN_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      // DisputePermissionsEnum.UN_ASSIGN_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      // DisputePermissionsEnum.UPDATE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
    ];
  }

  getVPFinancePermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.APPROVE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.AUTO_ASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
    ];
  }

  getDisputeVPPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.APPROVE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.AUTO_ASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
    ];
  }

  getDisputesVPSPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      // Edit detail
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT,

      // DRP Regular
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP,
    ];
  }

  getCollReviewerPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS,
      DisputePermissionsEnum.ADD_NOTES,
      DisputePermissionsEnum.SEND_EMAIL,
      DisputePermissionsEnum.ADD_REMOVE_WATCHERS,
      DisputePermissionsEnum.ADD_REMOVE_PROS,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
      DisputePermissionsEnum.GRAB_SUBMITTED_FOR_REVIEW_DISPUTE_CE,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_CE,
      DisputePermissionsEnum.SUBMIT_DISPUTE_FOR_REVIEW,
      DisputePermissionsEnum.SUBMIT_DISPUTE_FOR_APPROVE,
      DisputePermissionsEnum.SUBMIT_UNDER_REVIEW_DISPUTE_FOR_REVIEW,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
    ];
  }

  getCollApproverPermissions(): DisputePermissionsEnum[] {
    return [
      DisputePermissionsEnum.DASHBOARD,
      DisputePermissionsEnum.VIEW_ALL_DISPUTE,
      DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_CE,
      DisputePermissionsEnum.GRAB_SUBMITTED_FOR_REVIEW_DISPUTE_CE,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_CE,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000,
      DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000,
      DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000,
      DisputePermissionsEnum.EDIT_AE_NAE,
      DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER,
      DisputePermissionsEnum.EDIT_REQUEST_REASON,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR,
      DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT,
    ];
  }

  getAdminPermissions(): DisputePermissionsEnum[] {
    return Object.values(DisputePermissionsEnum);
  }
}
