import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { DisputeRequestReasonHelper } from '../classes/disputes/dispute-request-reason-helper';
import { DisputesDataService } from '../services/disputes/disputes-data.service';

export class ClaimsIntegrationValidator {
  /**
   * Return { required: true} if it is ClaimIntegration
   * Return null if it is not ClaimIntegration
   */
  static required(disputesDataService: DisputesDataService): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (
        DisputeRequestReasonHelper.isValidForClaimIntegration(disputesDataService.requestReasonSelected) &&
        !control.value
      ) {
        return { required: true };
      }
      return null;
    };
  }
}
