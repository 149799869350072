import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ApprobationExplanationDialogFormNamesEnum,
  ApprobationExplanationDialogMaxLenghtsEnum,
} from '../../../../enums';

export class ApprobationExplanationDialogFormBuilder {
  static create(fb: FormBuilder): FormGroup {
    return fb.group({
      [ApprobationExplanationDialogFormNamesEnum.REASON_CODE]: ['', Validators.required],
      [ApprobationExplanationDialogFormNamesEnum.REASON_EXPLANATION]: [
        '',
        Validators.maxLength(ApprobationExplanationDialogMaxLenghtsEnum.REASON_EXPLANATION),
      ],
    });
  }
}
