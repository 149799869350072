export enum UploadDocumentsFormNamesEnum {
  FORM_GROUP_NAME = 'UploadDocuments',
  FILE_LIST = 'fileList',
  FILE_UPLOADED_LIST = 'fileUploadedList',
  FILE_TO_DELETE_LIST = 'fileToDeleteList',
  UPLOAD = 'Upload',
  DELETE_FILE = 'DeleteFile',
  DOWNLOAD_FILE = 'DownloadFile',

  // Control names
  ATTACH_MIME_TYP_CD = 'ATTACH_MIME_TYP_CD',
  RECORD_VERSION_NBR = 'RECORD_VERSION_NBR',
  LIST_ACTION_CD = 'LIST_ACTION_CD',
  ATTACH_NAME = 'ATTACH_NAME',
  DSP_EVENT_ID = 'DSP_EVENT_ID',
  CORRELATION_ID = 'CORRELATION_ID',
  SEQ_NBR = 'SEQ_NBR',
  DMS_URL = 'DMS_URL',
  ATTACHMENT_DATA_TXT = 'ATTACHMENT_DATA_TXT',
  ATTACHMENT_DATA_TXT_FILE_NAME = 'ATTACHMENT_DATA_TXT_FILE_NAME',
  ATTACHMENT_DATA_TXT_CONTENT_TYPE = 'ATTACHMENT_DATA_TXT_CONTENT_TYPE',
  ATTACHMENT_DATA_TXT_BASE_64_DATA = 'ATTACHMENT_DATA_TXT_BASE_64_DATA',
}
