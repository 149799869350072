<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="50%">
    <p>
      {{ title }}
    </p>
    <p class="subtitle">{{ subtitle }}</p>
  </div>
  <div fxFlex="50%">
    {{ value }} <span *ngIf="value && suffix">{{ suffix }}</span>
  </div>
</div>
<mat-divider></mat-divider>
