import { Pipe, PipeTransform } from '@angular/core';
import { DocType } from '../enums/doc-type.enum';

@Pipe({
  name: 'docType'
})
export class DocTypePipe implements PipeTransform {

  transform(value: DocType, args?: any): any {
    let convertedValue = '';
    switch (value) {
      case DocType.ActivityLogHistory:
        convertedValue = 'Activity Log History';
        break;
      case DocType.BillOfLading:
        convertedValue = 'Bill of Lading';
        break;
      case DocType.BillOfLadingAttachment:
        convertedValue = 'Bill Of Lading Attachment';
        break;
      case DocType.CheckSheet:
        convertedValue = 'Check Sheet';
        break;
      case DocType.ClaimForm:
        convertedValue = 'Claim Form';
        break;
      case DocType.ClaimNotes:
        convertedValue = 'Claim Notes';
        break;
      case DocType.ClaimsAttachment:
        convertedValue = 'Claims Attachment';
        break;
      case DocType.ClaimsProductionUnknown:
        convertedValue = 'Claims Production Unknown';
        break;
      case DocType.ClosedClaimFolder:
        convertedValue = 'Closed Claim Folder';
        break;
      case DocType.CustomsDocuments:
        convertedValue = 'Customs Document';
        break;
      case DocType.CustomsReleased:
        convertedValue = 'Customs Released';
        break;
      case DocType.CrossDockExceptionPhotos:
        convertedValue = 'Cross Dock Damage';
        break;
      case DocType.DeliveryExceptionPhoto:
        convertedValue = 'Delivery Exception Photo';
        break;
      case DocType.DeliveryReceipt:
        convertedValue = 'Delivery Receipt';
        break;
      case DocType.DeliveryReceiptAttachment:
        convertedValue = 'Delivery Receipt Attachment';
        break;
      case DocType.DeliveryReturnReceipt:
        convertedValue = 'Delivery Return Receipt';
        break;
      case DocType.CustomerProvidedDocuments:
        convertedValue = 'Customer Provided Document';
        break;
      case DocType.FlySheet:
        convertedValue = 'Fly Sheet';
        break;
      case DocType.InMotionDimensionerPhoto:
        convertedValue = 'In-Motion Dimensioner Photo';
        break;
      case DocType.InspectionReport:
        convertedValue = 'Inspection Report';
        break;
      case DocType.InspectionReportPhoto:
        convertedValue = 'Inspection Report Photo';
        break;
      case DocType.InvoicePdf:
        convertedValue = 'Invoice';
        break;
      case DocType.LetterOfAuthority:
        convertedValue = 'Letter of Authority';
        break;
      case DocType.MatchMailAcknowledgement:
        convertedValue = 'Match Mail Acknowledgment';
        break;
      case DocType.MobileDimensionerPhoto:
        convertedValue = 'Mobile Dimensioner Photo';
        break;
      case DocType.NmfcClassInspectionCertificate:
        convertedValue = 'NMFC Class Inspection Certificate';
        break;
      case DocType.OSDPhoto:
        convertedValue = 'OS&D Photo';
        break;
      case DocType.PoorlyPackagedOnDock:
        convertedValue = 'Poorly Packaged on Dock';
        break;
      case DocType.PickupPhotos:
        convertedValue = 'Pickup Photos';
        break;
      case DocType.PoorlyPackagedPickUp:
        convertedValue = 'Poorly Packaged at Pickup';
        break;
      case DocType.WeightInspectionCertificate:
        convertedValue = 'Weight Inspection Certificate';
        break;
      case DocType.WeightAndResearchPhotos:
        convertedValue = 'Weight and Research Photos';
        break;
      case DocType.TrailerPhotoOpen:
        convertedValue = 'Trailer Photo Open';
        break;
      case DocType.TrailerPhotoClose:
        convertedValue = 'Trailer Photo Close';
        break;
      case DocType.TrailerPhotoProgressive:
        convertedValue = 'Trailer Photo Progressive';
        break;
      case DocType.UnloadPhoto:
        convertedValue = 'Unload Photo';
        break;
      case DocType.All:
        convertedValue = 'All Documents';
        break;
    }
    return convertedValue;
  }

  transformBack(value: string, args?: any): any {
    let convertedValue: DocType;

    switch (value) {
      case 'Customer Provided Document':
        convertedValue = DocType.CustomerProvidedDocuments;
        break;
      case 'Bill of Lading':
        convertedValue = DocType.BillOfLading;
        break;
      case 'Delivery Receipt':
        convertedValue = DocType.DeliveryReceipt;
        break;
      case 'Delivery Return Receipt':
        convertedValue = DocType.DeliveryReturnReceipt;
        break;
      case 'Customs Document':
        convertedValue = DocType.CustomsDocuments;
        break;
      case 'Customs Released':
        convertedValue = DocType.CustomsReleased;
        break;
      case 'Inspection Report':
        convertedValue = DocType.InspectionReport;
        break;
      case 'Letter of Authority':
        convertedValue = DocType.LetterOfAuthority;
        break;
      case 'NMFC Class Inspection Certificate':
        convertedValue = DocType.NmfcClassInspectionCertificate;
        break;
      case 'Weight Inspection Certificate':
        convertedValue = DocType.WeightInspectionCertificate;
        break;
      case 'Weight and Research Photos':
        convertedValue = DocType.WeightAndResearchPhotos;
        break;
      case 'In-Motion Dimensioner Photo':
        convertedValue = DocType.InMotionDimensionerPhoto;
        break;
      case 'Invoice':
        convertedValue = DocType.InvoicePdf;
        break;
      case 'Claims Attachment':
        convertedValue = DocType.ClaimsAttachment;
        break;
      case 'DR Attachment':
        convertedValue = DocType.DeliveryReceiptAttachment;
        break;
      case 'BL Attachment':
        convertedValue = DocType.BillOfLadingAttachment;
        break;
      case 'OS&D Photo':
        convertedValue = DocType.OSDPhoto;
        break;
      case 'All Documents':
        convertedValue = DocType.All;
        break;
      case 'Unload Photo':
        convertedValue = DocType.UnloadPhoto;
        break;
    }
    return convertedValue;
  }

}
