import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DisputeReassignReasonCdHelper } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { FormUtils } from '../../../../classes/form-utils.class';
import { DialogResponse } from '../../../../enums';
import { ReassignDisputeFormNamesEnum } from '../../../../enums/form-control-names/reassign-dispute-form-names.enum';
import { ReassignDisputeFormLabelsEnum } from '../../../../enums/form-labels/reassign-dispute-form-labels.enum';
import { ReassignDisputeMaxLengths } from '../../../../enums/form-max-lengths/reassign-dispute-max-lengths.enum';
import { DialogResponseData } from '../../../../interfaces/dialog-response-data.interface';
import { ReassignDisputeData } from '../../../../interfaces/disputes/reassign-dispute-data';
import { ReassignDialogFormBuilder } from './reassign-dialog.form-builder';

@Component({
  selector: 'app-reassign-dialog',
  templateUrl: './reassign-dialog.component.html',
  styleUrls: ['./reassign-dialog.component.scss'],
})
export class ReassignDialogComponent implements OnInit {
  readonly ReassignDisputeFormLabelsEnum = ReassignDisputeFormLabelsEnum;
  readonly ReassignDisputeFormNamesEnum = ReassignDisputeFormNamesEnum;
  readonly ReassignDisputeMaxLengths = ReassignDisputeMaxLengths;
  formGroup: FormGroup;
  readonly reassignReasons = _.sortBy(DisputeReassignReasonCdHelper.values());

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ReassignDialogComponent>) {}

  ngOnInit() {
    this.initReassignForm();
  }

  initReassignForm() {
    this.formGroup = ReassignDialogFormBuilder.create(this.fb);
  }

  reassignDispute() {
    if (this.formGroup.valid) {
      const code = this.formGroup.get(ReassignDisputeFormNamesEnum.REASON_CODE);
      const reason = this.formGroup.get(ReassignDisputeFormNamesEnum.REASON_FOR_REASSIGNMENT);

      const dialogData: ReassignDisputeData = { reasonCode: code.value, reasonForReassignment: reason.value };

      const dialogResponseData: DialogResponseData<any> = {
        response: DialogResponse.REASSIGN,
        data: dialogData,
      };

      this.dialogRef.close(dialogResponseData);
    } else {
      FormUtils.markAsTouched(this.formGroup);
    }
  }

  cancel() {
    const dialogResponseData: DialogResponseData<any> = {
      response: DialogResponse.CANCEL,
    };
    this.dialogRef.close(dialogResponseData);
  }
}
