import { Party } from '@xpo-ltl/sdk-disputes';
import {
  XrtAttributeFilter,
  XrtDisputePartySearchFilter,
  XrtDisputePartySearchRqst,
  XrtSearchQueryHeader,
} from '@xpo-ltl/sdk-elasticsearch';

export enum partyName {
  name1 = 'name1',
  addr1 = 'addr1',
  cityName = 'cityName',
  stateCd = 'stateCd',
  postalCd = 'postalCd',
  countryCd = 'countryCd',
}

export class DisputePartySearchHelper {
  static getRequestForXrtDisputePartySearch(party: Party, pageNumber: number): XrtDisputePartySearchRqst {
    const request = new XrtDisputePartySearchRqst();
    const queryHeader = new XrtSearchQueryHeader();
    queryHeader.page = pageNumber;
    let searchFilter = new XrtDisputePartySearchFilter();
    searchFilter = this.addFilter(searchFilter, party, partyName.name1);
    searchFilter = this.addFilter(searchFilter, party, partyName.addr1);
    searchFilter = this.addFilter(searchFilter, party, partyName.cityName);
    searchFilter = this.addFilter(searchFilter, party, partyName.stateCd);
    searchFilter = this.addFilter(searchFilter, party, partyName.postalCd);
    searchFilter = this.addFilter(searchFilter, party, partyName.countryCd);
    request.header = queryHeader;
    request.filter = searchFilter;
    return request;
  }

  static addFilter(filter: XrtDisputePartySearchFilter, party: Party, partyName: string): XrtDisputePartySearchFilter {
    if (party && party[partyName]) {
      const nameFilter = new XrtAttributeFilter();
      nameFilter.startsWith = party[partyName];
      filter[partyName] = nameFilter;
    }
    return filter;
  }
}
