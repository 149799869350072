<h1 mat-dialog-title>
  {{ title }}
  <xpo-dialog-close-button
    (click)="handleCloseButtonClick($event)"
    [hidden]="hideCloseDialogButton"
  ></xpo-dialog-close-button>
</h1>
<div mat-dialog-content>
  <ng-content></ng-content>
</div>
<div mat-dialog-actions>
  <ng-container *ngFor="let button of buttons">
    <div class="buttons-container">
      <button
        *ngIf="button.displayText && button.buttonType !== 'FLAT'"
        mat-button
        color="primary"
        (click)="onButtonClick(button.eventKey)"
        data-test="secondaryDialogAction"
      >
        {{ button.displayText }}
      </button>
      <button
        *ngIf="button.displayText && button.buttonType === 'FLAT'"
        mat-flat-button
        (click)="onButtonClick(button.eventKey)"
        data-test="primaryDialogAction"
        [color]="button.color"
      >
        {{ button.displayText }}
      </button>
    </div>
  </ng-container>
</div>
