<div [formGroup]="formGroup" class="explanation">
  <h1 mat-dialog-title>
    {{ title }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div mat-dialog-content>
    <span class="full-width">
      {{ description }}
    </span>
    <mat-form-field class="full-width" floatLabel="always" data-test="reason">
      <mat-label>{{ explanationDialogFormLabelsEnum.REASON_CODE }}</mat-label>
      <mat-select #disputeType [formControlName]="explanationDialogFormNamesEnum.REASON_CODE" required>
        <mat-option *ngFor="let reason of reasons" [value]="reason">{{ reason | humanize }}</mat-option>
      </mat-select>
      <mat-error *ngIf="formGroup.get(explanationDialogFormNamesEnum.REASON_CODE).hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width" floatLabel="always" data-test="explanation">
      <mat-label>{{ explanationDialogFormLabelsEnum.REASON_EXPLANATION }}</mat-label>
      <textarea #explanation matInput [placeholder]="explanationDialogFormLabelsEnum.REASON_EXPLANATION"
        [formControlName]="explanationDialogFormNamesEnum.REASON_EXPLANATION" rows="10"></textarea>
      <mat-hint align="end">
        {{ explanation?.value?.length }} /
        {{ explanationDialogMaxLenghtsEnum.REASON_EXPLANATION }}
      </mat-hint>
      <mat-error>
        <div *ngIf="formGroup.get(explanationDialogFormNamesEnum.REASON_EXPLANATION).hasError('maxlength')">
          Must be less than {{ explanationDialogMaxLenghtsEnum.REASON_EXPLANATION }}
        </div>
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <div class="buttons-container">
      <button mat-button (click)="cancel()" data-test="cancel" color="primary">
        {{ explanationDialogFormLabelsEnum.CANCEL }}
      </button>
      <button mat-flat-button (click)="onSubmit()" [disabled]="loading" data-test="submit"
        [color]="buttonColor || 'primary'">
        {{ explanationDialogFormLabelsEnum.SUBMIT }}
      </button>
    </div>
  </div>
</div>
