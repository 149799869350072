<div [formGroup]="formGroup" class="notes-entry">
  <mat-form-field floatLabel="always" class="full-width" data-test="noteSubject">
    <mat-label>{{ NotesEntryLabelsEnum.SUBJECT }}</mat-label>
    <input
      type="text"
      matInput
      autocomplete="off"
      [maxlength]="NotesEntryMaxLengthsEnum.SUBJECT"
      [formControlName]="NotesEntryFormNamesEnum.SUBJECT"
      required
    />
    <mat-error>
      <div
        *ngIf="
          formGroup?.get(NotesEntryFormNamesEnum.SUBJECT)?.touched &&
          formGroup?.get(NotesEntryFormNamesEnum.SUBJECT)?.hasError('required')
        "
      >
        Required
      </div>
    </mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" class="full-width" data-test="noteType">
    <mat-select
      [formControlName]="NotesEntryFormNamesEnum.NOTE_TYPE"
      [placeholder]="NotesEntryLabelsEnum.NOTE_TYPE"
      required
    >
      <mat-option *ngFor="let noteType of noteTypes" [value]="noteType">
        {{ noteType | humanize }}
      </mat-option>
    </mat-select>
    <mat-error>
      <div
        *ngIf="
          formGroup?.get(NotesEntryFormNamesEnum.NOTE_TYPE)?.touched &&
          formGroup?.get(NotesEntryFormNamesEnum.NOTE_TYPE)?.hasError('required')
        "
      >
        Required
      </div>
    </mat-error>
  </mat-form-field>
  <div class="divider"></div>
  <div class="full-width" [ngClass]="{ 'input-error': showRequiredError || showContentExceededError }">
    <angular-editor [config]="editorConfig" class="notes__content-editor" [formControlName]="NotesEntryFormNamesEnum.CONTENT" data-test="noteEditor"></angular-editor>
  </div>
  <div class="notes-entry__content-hint">
    <mat-error class="notes-entry__text-error">
      <div *ngIf="showRequiredError">
        Required
      </div>
      <div *ngIf="showContentExceededError">
        The text entered exceeds the maximum length
      </div>
    </mat-error>
    <div class="notes-entry__content-length" [ngClass]="{ 'content-exceeded': showContentExceededError }">
      <span>{{ notesEntryLength }}/{{ NotesEntryMaxLengthsEnum.CONTENT }}</span>
    </div>
  </div>
</div>
