export enum InvoiceDetailsLabelNamesEnum {
  TITLE = 'PRO List',
  PRO_NUMBER = 'PRO #',
  RELATED_CLAIM = 'Related Claim ',
  INDICATORS = 'Indicators',
  PAYMENT_STATUS = 'Payment Status',
  INVOICE_AMOUNT = 'Invoice Amount',
  CURRENT_INVOICE_AMOUNT = 'Current Invoice Amount',
  INVOICED_AMOUNT = 'Invoiced Amount',
  REQUESTED_ADJUSTED_AMOUNT = 'Requested Adjustment Amount',
  TOTAL_REQUESTED_ADJUSTED_AMOUNT = 'Total Disputed Amount',
  REQUESTED_ADJUSTED_CURRENCY = 'Currency',
  PICKUP_DATE = 'Pickup Date',
  SHIPPER = 'Shipper',
  BILL_TO = 'Bill To',
  INVALID_PRO_TEXT = 'You need to enter a valid PRO number for adding it into the Dispute',
  EXISTING_PRO_TEXT = 'The PRO already exists in the Dispute',
  INVALID_REQUEST_AMOUNT_TEXT = 'You need to enter a valid amount.',
  REQUIRED_REQUEST_AMOUNT_TEXT = 'Requested Amount cannot be empty. Must be greater than 0.',
  REQUIRED_REQUEST_AMOUNT_IF_BLANK_TEXT = 'Requested Amount cannot be empty. Must be greater than or equal to 0.',
  EXCEEDED_REQUEST_AMOUNT_TEXT = 'You need to enter a valid amount.',
  LOADING_MESSAGE = 'Loading Pros ...',
  EXCEEDED_TOTAL_DISPUTED_AMOUNT_TEXT = 'Total Disputed Amount cannot exceed the Invoice Amount.',
}
