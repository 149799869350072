import { FormBuilder, Validators } from '@angular/forms';

import { SelectEmployeeDialogFormNamesEnum } from '../../../../enums';
import { NoWhitespaceValidator } from '../../../../validators/no-whitespace.validator';

export class SelectEmployeeDialogFormBuilder {
  static create(fb: FormBuilder) {
    return fb.group({
      [SelectEmployeeDialogFormNamesEnum.LAST_NAME]: ['', [Validators.required, NoWhitespaceValidator]],
      [SelectEmployeeDialogFormNamesEnum.FIRST_NAME]: [''],
    });
  }
}
