<div [formGroup]="formGroup">
    <h1 mat-dialog-title>
        {{ ReassignDisputeFormLabelsEnum.TITLE }}
        <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>

    <div mat-dialog-content>
        <p class="reassign-dispute__description">
            Please explain the reason for assignment.
        </p>
        <div class="reassign-dispute__row">
            <mat-form-field class="full-width" floatLabel="always" data-test="reasonCode">
                <mat-select #reassignReason [formControlName]="ReassignDisputeFormNamesEnum.REASON_CODE" placeholder="{{ ReassignDisputeFormLabelsEnum.REASON_CODE }}" required>
                    <mat-option *ngFor="let reassignReason of reassignReasons" [value]="reassignReason">
                        {{ reassignReason | humanize }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="reassign-dispute__row">
            <mat-form-field class="full-width" floatLabel="always" data-test="reasonForReassignment">
                <textarea #reasonForReassignment matInput maxlength="500" [formControlName]="ReassignDisputeFormNamesEnum.REASON_FOR_REASSIGNMENT" [placeholder]="ReassignDisputeFormLabelsEnum.REASON_FOR_REASSIGNMENT" rows="6"></textarea>
                <mat-hint>Optional</mat-hint>
                <mat-hint align="end">
                    {{ reasonForReassignment?.value?.length }} / {{ ReassignDisputeMaxLengths.REASON_FOR_REASSIGNMENT }}
                </mat-hint>
                <mat-error>
                    <div *ngIf="formGroup.get(ReassignDisputeFormNamesEnum.REASON_FOR_REASSIGNMENT).hasError('maxlength')">
                        Must be less than {{ ReassignDisputeMaxLengths.REASON_FOR_REASSIGNMENT }}
                    </div>
                </mat-error>
            </mat-form-field>
        </div>

        <div mat-dialog-actions reassign-dispute__>
            <button mat-stroked-button (click)="cancel()" data-test="cancel">Cancel</button>
            <button mat-flat-button (click)="reassignDispute()" data-test="reassign">Assign</button>
        </div>
    </div>
</div>
