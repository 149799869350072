import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Dispute, Shipment } from '@xpo-ltl/sdk-disputes';

import { AddressEntryFormNamesEnum, ButtonType, DialogEventKeysEnum, DialogResponse } from '../../../../enums';
import { DebtorDetailsFormNamesEnum } from '../../../../enums/form-control-names/debtor-details-form-names.enum';
import { BaseDisputeDialogButton } from '../../../../interfaces/base-dispute-dialog-button.interface';
import { DisputesDataService } from '../../../../services/disputes/disputes-data.service';
import { DrpDisputesRegistrationService } from './../../../../services/disputes/drp-disputes-registration.service';

@Component({
  selector: 'app-confirm-new-claims-dispute-dialog',
  templateUrl: './confirm-new-claims-dispute-dialog.component.html',
  styleUrls: ['./confirm-new-claims-dispute-dialog.component.scss'],
})
export class ConfirmNewClaimsDisputeDialogComponent implements OnInit {
  private _title: string;
  private _message: string;
  private _confirmText: string;
  private _cancelText: string;
  private _buttonColor: string;
  private _hideCloseDialogButton: boolean;
  private _dispute: Dispute;

  confirmDisputeDialogButtons: BaseDisputeDialogButton[];

  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get hideCloseDialogButton(): boolean {
    return this._hideCloseDialogButton;
  }

  get shipments(): Array<Shipment> {
    return Array.from(this.disputesDataService.shipments);
  }

  get dispute(): Dispute {
    return this._dispute;
  }

  get madCode() {
    let result = '';
    const addressEntryFormGroup = this.disputesRegistrationService.disputeFormGroup
      .get(DebtorDetailsFormNamesEnum.FORM_GROUP_NAME)
      .get(AddressEntryFormNamesEnum.FORM_GROUP_NAME);

    if (addressEntryFormGroup) {
      result = addressEntryFormGroup.get(AddressEntryFormNamesEnum.MAD_CODE).value;
    }

    return result;
  }

  get requestorName() {
    if (this.dispute.drpAenaeEmployeeDtl) {
      return `${this.dispute.drpAenaeEmployeeDtl.firstName} ${this.dispute.drpAenaeEmployeeDtl.lastName}`;
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogRef: MatDialogRef<ConfirmNewClaimsDisputeDialogComponent>,
    private disputesDataService: DisputesDataService,
    private disputesRegistrationService: DrpDisputesRegistrationService
  ) {
    this._title = dialogData.title;
    this._message = dialogData.message;
    this._confirmText = dialogData.confirmText;
    this._cancelText = dialogData.cancelText;
    this._buttonColor = dialogData.buttonColor;
    this._hideCloseDialogButton = dialogData.hideCloseDialogButton;
    this._dispute = this.disputesRegistrationService.createDispute();
  }

  ngOnInit() {
    this.confirmDisputeDialogButtons = [
      {
        displayText: this._cancelText,
        buttonType: ButtonType.STROKED,
        eventKey: DialogEventKeysEnum.GO_BACK,
        color: this._buttonColor,
      },
      {
        displayText: this._confirmText,
        buttonType: ButtonType.FLAT,
        eventKey: DialogEventKeysEnum.SUBMIT,
        color: this._buttonColor,
      },
    ];
  }

  handleClick(eventKey: DialogEventKeysEnum) {
    switch (eventKey) {
      case DialogEventKeysEnum.GO_BACK:
        this.handleGoBackClicked();
        break;
      case DialogEventKeysEnum.SUBMIT:
        this.handleSubmitClicked();
        break;
    }
  }

  handleCloseButtonClick() {
    this.dialogRef.close(DialogResponse.DIALOG_CLOSED);
  }

  private handleGoBackClicked() {
    this.dialogRef.close(DialogResponse.GO_BACK);
  }

  private handleSubmitClicked() {
    this.dialogRef.close(DialogResponse.SUBMIT);
  }
}
