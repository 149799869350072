import { ActionCd, Attachment, AuditInfo } from '@xpo-ltl/sdk-common';

/**
 * Attachments for a specific Email Interaction (incoming and outgoing).
 */
export class EmailAttachData {
  /**
   * Attachment for this email. Part of the identifier
   * Required: false
   * Array: false
   */
  emailInstId: number;
  /**
   * A sequential number identifying the email attachment that is on the Dispute email.
   * Required: false
   * Array: false
   */
  sequenceNbr: string;
  /**
   * The mime type of the email attachment.
   * Required: false
   * Array: false
   */
  attachMimeType: string;
  /**
   * User supplied name for the attachment.
   * Required: false
   * Array: false
   */
  attachName: string;
  /**
   * The URL for the imaged email attachment in DMS.
   * Required: false
   * Array: false
   */
  dmsUrl: string;
  /**
   * Same value for event id is assigned to various rows from different tables that form a single database transactions.
   * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
   * In other words, this groups multipple rows across various tables for a single database transaction.
   * Typically this is used to publish a message to a queue for consumption or a workflow.
   * Required: false
   * Array: false
   */
  dspEventId: number;
  /**
   * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.
   * In other words, this groups multipple rows across various tables for a single business transaction.
   * Required: false
   * Array: false
   */
  correlationId: string;
  /**
   * An update operation uses this to version check the record to ensure another transaction
   * has not updated the row after this transaction had read the row and is now about to update it.
   * Required: false
   * Array: false
   */
  recordVersionNbr: number;
  /**
   * Indicates whether to add, update or delete this entry. This will be used for merging lists.
   *
   * A - Add entry to list
   * D - Delete entry from list
   * U - Update entry
   * Required: false
   * Array: false
   */
  listActionCd: ActionCd;
  /**
   * Audit information such as create and/or update transaction details - who, when and which program/transaction
   * Required: false
   * Array: false
   */
  auditInfo: AuditInfo;
  /**
   * Base 64 data for the attachment.
   * Required: false
   * Array: false
   */
  attachmentDataTxt: Attachment;
}
