export enum InvoiceDetailsFieldNamesEnum {
  SELECT = 'select',
  PRO_NUMBER = 'proNbr',
  RELATED_CLAIM = 'claimId',
  PAYMENT_STATUS = 'paymentStatus',
  INVOICE_AMOUNT = 'invoiceAmountAtTimeOfDisputeCreation',
  CURRENT_INVOICE_AMOUNT = 'currentInvoiceAmount',
  REQUESTED_ADJUSTED_AMOUNT = 'requestedAdjustmentDeltaAmount',
  REQUESTED_ADJUSTED_AMOUNT_FORMATTED = 'requestedAdjustmentDeltaAmountFormatted',
  REQUESTED_ADJUSTED_CURRENCY = 'currencyCd',
  PICKUP_DATE = 'pickupDate',
  INDICATORS = 'indicators',
  SHIPPER = 'shipperName',
  BILL_TO = 'billToName',
  SELECT_ALL = 'SelectAll',
  DISPUTE_ID = 'disputeId',
}
