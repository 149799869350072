import { Injectable, Injector } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  ActionCd,
  Attachment,
  AuditInfo,
  DisputeDrpCategoryCd,
  DisputePartyTypeCd,
  DisputeStatusCd,
} from '@xpo-ltl/sdk-common';
import {
  AsEnteredParty,
  Contact,
  CreateDisputeCsvFileUploadRqst,
  CsvFileUpload,
  Dispute,
  DisputeDocument,
  EmployeeNotify,
  GetDisputeResp,
  InterfaceEmployee,
  ListDisputeShipmentsResp,
  Party,
  Shipment,
  UpdateDisputeRqst,
  UpsertDisputeRqst,
} from '@xpo-ltl/sdk-disputes';
import { HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';

import { BehaviorSubject } from 'rxjs';

import * as _ from 'lodash';

import { DateUtils } from '../../classes/date-utils.class';
import { DocumentUtils } from '../../classes/document-utils.class';
import { FormUtils } from '../../classes/form-utils.class';
import { RowShipment } from '../../classes/row-shipment';
import {
  AddressEntryFormNamesEnum,
  AuditCompanyInformationFormNamesEnum,
  ClaimantInformationFormNamesEnum,
  DisputeDetailsFormNamesEnum,
  DisputeFilingFormNamesEnum,
  DisputeInformationFormNamesEnum,
  DrpDetailsContentMinLengthsEnum,
  InterestedPartiesFormNamesEnum,
  InvoiceDetailsFormNamesEnum,
  ProListFormErrorLabelsEnum,
  UploadDocumentsFormNamesEnum,
} from '../../enums';
import { DebtorDetailsFormNamesEnum } from '../../enums/form-control-names/debtor-details-form-names.enum';
import { InterestedParty } from '../../interfaces/interested-party.interface';
import { HumanizePipe } from '../../pipes/humanize.pipe';
import { UserService } from '../users/user.service';

import { DisputeRequestReasonHelper } from '../../classes/disputes/dispute-request-reason-helper';
import { DisputesDataService } from './disputes-data.service';
import { DisputesFilingService } from './disputes-filing.service';
import { InvoiceDetailsDataSourceService } from './invoice-details-data-source.service';

@Injectable({
  providedIn: 'root',
})
export class DisputesRegistrationService {
  fb: FormBuilder;
  disputesFilingService: DisputesFilingService;
  shipmentDataSource: InvoiceDetailsDataSourceService;
  disputeDataService: DisputesDataService;
  protected userService: UserService;
  readonly humanize = new HumanizePipe();
  protected humanResourceApiService: HumanResourceApiService;
  auditDoNotMatch: boolean;
  claimantDoNotMatch: boolean;
  disputeFormGroup: FormGroup;
  disputeForm = [];

  protected get dispute(): GetDisputeResp {
    return this.disputeDataService.dispute;
  }

  get disputeId(): string {
    if (!this.dispute || !this.dispute.dispute) {
      return;
    }
    return this.dispute.dispute.disputeId;
  }

  protected get listDisputeShipments(): ListDisputeShipmentsResp {
    return this.disputeDataService.listDisputeShipments;
  }

  // #region Dispute Form Pristine

  disputeFormPristineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  disputeFormPristine$ = this.disputeFormPristineSubject.asObservable();
  get disputeFormPristine(): boolean {
    return this.disputeFormPristineSubject.value;
  }
  set disputeFormPristine(value: boolean) {
    this.disputeFormPristineSubject.next(value);
  }

  // #endregion

  constructor(protected injector: Injector) {
    this.fb = injector.get(FormBuilder);
    this.disputeDataService = injector.get(DisputesDataService);
    this.disputesFilingService = injector.get(DisputesFilingService);
    this.shipmentDataSource = injector.get(InvoiceDetailsDataSourceService);
    this.disputeFormGroup = this.fb.group({});
    this.userService = injector.get(UserService);
    this.humanResourceApiService = injector.get(HumanResourceApiService);
  }

  /**
   * Add/Update a formGroup to disputeFormGroup
   */
  addFormGroup(name: string, formGroup: FormGroup) {
    if (this.disputeFormGroup.get(name)) {
      this.disputeFormGroup.get(name).patchValue(formGroup.value);
    } else {
      this.disputeFormGroup.addControl(name, formGroup);
    }
  }

  /**
   * Get disputeFormGroup
   */
  getFormGroup(): FormGroup {
    return this.disputeFormGroup;
  }

  disputesRegistrationFormValidity(executeForceValidation: boolean = false): boolean {
    this.loadFormErrorList();
    if (executeForceValidation) {
      this.forceValidation();
    }
    return this.disputeFormGroup.valid;
  }

  forceValidation() {}

  updateDRPDetailValidators(usdTotalAmount: number) {
    const formGroup = this.disputeFormGroup.get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME);
    if (formGroup) {
      if (
        this.disputeDataService.drpCategory === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION &&
        (!this.dispute || (this.dispute && this.dispute.dispute.statusCd === DisputeStatusCd.DRAFT))
      ) {
        this.setRevenueDetailsAsNotRequired(formGroup);
      } else {
        if (usdTotalAmount >= 2000) {
          this.setRevenueDetailsAsRequired(formGroup);
        } else {
          this.setRevenueDetailsAsNotRequired(formGroup);
        }
      }

      // Revenue Details are required with Claims integration
      if (DisputeRequestReasonHelper.isValidForClaimIntegration(this.disputeDataService.requestReasonSelected)) {
        this.setRevenueDetailsAsRequired(formGroup);
      }

      formGroup.get(DisputeDetailsFormNamesEnum.OPERATING_RATIO).updateValueAndValidity();
      formGroup.get(DisputeDetailsFormNamesEnum.CURRENCY).updateValueAndValidity();
      formGroup.get(DisputeDetailsFormNamesEnum.MONTHLY_REVENUE_POTENTIAL).updateValueAndValidity();
      formGroup.get(DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE).updateValueAndValidity();
    }
  }

  private setRevenueDetailsAsRequired(formGroup: AbstractControl) {
    formGroup
      .get(DisputeDetailsFormNamesEnum.OPERATING_RATIO)
      .setValidators([Validators.required, Validators.min(DrpDetailsContentMinLengthsEnum.OPERATING_RATIO)]);
    formGroup.get(DisputeDetailsFormNamesEnum.CURRENCY).setValidators(Validators.required);
    formGroup
      .get(DisputeDetailsFormNamesEnum.MONTHLY_REVENUE_POTENTIAL)
      .setValidators([Validators.required, Validators.min(DrpDetailsContentMinLengthsEnum.MONTHLY_REVENUE_POTENTIAL)]);
    formGroup
      .get(DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE)
      .setValidators([Validators.required, Validators.min(DrpDetailsContentMinLengthsEnum.AVERAGE_MONTHLY_REVENUE)]);
  }

  private setRevenueDetailsAsNotRequired(formGroup: AbstractControl) {
    formGroup
      .get(DisputeDetailsFormNamesEnum.OPERATING_RATIO)
      .setValidators(Validators.min(DrpDetailsContentMinLengthsEnum.OPERATING_RATIO));
    formGroup.get(DisputeDetailsFormNamesEnum.CURRENCY).setValidators((control) => null);
    formGroup
      .get(DisputeDetailsFormNamesEnum.MONTHLY_REVENUE_POTENTIAL)
      .setValidators(Validators.min(DrpDetailsContentMinLengthsEnum.MONTHLY_REVENUE_POTENTIAL));
    formGroup
      .get(DisputeDetailsFormNamesEnum.AVERAGE_MONTHLY_REVENUE)
      .setValidators(Validators.min(DrpDetailsContentMinLengthsEnum.AVERAGE_MONTHLY_REVENUE));
  }

  loadFormErrorList() {
    this.disputeDataService.formErrors = this.findInvalidControls(this.disputeFormGroup);
  }

  clearFormErrorList() {
    this.disputeDataService.formErrorsSubject.next([]);
  }

  findInvalidControls = (group) => {
    const invalid = [];
    const controls = group.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        const errors = this.findInvalidControls(controls[name]);
        if (!!errors) {
          invalid.push({
            name: this.sanitizeName(name),
            title: this.humanize.transform(this.sanitizeName(name)),
            description: this.getErrorDescription(controls[name]),
            // linkText: name === 'Shipments' ? 'Download Error Log' : 'View Error', Changed requested on LSS-1223
            linkText: 'View Error',
            errors: errors,
          });
        }
      }
    }
    return !!invalid.length ? invalid : [];
  }

  sanitizeName(name) {
    switch (name) {
      case 'Address1':
        return 'Mailing Address Line 1';
      case 'Address2':
        return 'Mailing Address Line 2';
      case 'Shipments':
        return 'PRO List';
      default:
        return name;
    }
  }

  getErrorDescription(control) {
    let error = '';
    if (control.hasError('required')) {
      error = 'Required';
    } else if (control.hasError('maxlength')) {
      error = `Must be less than ${control.errors.maxlength.requiredLength}, actually ${control.errors.maxlength.actualLength}`;
    } else if (control.hasError('email')) {
      error = 'Invalid format';
    } else if (control.hasError('matDatepickerParse')) {
      error = 'Invalid Date';
    } else if (control.hasError('xpoMaxDate')) {
      error = 'Cannot be a future date';
    } else if (control.hasError('relatedClaimNotFound')) {
      error = ProListFormErrorLabelsEnum.RelatedClaimNotFound;
    } else if (control.hasError('relatedClaimNotApproved')) {
      error = ProListFormErrorLabelsEnum.RelatedClaimNotApproved;
    } else if (control.hasError('multipleRelatedClaims')) {
      error = ProListFormErrorLabelsEnum.MultipleRelatedClaims;
    } else if (control.hasError('relatedClaimWithPendingRebuttal')) {
      error = ProListFormErrorLabelsEnum.RelatedClaimWithPendingRebuttal;
    }

    return error;
  }

  disputesRegistrationAsDraftFormValidity(): boolean {
    const headerFormToValida =
      this.disputeFormGroup.get(DisputeFilingFormNamesEnum.FORM_GROUP_NAME) ||
      this.disputeFormGroup.get(DisputeInformationFormNamesEnum.FORM_GROUP_NAME);
    return headerFormToValida ? headerFormToValida.valid : true;
  }

  isFormDirty(): boolean {
    const eachForm = (formType) => {
      return this.disputeFormGroup.get(formType) ? `${this.disputeFormGroup.get(formType).dirty}` : 'false';
    };
    const isFormDirty = [
      DisputeFilingFormNamesEnum.FORM_GROUP_NAME,
      AddressEntryFormNamesEnum.FORM_GROUP_NAME,
      DisputeDetailsFormNamesEnum.FORM_GROUP_NAME,
      DisputeInformationFormNamesEnum.FORM_GROUP_NAME,
      ClaimantInformationFormNamesEnum.FORM_GROUP_NAME,
    ].map((each) => eachForm(each));
    return isFormDirty.includes('true');
  }

  createUpsertDisputesReq(disputeStatus: DisputeStatusCd): UpsertDisputeRqst {
    let isFormValid = false;
    if (disputeStatus === DisputeStatusCd.DRAFT) {
      isFormValid = this.disputesRegistrationAsDraftFormValidity();
    } else {
      isFormValid = this.disputesRegistrationFormValidity();
    }
    const req: UpsertDisputeRqst = new UpsertDisputeRqst();
    if (isFormValid) {
      req.dispute = this.createDispute();
      req.shipments = this.createShipmentForUpsert();
      req.asEnteredParty = this.createAsEnteredParty();
      req.employeeNotify = this.createEmployeeNotifyForUpsert();
      req.onBehalfOf = this.createOnBehalfOf();
      req.onBehalfOfContact = this.createOnBehalfOfContact();
      req.claimant = this.createClaimant();
      req.claimantContact = this.createClaimantContact();
      req.itemInstId = this.getItemInstId();
      req.disputeDocuments = this.createDisputeDocuments();
      return req;
    } else {
      throw new Error('Invalid Form');
    }
  }

  getItemInstId(): number {
    if (!this.dispute || !this.dispute.dispute || !this.dispute.dispute.disputeId) {
      return 0;
    }
    return this.dispute.itemInstId;
  }

  createUpdateDisputeRqst(): UpdateDisputeRqst {
    const req: UpdateDisputeRqst = new UpdateDisputeRqst();
    let isFormValid = false;
    const disputeStatus = this.dispute.dispute.statusCd;
    if (disputeStatus === DisputeStatusCd.DRAFT) {
      isFormValid = this.disputesRegistrationAsDraftFormValidity();
    } else {
      isFormValid = this.disputesRegistrationFormValidity();
    }
    if (isFormValid) {
      req.dispute = this.createDispute();
      req.dispute.statusCd = this.dispute.dispute.statusCd;
      req.dispute.assignedToEmployeeId = this.dispute.dispute.assignedToEmployeeId;
      return req;
    } else {
      throw new Error('Invalid Form');
    }
  }

  private asEnteredPartyEquals(party: AsEnteredParty, anotherParty: AsEnteredParty): boolean {
    return (
      party.name2 === anotherParty.name2 &&
      party.addr1 === anotherParty.addr1 &&
      party.addr2 === anotherParty.addr2 &&
      party.cityName === anotherParty.cityName &&
      party.stateCd === anotherParty.stateCd &&
      party.postalCd === anotherParty.postalCd &&
      party.countryCd === anotherParty.countryCd
    );
  }

  createAsEnteredParty(): Array<AsEnteredParty> {
    const asEnteredPartyList = new Array<AsEnteredParty>();

    let newClaimantAsEnteredParty = this.disputeDataService.claimantAsEnteredParty;
    let newOnBeHalfOfParty = this.disputeDataService.onBeHalfOfAsEnteredParty;

    if (!newClaimantAsEnteredParty) {
      // if did not match, load the asEntered party with the form data
      newClaimantAsEnteredParty = this.asEnteredPartyFromFormGroup(ClaimantInformationFormNamesEnum.FORM_GROUP_NAME);
      newClaimantAsEnteredParty.partyTypeCd = DisputePartyTypeCd.CLAIMANT;
    }

    if (!newOnBeHalfOfParty) {
      // if did not match, load the asEntered party with the form data
      newOnBeHalfOfParty = this.asEnteredPartyFromFormGroup(AuditCompanyInformationFormNamesEnum.FORM_GROUP_NAME);
      newOnBeHalfOfParty.partyTypeCd = DisputePartyTypeCd.ON_BEHALF_OF;
    }

    // TODO: use the SAME_AS value when the API be saving the value on the database
    // const isSameAsClaimant = onBeHalfOfFormGroup.get(AuditCompanyInformationFormNamesEnum.SAME_AS).value;
    const isSameAsClaimant = this.asEnteredPartyEquals(newClaimantAsEnteredParty, newOnBeHalfOfParty);

    if (this.dispute) {
      // Is update

      const claimantAsEnteredPartyIndex = this.dispute.asEnteredParty.findIndex(
        (party) => party.partyTypeCd === DisputePartyTypeCd.CLAIMANT
      );
      const asEnteredPartyForOnBeHalfOfIndex = this.dispute.asEnteredParty.findIndex(
        (party) => party.partyTypeCd === DisputePartyTypeCd.ON_BEHALF_OF
      );

      const currentClaimantAsEnteredParty =
        claimantAsEnteredPartyIndex >= 0 ? this.dispute.asEnteredParty[claimantAsEnteredPartyIndex] : undefined;

      const currentAsEnteredPartyForOnBeHalfOf =
        asEnteredPartyForOnBeHalfOfIndex >= 0
          ? this.dispute.asEnteredParty[asEnteredPartyForOnBeHalfOfIndex]
          : undefined;

      const claimantEquals = currentClaimantAsEnteredParty
        ? this.asEnteredPartyEquals(currentClaimantAsEnteredParty, newClaimantAsEnteredParty)
        : false;

      const onBeHalfOfEquals = currentAsEnteredPartyForOnBeHalfOf
        ? this.asEnteredPartyEquals(currentAsEnteredPartyForOnBeHalfOf, newOnBeHalfOfParty)
        : false;

      if (!currentClaimantAsEnteredParty) {
        newClaimantAsEnteredParty.disputeId = this.dispute.dispute.disputeId;
        newClaimantAsEnteredParty.recordVersionNbr = undefined;
        newClaimantAsEnteredParty.listActionCd = ActionCd.ADD;
        asEnteredPartyList.push(newClaimantAsEnteredParty);
      } else if (currentClaimantAsEnteredParty && claimantEquals) {
        currentClaimantAsEnteredParty.disputeId = this.dispute.dispute.disputeId;
        currentClaimantAsEnteredParty.listActionCd = ActionCd.NO_ACTION;
        asEnteredPartyList.push(currentClaimantAsEnteredParty);
      } else if (currentClaimantAsEnteredParty && !claimantEquals) {
        newClaimantAsEnteredParty.disputeId = this.dispute.dispute.disputeId;
        newClaimantAsEnteredParty.recordVersionNbr = currentClaimantAsEnteredParty.recordVersionNbr;
        newClaimantAsEnteredParty.listActionCd = ActionCd.UPDATE;
        asEnteredPartyList.push(newClaimantAsEnteredParty);
      }

      if (!currentAsEnteredPartyForOnBeHalfOf && !isSameAsClaimant) {
        newOnBeHalfOfParty.disputeId = this.dispute.dispute.disputeId;
        newOnBeHalfOfParty.recordVersionNbr = undefined;
        newOnBeHalfOfParty.listActionCd = ActionCd.ADD;
        asEnteredPartyList.push(newOnBeHalfOfParty);
      } else if (currentAsEnteredPartyForOnBeHalfOf && isSameAsClaimant) {
        currentAsEnteredPartyForOnBeHalfOf.disputeId = this.dispute.dispute.disputeId;
        currentAsEnteredPartyForOnBeHalfOf.listActionCd = ActionCd.DELETE;
        asEnteredPartyList.push(currentAsEnteredPartyForOnBeHalfOf);
      } else if (currentAsEnteredPartyForOnBeHalfOf && !isSameAsClaimant && onBeHalfOfEquals) {
        currentAsEnteredPartyForOnBeHalfOf.disputeId = this.dispute.dispute.disputeId;
        currentAsEnteredPartyForOnBeHalfOf.listActionCd = ActionCd.NO_ACTION;
        asEnteredPartyList.push(currentAsEnteredPartyForOnBeHalfOf);
      } else if (currentAsEnteredPartyForOnBeHalfOf && !isSameAsClaimant && !onBeHalfOfEquals) {
        newOnBeHalfOfParty.disputeId = this.dispute.dispute.disputeId;
        newOnBeHalfOfParty.recordVersionNbr = currentAsEnteredPartyForOnBeHalfOf.recordVersionNbr;
        newOnBeHalfOfParty.listActionCd = ActionCd.UPDATE;
        asEnteredPartyList.push(newOnBeHalfOfParty);
      }
    } else {
      // Is Create
      newClaimantAsEnteredParty.listActionCd = ActionCd.ADD;
      newClaimantAsEnteredParty.recordVersionNbr = undefined;
      asEnteredPartyList.push(newClaimantAsEnteredParty);

      newOnBeHalfOfParty.listActionCd = ActionCd.ADD;
      newOnBeHalfOfParty.recordVersionNbr = undefined;
      asEnteredPartyList.push(newOnBeHalfOfParty);
    }
    return asEnteredPartyList;
  }

  private asEnteredPartyFromFormGroup(formName: string) {
    const asEnteredParty = new AsEnteredParty();
    const formGroup = this.disputeFormGroup.get(formName);
    const addressEntryForm = formGroup.get(AddressEntryFormNamesEnum.FORM_GROUP_NAME);

    asEnteredParty.name1 = addressEntryForm.get(AddressEntryFormNamesEnum.NAME).value;
    asEnteredParty.addr1 = addressEntryForm.get(AddressEntryFormNamesEnum.MAILING_ADDRESS_1).value;
    asEnteredParty.addr2 = addressEntryForm.get(AddressEntryFormNamesEnum.MAILING_ADDRESS_2).value;
    asEnteredParty.cityName = addressEntryForm.get(AddressEntryFormNamesEnum.CITY).value;
    asEnteredParty.stateCd = addressEntryForm.get(AddressEntryFormNamesEnum.PROVINCE_STATE).value;
    asEnteredParty.postalCd = addressEntryForm.get(AddressEntryFormNamesEnum.ZIP_CODE).value;
    asEnteredParty.countryCd = addressEntryForm.get(AddressEntryFormNamesEnum.COUNTRY).value;

    return asEnteredParty;
  }

  /**
   * EmployeeNotify for upsert
   * Shoud add new items and remove.
   * If there is not changes, nothing should be send.
   */
  createEmployeeNotifyForUpsert(): EmployeeNotify[] {
    let parties = [];
    const formArray: FormArray = <FormArray>(
      this.disputeFormGroup
        .get(InterestedPartiesFormNamesEnum.FORM_GROUP_NAME)
        .get(InterestedPartiesFormNamesEnum.PARTIES)
    );

    const partiesInList: EmployeeNotify[] = formArray.controls
      .map((control) => control.value)
      .map((interestedParty: InterestedParty) => {
        const employeeNotify = new EmployeeNotify();
        employeeNotify.employeeId = interestedParty.employeeId;
        employeeNotify.listActionCd = ActionCd.ADD;
        employeeNotify.disputeId =
          this.dispute && this.dispute.dispute.disputeId ? this.dispute.dispute.disputeId : undefined;
        // enteredParty.
        return employeeNotify;
      });

    // Is update
    if (this.dispute) {
      // Remove
      this.dispute.employeeNotify.forEach((existingEmployee) => {
        if (!partiesInList.some((party) => existingEmployee.employeeId === party.employeeId)) {
          existingEmployee.listActionCd = ActionCd.DELETE;
          parties.push(existingEmployee);
        }
      });
      // Add
      partiesInList.forEach((shipment) => {
        const exist = this.dispute.employeeNotify.some(
          (existingEmployee) => existingEmployee.employeeId === shipment.employeeId
        );
        if (!exist) {
          parties.push(shipment);
        }
      });
    } else {
      // Is create
      parties = partiesInList;
    }
    return parties;
  }

  /**
   * EmployeeNotify for update
   * Should add new items and remove.
   * If there is not changes, nothing should be send.
   */
  createEmployeeNotifyForUpdate(): { add: EmployeeNotify[]; remove: EmployeeNotify[] } {
    const parties = { add: [], remove: [] };
    const formArray: FormArray = <FormArray>(
      this.disputeFormGroup
        .get(InterestedPartiesFormNamesEnum.FORM_GROUP_NAME)
        .get(InterestedPartiesFormNamesEnum.PARTIES)
    );

    const partiesInList: EmployeeNotify[] = formArray.controls
      .map((control) => control.value)
      .map((interestedParty: InterestedParty) => {
        const employeeNotify = new EmployeeNotify();
        employeeNotify.employeeId = interestedParty.employeeId;
        employeeNotify.listActionCd = ActionCd.ADD;
        employeeNotify.employeeDtl = new InterfaceEmployee();
        employeeNotify.employeeDtl.firstName = interestedParty.firstName;
        employeeNotify.employeeDtl.lastName = interestedParty.lastName;
        employeeNotify.employeeDtl.employeeId = interestedParty.employeeId;
        employeeNotify.disputeId =
          this.dispute && this.dispute.dispute.disputeId ? this.dispute.dispute.disputeId : undefined;
        // enteredParty.
        return employeeNotify;
      });

    // Is update
    if (this.dispute) {
      // Remove
      this.dispute.employeeNotify.forEach((existingEmployee) => {
        if (!partiesInList.some((party) => existingEmployee.employeeId === party.employeeId)) {
          existingEmployee.listActionCd = ActionCd.DELETE;
          parties.remove.push(existingEmployee);
        }
      });
      // Add
      partiesInList.forEach((employee) => {
        const exist = this.dispute.employeeNotify.some(
          (existingEmployee) => existingEmployee.employeeId === employee.employeeId
        );
        if (!exist) {
          parties.add.push(employee);
        }
      });
    } else {
      // Is create
      parties.add = partiesInList;
    }

    return parties;
  }

  createCsvFileUploads(): CreateDisputeCsvFileUploadRqst {
    const form = this.disputeFormGroup
      .get(InvoiceDetailsFormNamesEnum.FORM_GROUP_NAME)
      .get(InvoiceDetailsFormNamesEnum.IMPORT_PROS);

    const file = new CsvFileUpload();
    const base64 = form.get(InvoiceDetailsFormNamesEnum.FILE_BASE_64).value;
    file.uploadFileContents = base64.substr(base64.lastIndexOf('base64') + 7);
    file.csvFileName = form.get(InvoiceDetailsFormNamesEnum.FILE_NAME).value;
    file.listActionCd = ActionCd.ADD;

    const result = new CreateDisputeCsvFileUploadRqst();
    result.disputeId = form.get(InvoiceDetailsFormNamesEnum.DISPUTE_ID).value;
    result.csvFileUpload = file;

    return result;
  }

  createContact(
    formGroupName:
      | ClaimantInformationFormNamesEnum.FORM_GROUP_NAME
      | AuditCompanyInformationFormNamesEnum.FORM_GROUP_NAME
      | DebtorDetailsFormNamesEnum.FORM_GROUP_NAME
      | DebtorDetailsFormNamesEnum.SECONDARY_FORM_GROUP_NAME
  ) {
    if (!formGroupName) {
      return;
    }
    let contact;
    const form = this.disputeFormGroup.get(formGroupName).get(AddressEntryFormNamesEnum.FORM_GROUP_NAME);

    const firstName = form.get(AddressEntryFormNamesEnum.CONTACT_FIRST_NAME).value;
    const lastName = form.get(AddressEntryFormNamesEnum.CONTACT_LAST_NAME).value;
    const emailAddress = form.get(AddressEntryFormNamesEnum.CONTACT_EMAIL_ADDRESS).value;
    const phoneNbr = form.get(AddressEntryFormNamesEnum.CONTACT_PHONE_NUMBER).value;
    const faxNbr = form.get(AddressEntryFormNamesEnum.CONTACT_FAX_NUMBER).value;
    const phoneExt = form.get(AddressEntryFormNamesEnum.CONTACT_PHONE_EXT_NUMBER).value;
    const listActionCd = ActionCd.ADD;
    const correlationId = '123456';
    const dspEventId = 1;
    const cisCustomerNbr = form.get(AddressEntryFormNamesEnum.CUSTOMER_NUMBER).value;
    const cntctId = form.get(AddressEntryFormNamesEnum.CONTACT_ID).value;

    if (firstName || lastName || emailAddress || phoneNbr || faxNbr || phoneExt || cntctId) {
      contact = {
        firstName,
        lastName,
        emailAddress,
        phoneNbr,
        faxNbr,
        phoneExt,
        listActionCd,
        correlationId,
        dspEventId,
        cisCustomerNbr,
        cntctId,
      } as Contact;
    }

    return contact;
  }

  createClaimantContact(): Contact {
    const contact = this.createContact(ClaimantInformationFormNamesEnum.FORM_GROUP_NAME);
    if (contact) {
      contact.recordVersionNbr =
        this.dispute && this.dispute.claimantContact ? this.dispute.claimantContact.recordVersionNbr : undefined;
    }
    return contact;
  }

  createOnBehalfOfContact(): Contact {
    const contact = this.createContact(AuditCompanyInformationFormNamesEnum.FORM_GROUP_NAME);
    if (contact) {
      contact.recordVersionNbr =
        this.dispute && this.dispute.onBehalfOfContact ? this.dispute.onBehalfOfContact.recordVersionNbr : undefined;
    }
    return contact;
  }

  private createOCCPartyFromAddressEntryForm(addressEntryForm: AbstractControl) {
    const party = new Party();

    party.dspPartyId = addressEntryForm.get(AddressEntryFormNamesEnum.MATCH_PARTY_ID).value;
    party.name1 = addressEntryForm.get(AddressEntryFormNamesEnum.NAME).value;
    party.addr1 = addressEntryForm.get(AddressEntryFormNamesEnum.MAILING_ADDRESS_1).value;
    party.addr2 = addressEntryForm.get(AddressEntryFormNamesEnum.MAILING_ADDRESS_2).value;
    party.cityName = addressEntryForm.get(AddressEntryFormNamesEnum.CITY).value;
    party.stateCd = addressEntryForm.get(AddressEntryFormNamesEnum.PROVINCE_STATE).value;
    party.postalCd = addressEntryForm.get(AddressEntryFormNamesEnum.ZIP_CODE).value;
    party.countryCd = addressEntryForm.get(AddressEntryFormNamesEnum.COUNTRY).value;
    party.cisCustomerNbr = addressEntryForm.get(AddressEntryFormNamesEnum.CUSTOMER_NUMBER).value;

    // Dummy data
    party.postalCdExt = '';
    party.longitudeNbr = 0;
    party.name2 = '';
    party.autoAprvlExcludeInd = false;

    return party;
  }

  createOnBehalfOf(): Party {
    const onBeHalfOfForm = this.disputeFormGroup.get(AuditCompanyInformationFormNamesEnum.FORM_GROUP_NAME);
    const addressEntryForm = onBeHalfOfForm.get(AddressEntryFormNamesEnum.FORM_GROUP_NAME);
    const onBeHalfOf: Party = this.createOCCPartyFromAddressEntryForm(addressEntryForm);

    onBeHalfOf.doNotMatchInd = onBeHalfOfForm.get(AuditCompanyInformationFormNamesEnum.DO_NOT_MATCH_IND).value;
    onBeHalfOf.recordVersionNbr =
      this.dispute && this.dispute.onBehalfOf ? this.dispute.onBehalfOf.recordVersionNbr : undefined;

    return onBeHalfOf;
  }

  createDocument(control: AbstractControl, action: ActionCd) {
    const document: DisputeDocument = new DisputeDocument();
    document.disputeId = this.dispute && this.dispute.dispute.disputeId ? this.dispute.dispute.disputeId : undefined;
    document.auditInfo = this.getDocumentAuditInfo();
    document.recordVersionNbr = control.get(UploadDocumentsFormNamesEnum.RECORD_VERSION_NBR).value;
    document.listActionCd = action;
    document.attachName = control.get(UploadDocumentsFormNamesEnum.ATTACH_NAME).value;
    document.dspEventId = control.get(UploadDocumentsFormNamesEnum.DSP_EVENT_ID).value;
    document.correlationId = control.get(UploadDocumentsFormNamesEnum.CORRELATION_ID).value;
    document.seqNbr = control.get(UploadDocumentsFormNamesEnum.SEQ_NBR).value;
    document.dmsUrl = '';
    const attachmentDataTxtForm = control.get(UploadDocumentsFormNamesEnum.ATTACHMENT_DATA_TXT).value;
    document.attachMimeTypCd = control.get(UploadDocumentsFormNamesEnum.ATTACH_MIME_TYP_CD).value;
    document.attachmentDataTxt = new Attachment();
    document.attachmentDataTxt.fileName = attachmentDataTxtForm.get(
      UploadDocumentsFormNamesEnum.ATTACHMENT_DATA_TXT_FILE_NAME
    ).value;
    document.attachmentDataTxt.contentType = attachmentDataTxtForm.get(
      UploadDocumentsFormNamesEnum.ATTACHMENT_DATA_TXT_CONTENT_TYPE
    ).value;

    if (!document.attachmentDataTxt.contentType) {
      document.attachmentDataTxt.contentType = this.getContentTypeFromFileName(document.attachmentDataTxt.fileName);
    }

    document.attachmentDataTxt.base64Data = attachmentDataTxtForm.get(
      UploadDocumentsFormNamesEnum.ATTACHMENT_DATA_TXT_BASE_64_DATA
    ).value;

    return document;
  }

  createDisputeDocuments(disputeId: string = ''): DisputeDocument[] {
    const documents: DisputeDocument[] = [];

    const form = this.disputeFormGroup
      .get(UploadDocumentsFormNamesEnum.FORM_GROUP_NAME)
      .get(UploadDocumentsFormNamesEnum.FILE_LIST) as FormArray;

    if (form.controls.length > 0) {
      form.controls.forEach((control) => {
        if (!control.get(UploadDocumentsFormNamesEnum.SEQ_NBR).value) {
          const document: DisputeDocument = this.createDocument(control, ActionCd.ADD);
          if (disputeId) {
            document.disputeId = disputeId;
          }
          documents.push(document);
        }
      });
    }

    const formToDelete = this.disputeFormGroup
      .get(UploadDocumentsFormNamesEnum.FORM_GROUP_NAME)
      .get(UploadDocumentsFormNamesEnum.FILE_TO_DELETE_LIST) as FormArray;

    if (formToDelete.controls.length > 0) {
      formToDelete.controls.forEach((control) => {
        if (control.get(UploadDocumentsFormNamesEnum.SEQ_NBR).value) {
          const document: DisputeDocument = this.createDocument(control, ActionCd.DELETE);
          documents.push(document);
        }
      });
    }

    return documents;
  }

  private getContentTypeFromFileName(fileName: string): string {
    const extension = fileName.match(/[^.]+$/);
    return DocumentUtils.getContentTypeByExtension(extension.toString());
  }

  protected getDocumentAuditInfo(): AuditInfo {
    const auditInfo = new AuditInfo();
    const result: AuditInfo = {
      ...auditInfo,
      createdById: 'string',
      createdTimestamp: new Date(),
      updateById: 'string',
      updatedTimestamp: new Date(),
      createByPgmId: 'string',
      updateByPgmId: 'string',
      correlationId: 'string',
    };
    return result;
  }

  createClaimant(): Party {
    const claimantForm = this.disputeFormGroup.get(ClaimantInformationFormNamesEnum.FORM_GROUP_NAME);
    const addressEntryForm = claimantForm.get(AddressEntryFormNamesEnum.FORM_GROUP_NAME);
    const claimant: Party = this.createOCCPartyFromAddressEntryForm(addressEntryForm);

    claimant.doNotMatchInd = claimantForm.get(ClaimantInformationFormNamesEnum.DO_NOT_MATCH_IND).value;
    claimant.recordVersionNbr =
      this.dispute && this.dispute.claimant ? this.dispute.claimant.recordVersionNbr : undefined;

    return claimant;
  }

  protected createDispute(): Dispute {
    const dispute: Dispute = new Dispute();
    const DisputeDetailsForm = this.disputeFormGroup.get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME);
    if (this.dispute && this.dispute.dispute.statusCd !== DisputeStatusCd.DRAFT) {
      const DisputeInformationForm = this.disputeFormGroup.get(DisputeInformationFormNamesEnum.FORM_GROUP_NAME);
      dispute.disputeId = this.dispute ? this.dispute.dispute.disputeId : undefined;
      const filingDate =
        DisputeInformationForm.get(DisputeInformationFormNamesEnum.DISPUTE_FILING_DATE).value || new Date();
      dispute.filingDate = DateUtils.getFormatDate(filingDate, 'YYYY-MM-DD');
      dispute.typeCd = DisputeInformationForm.get(DisputeInformationFormNamesEnum.DISPUTE_TYPE).value;
      dispute.filedByEmployeeId = DisputeInformationForm.get(DisputeInformationFormNamesEnum.FILED_BY).value;
      dispute.filingMethodCd = DisputeInformationForm.get(DisputeInformationFormNamesEnum.FILE_METHOD).value;
      dispute.customerRefNbr = DisputeInformationForm.get(
        DisputeInformationFormNamesEnum.CUSTOMER_REFERENCE_NUMBER
      ).value;
      const receivedDate =
        DisputeInformationForm.get(DisputeFilingFormNamesEnum.DISPUTE_RECEIVED_DATE).value || new Date();
      dispute.receivedDate = DateUtils.getFormatDate(receivedDate, 'YYYY-MM-DD');
    } else {
      const DisputeFilingForm = this.disputeFormGroup.get(DisputeFilingFormNamesEnum.FORM_GROUP_NAME);
      dispute.disputeId = this.dispute ? this.dispute.dispute.disputeId : undefined;
      const filingDate = DisputeFilingForm.get(DisputeFilingFormNamesEnum.DISPUTE_FILING_DATE).value || new Date();
      dispute.filingDate = DateUtils.getFormatDate(filingDate, 'YYYY-MM-DD');
      dispute.typeCd = DisputeFilingForm.get(DisputeFilingFormNamesEnum.DISPUTE_TYPE).value;
      dispute.filedByEmployeeId = DisputeFilingForm.get(DisputeFilingFormNamesEnum.FILED_BY).value;
      dispute.filingMethodCd = DisputeFilingForm.get(DisputeFilingFormNamesEnum.FILE_METHOD).value;
      dispute.customerRefNbr = DisputeFilingForm.get(DisputeFilingFormNamesEnum.CUSTOMER_REFERENCE_NUMBER).value;
      const receivedDate = DisputeFilingForm.get(DisputeFilingFormNamesEnum.DISPUTE_RECEIVED_DATE).value || new Date();
      dispute.receivedDate = DateUtils.getFormatDate(receivedDate, 'YYYY-MM-DD');
    }
    dispute.requestReasonCd = DisputeDetailsForm.get(DisputeDetailsFormNamesEnum.REQUEST_REASON).value || undefined;
    dispute.description = DisputeDetailsForm.get(DisputeDetailsFormNamesEnum.REQUEST_EXPLANATION).value;
    dispute.statusCd = DisputeStatusCd.DRAFT;
    dispute.listActionCd = dispute.disputeId ? ActionCd.UPDATE : ActionCd.ADD;
    dispute.recordVersionNbr = this.dispute ? this.dispute.dispute.recordVersionNbr : undefined;
    dispute.assignedToEmployeeId = this.dispute ? this.dispute.dispute.assignedToEmployeeId : undefined;
    dispute.assigneeEmplRole = this.dispute ? this.dispute.dispute.assigneeEmplRole : undefined;
    dispute.finalApprovlEmployeeId = this.dispute ? this.dispute.dispute.finalApprovlEmployeeId : undefined;
    dispute.finalApprovalDate = this.dispute ? this.dispute.dispute.finalApprovalDate : undefined;
    dispute.decisionReasonTypeCd = this.dispute ? this.dispute.dispute.decisionReasonTypeCd : undefined;
    dispute.decisionReason = this.dispute ? this.dispute.dispute.decisionReason : undefined;
    dispute.decisionReasonSubtypeCd = this.dispute ? this.dispute.dispute.decisionReasonSubtypeCd : undefined;
    return dispute;
  }

  /**
   * Shipments for upsert
   * Shoud add new items and remove.
   * If there is not changes, nothing should be send.
   */
  createShipmentForUpsert(): Array<Shipment> {
    let shipments: Shipment[] = [];
    const form = this.disputeFormGroup.get(InvoiceDetailsFormNamesEnum.FORM_GROUP_NAME);
    const shipmentsFormArray: FormArray = <FormArray>form.get(InvoiceDetailsFormNamesEnum.SHIPMENTS);
    const shipmentsInList: Shipment[] = shipmentsFormArray.controls
      .map((control) => control.value)
      .map((shipment: RowShipment) => {
        // TODO: maybe could be in invoice-detail
        // TODO: remove this later
        shipment.seqNbr = shipment.seqNbr != null ? shipment.seqNbr : shipment.idRow;
        delete shipment.idRow;
        delete shipment.selected;
        delete shipment.indicators;
        delete shipment.errors;
        delete shipment.requestedAdjustmentDeltaAmountFormatted;

        return shipment;
      });
    if (this.listDisputeShipments && this.listDisputeShipments.shipments) {
      this.listDisputeShipments.shipments.forEach((existingShipment) => {
        // Remove shipment
        if (!shipmentsInList.some((shipment) => existingShipment.proNbr === shipment.proNbr)) {
          existingShipment.listActionCd = ActionCd.DELETE;
          shipments.push(existingShipment);
        }
        // Update Shipment
        const shipmentUpdated = shipmentsInList.find((shipment) => existingShipment.proNbr === shipment.proNbr);
        if (shipmentUpdated && shipmentUpdated.listActionCd === ActionCd.UPDATE) {
          shipments.push(shipmentUpdated);
        }
      });
      // Add Shipment
      shipmentsInList.forEach((shipment) => {
        const exist = this.listDisputeShipments.shipments.some(
          (existingShipment) => existingShipment.proNbr === shipment.proNbr
        );
        if (!exist) {
          shipment.listActionCd = ActionCd.ADD;
          shipments.push(shipment);
        }
      });
    } else {
      shipments = shipmentsInList.map((shipment) => Object.assign({}, shipment, { listActionCd: ActionCd.ADD }));
    }

    return shipments;
  }

  /**
   * Shipments for update
   * Shoud add new items and remove.
   * If there is not changes, nothing should be send.
   */
  createShipmentForUpdate(): { add: Shipment[]; remove: Shipment[] } {
    const shipments = { add: [], remove: [] };

    const form = this.disputeFormGroup.get(InvoiceDetailsFormNamesEnum.FORM_GROUP_NAME);
    if (!form) {
      return shipments;
    }
    const shipmentsFormArray: FormArray = <FormArray>form.get(InvoiceDetailsFormNamesEnum.SHIPMENTS);
    const shipmentsInList: Shipment[] = shipmentsFormArray.controls
      .map((control) => control.value)
      .map((shipment: RowShipment) => {
        shipment.seqNbr = shipment.seqNbr != null ? shipment.seqNbr : shipment.idRow;
        delete shipment.idRow;
        delete shipment.selected;
        delete shipment.indicators;
        delete shipment.errors;
        delete shipment.requestedAdjustmentDeltaAmountFormatted;
        return shipment;
      });

    if (this.listDisputeShipments && this.listDisputeShipments.shipments) {
      this.listDisputeShipments.shipments.forEach((existingShipment) => {
        // Remove shipment
        if (!shipmentsInList.some((shipment) => existingShipment.proNbr === shipment.proNbr)) {
          existingShipment.listActionCd = ActionCd.DELETE;
          shipments.remove.push(existingShipment);
        }
        // Update Shipment
        const shipmentUpdated = shipmentsInList.find((shipment) => existingShipment.proNbr === shipment.proNbr);
        if (shipmentUpdated && shipmentUpdated.listActionCd === ActionCd.UPDATE) {
          shipments.add.push(shipmentUpdated);
        }
      });
      // Add Shipment
      shipmentsInList.forEach((shipment) => {
        const exist = this.listDisputeShipments.shipments.some(
          (existingShipment) => existingShipment.proNbr === shipment.proNbr
        );
        if (!exist) {
          shipments.add.push(shipment);
        }
      });
    } else {
      shipments.add = shipmentsInList.map((shipment) => Object.assign({}, shipment, { listActionCd: ActionCd.ADD }));
    }

    return shipments;
  }

  validateForm(): void {
    FormUtils.markAsTouched(this.disputeFormGroup);
  }

  formIsValidForSaving(): boolean {
    let result: boolean;
    result = this.disputeFormGroup.get(DisputeFilingFormNamesEnum.FORM_GROUP_NAME).valid;
    if (!result) {
      this.disputeDataService.formErrors = this.findInvalidControls(
        this.disputeFormGroup.get(DisputeFilingFormNamesEnum.FORM_GROUP_NAME)
      );
    }
    return result;
  }
}
