import { DisputeDecisionReasonSubTypeCd } from '@xpo-ltl/sdk-common';

export class DisputeDecisionReasonApprovalHelper {
  readonly DisputeDecisionReasonSubTypeCd: DisputeDecisionReasonSubTypeCd;

  static getOCCApprovalReasons(): string[] {
    const reasons = [
      DisputeDecisionReasonSubTypeCd.ACCESSORIAL_CHARGE,
      DisputeDecisionReasonSubTypeCd.BILLING_ERROR,
      DisputeDecisionReasonSubTypeCd.CLASS_CHANGE,
      DisputeDecisionReasonSubTypeCd.CODE_MATCHING,
      DisputeDecisionReasonSubTypeCd.CONTRACTUAL_EXCEPTIONS,
      DisputeDecisionReasonSubTypeCd.DUPLICATE_PAYMENT,
      DisputeDecisionReasonSubTypeCd.INSPECTION,
      DisputeDecisionReasonSubTypeCd.INSPECTOR_ERROR,
      DisputeDecisionReasonSubTypeCd.INSUFFICIENT_SUPPORTING_INFORMATION,
      DisputeDecisionReasonSubTypeCd.LINEAL_FOOT,
      DisputeDecisionReasonSubTypeCd.MISCELLANEOUS,
      DisputeDecisionReasonSubTypeCd.RATING_ERROR,
      DisputeDecisionReasonSubTypeCd.REFUND_OF_CREDIT_BALANCE,
      DisputeDecisionReasonSubTypeCd.REWEIGH,
      DisputeDecisionReasonSubTypeCd.RULESET_ERROR,
      DisputeDecisionReasonSubTypeCd.TARIFF_OR_CONTRACT,
      DisputeDecisionReasonSubTypeCd.TERMS_BILLED_CORRECTLY_PER_BILL_OF_LADING,
      DisputeDecisionReasonSubTypeCd.WEIGHT_CORRECTION,
      DisputeDecisionReasonSubTypeCd.OTHER,
    ];
    return reasons.map((code) => code.valueOf());
  }

  static getWNIApprovalReasons(): string[] {
    const reasons = [DisputeDecisionReasonSubTypeCd.INSPECTOR_ERROR, DisputeDecisionReasonSubTypeCd.OTHER];
    return reasons.map((code) => code.valueOf());
  }

  static getDRPApprovalReasons(): string[] {
    const reasons = [
      DisputeDecisionReasonSubTypeCd.ACCESSORIAL_DISPUTE,
      DisputeDecisionReasonSubTypeCd.REWEIGH_ADJUSTMENT,
      DisputeDecisionReasonSubTypeCd.CARGO_LOSS_DAMAGE_DISPUTE,
      DisputeDecisionReasonSubTypeCd.CUSTOMER_SATISFACTION,
      DisputeDecisionReasonSubTypeCd.DRP_DECLINED,
      DisputeDecisionReasonSubTypeCd.GUARANTEED,
      DisputeDecisionReasonSubTypeCd.INSPECTION,
      DisputeDecisionReasonSubTypeCd.RATE_DISPUTE,
      DisputeDecisionReasonSubTypeCd.SYSTEM_ISSUE,
      DisputeDecisionReasonSubTypeCd.OTHER,
    ];
    return reasons.map((code) => code.valueOf());
  }
}
