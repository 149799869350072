export enum ContactsListDialogFormLabelsEnum {
  TITLE = 'Contacts List',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  EMAIL_ADDRESS = 'Email Address',
  PHONE = 'Phone',
  EXT = 'Ext.',
  FAX = 'Fax',
  CANCEL = 'Cancel',
  SELECT = 'Select',
  FILTER_BY = 'Filter First Name, Last Name, Phone, etc.',
  NO_CONTACTS_FOUND = 'No contacts were found with your search criteria. Please try a new search.',
}
