export enum DisputeRolesEnum {
  VIEW_ONLY = 'disputeViewOnlyRole',
  REQUESTER = 'disputeRequesterRole',
  OCC_APPROVER = 'disputeOCCApproverRole',
  WNI_APPROVER = 'disputeWNIApproverRole',
  CORRECTIONS_AUDITOR = 'disputeCorrectionsAuditorRole',
  // #region DRP
  NAE = 'disputeNAERole',
  CUSTOMER_CARE_SUPERVISOR = 'disputeCustomerCareSupervisorRole',
  SERVICE_CENTER_MANAGER = 'disputeServiceCenterManagerRole',
  SMRS = 'disputeSMRSRole',
  DNS = 'disputeDNSRole',
  DOO = 'disputeDOORole',
  DIRECTOR_OF_CUSTOMER_CARE = 'disputeDirectorOfCustomerCareRole',
  VPAS = 'disputeVPASRole',
  VPAO = 'disputeVPAORole',
  VP_MARKETING = 'disputeVPMarketingRole',
  SVP_SALES = 'disputeSVPSalesRole',
  SVP_OPS = 'disputeSVPOpsRole',
  VP_FINANCE = 'disputeVPFinanceRole',
  ADMIN = 'disputeAdminRole',
  COLL_REVIEWER = 'disputeCollReviewerRole',
  COLL_APPROVER = 'disputeCollApproverRole',
  DISPUTE_DIRECTOR = 'disputeDirector',
  DISPUTE_VP = 'disputeVP',
  DISPUTE_SVP = 'disputeSVP',
  DISPUTE_VPS = 'disputeVPS',
  // #endregion
}
