import { DisputeRequestReasonCd } from '@xpo-ltl/sdk-common';

export class DisputeRequestReasonHelper {
  /**
   * Request Reason for Claims Integration
   * *DisputeRequestReasonCd.DRP_CARGO_LOSS_DAMAGE_DISPUTE
   */
  static isValidForClaimIntegration(requestReasonCd: DisputeRequestReasonCd): boolean {
    const requestReasonsForClaimsIntegration = [DisputeRequestReasonCd.DRP_CARGO_LOSS_DAMAGE_DISPUTE];
    return requestReasonsForClaimsIntegration.includes(requestReasonCd);
  }

  /**
   * Request reason is displayed in the drop down
   */
  static isDRPDisplayedRequestReason(
    requestReasonCd: DisputeRequestReasonCd,
    canEdit: boolean,
    isRegular: boolean
  ): boolean {
    const retiredRequestReasonCodes = [
      DisputeRequestReasonCd.DRP_CUSTOMER_SATISFACTION,
      DisputeRequestReasonCd.DRP_CUSTOMER_NON_COMPLIANCE_NON_REVENUE_ADJUSTMENT,
      DisputeRequestReasonCd.DRPEDI_NON_REVENUE_ADJUSTMENT,
      DisputeRequestReasonCd.DRP_CLAIM_DISPUTE,
    ];

    const regularCodes = [DisputeRequestReasonCd.DRP_CARGO_LOSS_DAMAGE_DISPUTE];

    if (requestReasonCd.startsWith('DRP')) {
      if (canEdit) {
        return (
          !retiredRequestReasonCodes.includes(requestReasonCd) &&
          (!isRegular ? !regularCodes.includes(requestReasonCd) : true)
        );
      } else {
        return true;
      }
    }
    return false;
  }
}
