import { Injectable } from '@angular/core';

import { ConditioningService } from '@xpo-ltl/common-services';
import { DataOptions } from '@xpo-ltl/data-api';
import { ListInfo } from '@xpo-ltl/sdk-common';

import {
  AddShipmentsToDisputePath,
  AddShipmentsToDisputeResp,
  AddShipmentsToDisputeRqst,
  DeleteShipmentsFromDisputePath,
  DeleteShipmentsFromDisputeQuery,
  DisputesApiService,
  DisputeShipment,
  ListDisputeShipmentsQuery,
  Shipment,
  ValidateDisputeShipmentPath,
  ValidateDisputeShipmentQuery,
  ValidateDisputeShipmentResp,
} from '@xpo-ltl/sdk-disputes';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProNumberMaxLengthsEnum } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class DisputesShipmentsService {
  constructor(private disputesApiService: DisputesApiService, private conditioningService: ConditioningService) {}

  /**
   * Return if two shipment have same pro number.
   */
  areProEquals(shipment: Shipment, shipmentToCompare: Shipment): boolean {
    return (
      this.conditioningService.conditionProNumber(shipment.proNbr, ProNumberMaxLengthsEnum.DIGITS) ===
      this.conditioningService.conditionProNumber(shipmentToCompare.proNbr, ProNumberMaxLengthsEnum.DIGITS)
    );
  }

  getShipmentsByDisputeId(filters: { disputeId: string; proNumber?: string }, startAt: number = 0) {
    const query = new ListDisputeShipmentsQuery();
    query.disputeId = filters.disputeId;
    if (filters.proNumber) {
      query.proNbr = filters.proNumber;
    }
    query.listInfo = new ListInfo();
    query.listInfo.startAt = startAt;
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };
    return this.disputesApiService.listDisputeShipments(query, dataOptions);
  }

  validateShipments(
    disputeId: string,
    disputeReceivedDate: string,
    proNumber: string,
    shipmentInstId: string
  ): Observable<ValidateDisputeShipmentResp> {
    const validateDisputeShipmentPath = new ValidateDisputeShipmentPath();
    validateDisputeShipmentPath.disputeId = disputeId;
    const validateDisputeShipmentQuery = new ValidateDisputeShipmentQuery();
    validateDisputeShipmentQuery.disputeReceivedDate = disputeReceivedDate;
    validateDisputeShipmentQuery.proNumber = this.conditioningService.conditionProNumber(
      proNumber,
      ProNumberMaxLengthsEnum.DIGITS
    );
    validateDisputeShipmentQuery.shipmentInstId = shipmentInstId;
    return this.disputesApiService
      .validateDisputeShipment(validateDisputeShipmentPath, validateDisputeShipmentQuery)
      .pipe(
        map((result) => {
          result.disputeShipment = Object.assign({}, result.disputeShipment, {
            proNbr: this.conditioningService.conditionProNumber(result.disputeShipment.proNbr),
          });
          return result;
        })
      );
  }

  /**
   * Use it for add and update shipments
   */
  addShipments(shipments: Shipment[], disputeId: string, itemInstId: number): Observable<AddShipmentsToDisputeResp> {
    if (!shipments || !shipments.length) {
      const response = new AddShipmentsToDisputeResp();
      response.shipments = [];
      return of(response);
    }
    const mapToDisputeShipment = (shipment: Shipment): DisputeShipment => ({
      pickupDate: shipment.pickupDate,
      proNumber: shipment.proNbr,
      requestedAdjustmentAmount: shipment.requestedAdjustmentDeltaAmount,
      shipmentInstId: shipment.shipmentInstId.toString(),
    });

    const request = new AddShipmentsToDisputeRqst();
    request.disputeShipments = shipments.map(mapToDisputeShipment);

    const path = new AddShipmentsToDisputePath();
    path.disputeId = disputeId;
    path.itemInstId = itemInstId;

    return this.disputesApiService.addShipmentsToDispute(request, path);
  }

  removeShipments(shipments: Shipment[], disputeId: string, itemInstId: number): Observable<boolean> {
    if (!shipments || !shipments.length) {
      return of(false);
    }
    const path = new DeleteShipmentsFromDisputePath();
    path.disputeId = disputeId;
    path.itemInstId = itemInstId;

    const query = new DeleteShipmentsFromDisputeQuery();
    query.shipmentSeqNbrs = shipments.map((shipment) => shipment.seqNbr);

    return this.disputesApiService.deleteShipmentsFromDispute(path, query).pipe(map(() => true));
  }
}
