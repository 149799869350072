import { DisputeDecisionReasonSubTypeCd } from '@xpo-ltl/sdk-common';

export class DisputeDecisionReasonDeclineHelper {
  readonly DisputeDecisionReasonSubTypeCd: DisputeDecisionReasonSubTypeCd;

  static getOCCDeclineReasons(): string[] {
    const reasons = [
      DisputeDecisionReasonSubTypeCd.ACCESSORIAL_CHARGE,
      DisputeDecisionReasonSubTypeCd.AMENDED_CLAIM,
      DisputeDecisionReasonSubTypeCd.CLASS_CORRECTLY_BILLED,
      DisputeDecisionReasonSubTypeCd.DUPLICATE_CLAIM_PRO_NUMBER,
      DisputeDecisionReasonSubTypeCd.INSPECTION,
      DisputeDecisionReasonSubTypeCd.INVALID_PRO_NUMBER_NOT_A_CONWAY_INVOICE,
      DisputeDecisionReasonSubTypeCd.LINEAL_FOOT,
      DisputeDecisionReasonSubTypeCd.MISCELLANEOUS,
      DisputeDecisionReasonSubTypeCd.NO_DUPLICATE_PAYMENT,
      DisputeDecisionReasonSubTypeCd.OPEN_INVOICE,
      DisputeDecisionReasonSubTypeCd.PREVIOUSLY_REFUNDED,
      DisputeDecisionReasonSubTypeCd.REWEIGH,
      DisputeDecisionReasonSubTypeCd.STATUTE_OF_LIMITATION,
      DisputeDecisionReasonSubTypeCd.TARIFF_OR_CONTRACT,
      DisputeDecisionReasonSubTypeCd.WI_APPEAL_NOT_APPROVED,
      DisputeDecisionReasonSubTypeCd.OTHER,
    ];
    return reasons.map((code) => code.valueOf());
  }

  static getWNIDeclineReasons(): string[] {
    const reasons = [
      DisputeDecisionReasonSubTypeCd.BILLING_ERROR,
      DisputeDecisionReasonSubTypeCd.CUSTOMER_ERROR,
      DisputeDecisionReasonSubTypeCd.INTERNAL_SYSTEM_ERROR,
      DisputeDecisionReasonSubTypeCd.NON_ERROR_CORRECTION,
      DisputeDecisionReasonSubTypeCd.OTHER,
    ];
    return reasons.map((code) => code.valueOf());
  }

  static getDRPDeclineReasons(): string[] {
    const reasons = [
      DisputeDecisionReasonSubTypeCd.AUTO_DECLINED,
      DisputeDecisionReasonSubTypeCd.REWEIGH_ADJUSTMENT,
      DisputeDecisionReasonSubTypeCd.OTHER,
    ];
    return reasons.map((code) => code.valueOf());
  }
}
