export enum AddressEntryFormNamesEnum {
  FORM_GROUP_NAME = 'Address',
  CONTACT_ID = 'contactId',
  MAD_CODE = 'MADCode',
  CUSTOMER_NUMBER = 'customerNumber',
  NAME = 'Name',
  MAILING_ADDRESS_1 = 'Address1',
  MAILING_ADDRESS_2 = 'Address2',
  CITY = 'City',
  PROVINCE_STATE = 'ProvinceState',
  ZIP_CODE = 'ZipCode',
  COUNTRY = 'Country',
  CONTACT_FIRST_NAME = 'ContactFirstName',
  CONTACT_LAST_NAME = 'ContactLastName',
  CONTACT_EMAIL_ADDRESS = 'ContactEmailAddress',
  CONTACT_PHONE_NUMBER = 'ContactPhoneNumber',
  CONTACT_PHONE_EXT_NUMBER = 'ContactPhoneExtNumber',
  CONTACT_FAX_NUMBER = 'ContactFaxNumber',
  MATCH_PARTY_ID = 'MatchPartyId',
  VIEW_CONTACT = 'ViewContact',
  VIEW_CONTACTS = 'ViewContacts',
  SEARCH = 'Search',
}
