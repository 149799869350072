export enum MatchDialogFormLabelsEnum {
  CLAIMANT_TITLE = 'Match Claimant',
  CLAIMANT_EXPLANATION = 'Please select the correct claimant for the dispute and hit Match.',
  ON_BE_HALF_OF_TITLE = 'Match Requestor (On Behalf Of Claimant)',
  ON_BE_HALF_OF_EXPLANATION = 'Please select the correct requestor for the dispute and hit Match.',
  NOT_FOUND = 'Parties not found',
  NAME = 'Name',
  ADDRESS = 'Address Line 1',
  CITY = 'City',
  PROVINCE_STATE = 'Province/State',
  ZIP_CODE = 'Zip Code',
  COUNTRY = 'Country',
}
