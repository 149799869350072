import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingManagerService {
  private showFinishingLoadingSubject: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  showFinishingLoading$ = this.showFinishingLoadingSubject.asObservable();

  getShowFinishingLoading(): Boolean {
    return this.showFinishingLoadingSubject.value;
  }
  setShowFinishingLoading(show: Boolean) {
    if (show) {
    } else {
    }
    this.showFinishingLoadingSubject.next(show);
  }
  constructor() {}
}
