import { FormBuilder, FormGroup } from '@angular/forms';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { EmailEntryFormBuilder } from '../../../../../../lib/src/lib/notes-emails/public_api';
import { ApprobationEmailDialogFormNamesEnum } from '../../../../enums/form-control-names/approbation-email-dialog-form-names.enum';

export class ApprobationEmailDialogFormBuilder {
  static create(fb: FormBuilder, formatValidationService: FormatValidationService): FormGroup {
    return fb.group({
      [ApprobationEmailDialogFormNamesEnum.EMAIL]: EmailEntryFormBuilder.create(fb, formatValidationService),
    });
  }
}
