import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProDownloadTemplateDialogFormLabelsEnum } from '../../../../enums/form-labels/pro-download-template-dialog-form-labels.enum';
import { SvgIconsEnums } from '../../../../enums/svg-icons.enum';

@Component({
  selector: 'app-pro-download-template-dialog',
  templateUrl: './pro-download-template-dialog.component.html',
  styleUrls: ['./pro-download-template-dialog.component.scss'],
})
export class ProDownloadTemplateDialogComponent implements OnInit {
  readonly ProDownloadTemplateDialogFormLabelsEnum = ProDownloadTemplateDialogFormLabelsEnum;
  readonly SvgIconsEnums = SvgIconsEnums;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ProDownloadTemplateDialogComponent>
  ) {}

  ngOnInit() {}

  onCancelClick() {
    this.dialogRef.close();
  }

  onContinueClick() {
    this.data.callback();
    this.dialogRef.close();
  }
}
