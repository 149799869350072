import { FormBuilder, Validators } from '@angular/forms';

import { DisputeReassignReasonCd } from '@xpo-ltl/sdk-common';
import { ReassignDisputeFormNamesEnum } from '../../../../enums/form-control-names/reassign-dispute-form-names.enum';

export class ReassignDialogFormBuilder {
  static create(fb: FormBuilder) {
    return fb.group({
      [ReassignDisputeFormNamesEnum.REASON_CODE]: [DisputeReassignReasonCd.OTHER, Validators.required],
      [ReassignDisputeFormNamesEnum.REASON_FOR_REASSIGNMENT]: [''],
    });
  }
}
