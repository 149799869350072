import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { NoteData } from '../../classes/notes-data.class';

@Component({
  selector: 'xpo-notes-detail',
  templateUrl: './notes-detail.component.html',
  styleUrls: ['./notes-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesDetailComponent implements OnInit {
  private readonly searchEmailExp = /(?:<(.+?)@(.+?)>)/g;
  private readonly replaceEmailExp = '&lt;$1@$2&gt;';

  @Input()
  note: NoteData;

  private authorNameSubject = new BehaviorSubject<string>(undefined);
  authorName$ = this.authorNameSubject.asObservable();

  constructor() {}

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.authorNameSubject.next(
      `${_.get(this.note, 'createByEmployeeDtl.firstName', '')}
      ${_.get(this.note, 'createByEmployeeDtl.middleName', '')} ${_.get(
        this.note,
        'createByEmployeeDtl.lastName',
        ''
      )} - ${_.get(this.note, 'createByEmployeeDtl.jobDescription', '')}`
    );
  }

  escapeEmails(body: string): string {
    return body && body.replace(this.searchEmailExp, this.replaceEmailExp);
  }
}
