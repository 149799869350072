<div [formGroup]="formGroup" class="notes">
  <as-split #splitPanel gutterSize="12" direction="horizontal" class="notes__content">
    <as-split-area class="notes__content-left" size="50" [visible]="enabled">
      <div class="notes__content-container">
        <xpo-card-content class="notes__content-inner">
          <xpo-notes-entry
            [noteTypes]="noteTypes$ | async"
            [defaultNoteType]="defaultNoteType"
            [formGroup]="notesEntryFormGroup"
          ></xpo-notes-entry>
        </xpo-card-content>
        <xpo-card-actions class="notes__content-actionbuttons">
          <button mat-raised-button [disabled]="formGroup.invalid" (click)="addNoteClicked()" data-test="addNote">
            {{ NotesFormLabelsEnum.ADD_NOTE }}
          </button>
        </xpo-card-actions>
      </div>
    </as-split-area>
    <as-split-area size="50" class="notes__detail-inner">
      <xpo-card class="notes__detail-inner-container">
        <xpo-card-header class="notes__detail-header">
          <xpo-card-title>
            {{ NotesFormLabelsEnum.NOTES }}<span *ngIf="(notesCount$ | async) > 0">({{ notesCount$ | async }})</span>
          </xpo-card-title>
          <xpo-card-actions>
            <mat-form-field data-test="filterNoteType">
              <mat-select #filterNoteType [placeholder]="NotesFormLabelsEnum.NOTE_TYPE">
                <mat-option *ngFor="let filterNoteType of filterNoteTypes$ | async" [value]="filterNoteType">
                  Note Type: {{ filterNoteType | humanize }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field data-test="noteAge">
              <mat-select #age [placeholder]="NotesFormLabelsEnum.AGE_ORDER">
                <mat-option *ngFor="let age of ageTypes" [value]="age">
                  {{ age }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </xpo-card-actions>
        </xpo-card-header>
        <xpo-card-content class="notes__detail-list">
          <div class="notes__detail-no-items" *ngIf="!(notesCount$ | async)">
            {{ NotesFormLabelsEnum.NOTES_EMPTY }}
          </div>
          <div class="notes__detail-item" *ngFor="let note of notes$ | async">
            <xpo-notes-detail [note]="note"></xpo-notes-detail>
          </div>
        </xpo-card-content>
      </xpo-card>
    </as-split-area>
  </as-split>
</div>
