<app-base-dispute-dialog
  [title]="title"
  (closeButtonClick)="handleCloseButtonClick()"
  [hideCloseDialogButton]="hideCloseDialogButton"
  [buttons]="confirmDisputeDialogButtons"
  (buttonClick)="handleClick($event)"
>
  <div class="confirmation-content">
    <p class="confirmation-content--message" [innerHTML]="message"></p>
    <div class="confirmation-content--expansion">
      <ng-container *ngFor="let shipment of shipments; let i = index">
        <mat-expansion-panel [expanded]="i === 0" [hideToggle]="shipments.length === 1" [disabled]="shipments.length === 1">
          <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
            <mat-panel-title> PRO# {{ shipment.proNbr }} </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="expansion-panel-body">
            <app-claims-dispute-dialog-row title="Requestor" [value]="requestorName"> </app-claims-dispute-dialog-row>
            <app-claims-dispute-dialog-row title="Date" [value]="dispute.receivedDate | date:'MM/dd/yyyy'">
            </app-claims-dispute-dialog-row>
            <app-claims-dispute-dialog-row
              title="Policy Pay Amount"
              [value]="shipment.requestedAdjustmentDeltaAmountFormatted"
              [suffix]="shipment.currencyCd"
            >
            </app-claims-dispute-dialog-row>
            <app-claims-dispute-dialog-row title="Customer MAD Code" [value]="madCode"> </app-claims-dispute-dialog-row>
            <app-claims-dispute-dialog-row
              title="Operating Ratio For Previous 9 Months"
              subtitle="Cargo Claims will provide Claims ratio"
              [value]="dispute.drpOperatingRatioOrPercentage"
              suffix="%"
            >
            </app-claims-dispute-dialog-row>
            <app-claims-dispute-dialog-row title="Preventive Measures Taken" [value]="dispute.drpPreventativeMeasures">
            </app-claims-dispute-dialog-row>
            <app-claims-dispute-dialog-row title="Overall Justification" [value]="dispute.description">
            </app-claims-dispute-dialog-row>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </div>
  </div>
  <!-- <p>confirm-new-claims-dispute-dialog works!</p> -->
</app-base-dispute-dialog>
