<div class="contacts-list">
  <h1 mat-dialog-title>
    {{ title }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div mat-dialog-content>
    <mat-form-field class="full-width" floatLabel="always">
      <mat-label>{{ ContactsListDialogFormLabelsEnum.FILTER_BY }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)" #filterInput />
    </mat-form-field>
    <div *ngIf="loading">Loading ...</div>
    <div *ngIf="!loading && contactsNotFound">{{ ContactsListDialogFormLabelsEnum.NO_CONTACTS_FOUND }}</div>
    <div class="contacts-list__table_container" [hidden]="loading || !contacts?.data?.length">
      <table mat-table [dataSource]="contacts" matSort class="contacts-list__table">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-radio-button [value]="row.id" (change)="contactSelected(row)"></mat-radio-button>
          </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ ContactsListDialogFormLabelsEnum.FIRST_NAME }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ ContactsListDialogFormLabelsEnum.LAST_NAME }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ ContactsListDialogFormLabelsEnum.EMAIL_ADDRESS }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.emailAddress }}</td>
        </ng-container>
        <ng-container matColumnDef="phoneNbr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ ContactsListDialogFormLabelsEnum.PHONE }}</th>
          <td mat-cell *matCellDef="let row">{{ row.phoneNbr }}</td>
        </ng-container>
        <ng-container matColumnDef="phoneExt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ ContactsListDialogFormLabelsEnum.EXT }}</th>
          <td mat-cell *matCellDef="let row">{{ row.phoneExt }}</td>
        </ng-container>
        <ng-container matColumnDef="faxNbr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ ContactsListDialogFormLabelsEnum.FAX }}</th>
          <td mat-cell *matCellDef="let row">{{ row.faxNbr }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div mat-dialog-actions class="contacts-list__footer">
    <button mat-button color="primary" (click)="cancel()" [disabled]="loading">
      {{ ContactsListDialogFormLabelsEnum.CANCEL }}
    </button>
    <button mat-flat-button (click)="returnContactSelected()" [disabled]="disableSelectContactButton">
      {{ ContactsListDialogFormLabelsEnum.SELECT }}
    </button>
  </div>
</div>
