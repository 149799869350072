export enum DisputeInformationFormNamesEnum {
  FORM_GROUP_NAME = 'DisputeInformation',
  DISPUTE_TYPE = 'DisputeType',
  DISPUTE_RECEIVED_DATE = 'DisputeReceivedDate',
  DISPUTE_CREATED_DATE = 'DisputeCreatedDate',
  DISPUTE_FILING_DATE = 'DisputeFilingDate',
  FILED_BY = 'FiledBy',
  FILED_BY_NAME = 'FiledByName',
  FILE_METHOD = 'FileMethod',
  CUSTOMER_REFERENCE_NUMBER = 'CustomerReferenceNumber',
  DISPUTE_DETAILS = 'DisputeDetails',
  INVOICE_DETAILS = 'InvoiceDetails',
  TOTAL_PRO_COUNT = 'TotalProCount',
  TOTAL_INVOICE_AMOUNT = 'TotalInvoiceAmount',
  TOTAL_REQUESTED_AMOUNT = 'TotalRequestedAmount',
  ASSIGNED_TO = 'AssignedTo',
  ASSIGNED_TO_NAME = 'AssignedToName',
  REASSIGN = 'Reassign',
  ASSIGN = 'Assign',
  APPROVED_BY = 'ApprovedBy',
  APPROVED_DATE = 'ApprovedDate',
  AMENDED_BY = 'AmendedBy',
  AMENDED_DATE = 'AmendedDate',
  DECLINED_DATE = 'DeclinedDate',
  DECLINED_BY = 'DeclinedBy',
  CANCELLED_DATE = 'CancelledDate',
  CANCELLED_BY = 'CancelledBy',
  REASON_CODE = 'ReasonCode',
  CLOSED_BY = 'ClosedBy',
  CLOSED_DATE = 'ClosedDate',
}
