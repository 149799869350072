import { ActionCd, AuditInfo, DisputeEmailTemplateTypeCd, EmailDirectionTypeCd } from '@xpo-ltl/sdk-common';
import { EmailAttachData } from './email-attach-data.class';

export class EmailData {
  /**
   * Unique identifier for the email interaction.
   * Required: false
   * Array: false
   */
  emailInstId: number;
  /**
   * The email address for the sender of the email.
   * Required: false
   * Array: false
   */
  senderEmailAddress: string;
  /**
   * The email address for the recipient of the email.
   * Required: false
   * Array: false
   */
  recipientEmailAddress: string;
  /**
   * The email address for recipients that are copied on the email.
   * Multiple copied recipient email addresses may be entered and separate by a semicolon.
   * Required: false
   * Array: false
   */
  ccEmailAddress: string;
  /**
   * The subject line for the email message.
   * Required: false
   * Array: false
   */
  subject: string;
  /**
   * The body (usually HTML) of the email message.
   * If the length of the message is longer than 4000 char then, remaining text goes into table DSP_EMAIL_XTND_MSG
   * Required: false
   * Array: false
   */
  message: string;
  /**
   * Same value for event id is assigned to various rows from different tables that form a single database transactions.
   * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
   * In other words, this groups multipple rows across various tables for a single database transaction.
   * Typically this is used to publish a message to a queue for consumption or a workflow.
   * Required: false
   * Array: false
   */
  dspEventId: number;
  /**
   * Indicates whether the Email has been read by the examiner.
   * Required: false
   * Array: false
   */
  readInd: boolean;
  /**
   * Not Null,Indicates the direction of the email.
   * Valid List of values
   * INBOUND - email is incoming to the Disputes application mailbox
   * OUTBOUND - email is outbound from the Disputes application mailbox to another party
   * Required: false
   * Array: false
   */
  directionTypeCd: EmailDirectionTypeCd;
  /**
   * Indicates whether the email is a Draft email and has not been sent yet.
   * Required: false
   * Array: false
   */
  draftEmailInd: boolean;
  /**
   * Indicates the template that was used in composing the email. This is the same as DSP_EMAIL_TEMPLATE.TYP_CD.
   * Required: false
   * Array: false
   */
  templateTypeCd: DisputeEmailTemplateTypeCd;
  /**
   * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.
   * In other words, this groups multipple rows across various tables for a single business transaction.
   * Required: false
   * Array: false
   */
  correlationId: string;
  /**
   * An update operation uses this to version check the record to ensure another transaction has not updated
   * the row after this transaction had read the row and is now about to update it.
   * Required: false
   * Array: false
   */
  recordVersionNbr: number;
  /**
   * Indicates whether to add, update or delete this entry. This will be used for merging lists.
   *
   * A - Add entry to list
   * D - Delete entry from list
   * U - Update entry
   * Required: false
   * Array: false
   */
  listActionCd: ActionCd;
  /**
   * Audit information such as create and/or update transaction details - who, when and which program/transaction
   * Required: false
   * Array: false
   */
  auditInfo: AuditInfo;
  /**
   *
   * Required: false
   * Array: true
   */
  emailAttach: EmailAttachData[];
}
