import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmendExplanationDialogFormNamesEnum } from '../../../../enums/form-control-names/amend-explanation-dialog-form-names.enum';
import { AmendExplanationDialogMaxLenghtsEnum } from '../../../../enums/form-max-lengths/amend-explanation-dialog-max-lengths.enum';

export class AmendExplanationDialogFormBuilder {
  static create(fb: FormBuilder): FormGroup {
    return fb.group({
      [AmendExplanationDialogFormNamesEnum.REASON_CODE]: ['', Validators.required],
      [AmendExplanationDialogFormNamesEnum.REASON_EXPLANATION]: [
        '',
        Validators.maxLength(AmendExplanationDialogMaxLenghtsEnum.REASON_EXPLANATION),
      ],
      [AmendExplanationDialogFormNamesEnum.CC_EMAILS]: [''],
    });
  }
}
