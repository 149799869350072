export enum ActionBarButtonTypesCd {
  SUBMIT = 'Submit',
  SUBMIT_FOR_REVIEW = 'Submit For Review',
  SUBMIT_FOR_APPROVAL = 'Submit For Approval',
  SAVE_DRAFT = 'Save Draft',
  UPDATE_FOR_EDIT = 'Update For Edit',
  UPDATE = 'Update Dispute',
  SAVE_CHANGES = 'Save Changes',
  DECLINE = 'Decline Dispute',
  SAVE = 'Save as Draft',
  APPROVE = 'Approve Dispute',
  APPROVE_AND_CLOSE = 'Approve & Close',
  AMEND = 'Amend Dispute',
  DELETE = 'Delete',
  EDIT = 'Edit',
  CANCEL = 'Cancel Dispute',
  BACK_TO_WORKLIST = 'Back to Worklist',
  GRAB = 'Grab',
  DISCARD_CHANGES = 'Discard Changes',
  GO_BACK = 'Go Back',
  START_CORRECTIONS = 'Start Corrections',
  CLOSE = 'Close',
}
