export enum ConfigManagerProperties {
  buildVersion = 'buildVersion',
  production = 'production',
  sysRegion = 'sys_rgn',
  appUrl = 'appUrl',
  apiUrl = 'apiUrl',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  appName = 'appName',
  region = 'region',
  loggedInUserRoot = 'loggedInUserRoot',
  userContextPath = 'userContextPath',
  appMetadataEndpoint = 'appMetadataEndpoint',
  bolApiEndpoint = 'bolApiEndpoint',
  customerLocationApiEndpoint = 'customerLocationApiEndpoint',
  elasticSearchApiEndpoint = 'elasticSearchApiEndpoint',
  freightFlowApiEndpoint = 'freightFlowApiEndpoint',
  infrastructureApiEndpoint = 'infrastructureApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  locationApiEndpoint = 'locationApiEndpoint',
  loggingApiEndpoint = 'loggingApiEndpoint',
  membershipApiEndpoint = 'membershipApiEndpoint',
  pricingApiEndpoint = 'pricingApiEndpoint',
  shipmentApiEndpoint = 'shipmentApiEndpoint',
  errorToastDuration = 'errorToastDuration',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  compatibleBrowsers = 'compatibleBrowsers',
  omsSystemNotesUrl = 'omsSystemNotesUrl',
  omsSystemNotesDisputeIdParam = 'omsSystemNotesDisputeIdParam',
  claimsAppUrl = 'claimsAppUrl',
  dmsCorpCode = 'dmsCorpCode',
  dmsDocClass = 'dmsDocClass',
  prodAppUrl = 'prodAppUrl',
  claimsProdUrl = 'claimsProdUrl'
}
