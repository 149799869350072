export enum NotesFormNamesEnum {
  FORM_GROUP_NAME = 'notesFormGroup',
  AuthorName = 'authorName',
  NoteTxt = 'noteTxt',
  SubjectTxt = 'subjectTxt',
  NoteId = 'noteId',
  TypeCd = 'typeCd',
  VisibilityCd = 'visibilityCd',
  ListActionCd = 'listActionCd',
  AuditInfo = 'auditInfo',
  ClaimEventId = 'claimEventId',
  RecordVersionNbr = 'recordVersionNbr',
  CorrelationId = 'correlationId',
  CreateByEmployeeDtl = 'createByEmployeeDtl',
}
