import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ShpmtRecycledPro } from '@xpo-ltl/sdk-disputes';

import { SelectProDialogFormLabelsEnum } from '../../../../enums/form-labels/select-pro-dialog-form-labels.enum';

@Component({
  selector: 'app-select-pro-dialog',
  templateUrl: './select-pro-dialog.component.html',
  styleUrls: ['./select-pro-dialog.component.scss'],
})
export class SelectProDialogComponent implements OnInit, OnDestroy {
  readonly SelectProDialogFormLabelsEnum = SelectProDialogFormLabelsEnum;
  proItems: ShpmtRecycledPro[];
  proNumber: string;
  unsubscriber = new Unsubscriber();
  dataSource = new MatTableDataSource<ShpmtRecycledPro>([]);
  displayedColumns = ['select', 'pkupDt', 'shipperName', 'billToName', 'consigneeName'];
  selectedPro: ShpmtRecycledPro;
  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<SelectProDialogComponent>) {
    this.proItems = data.proItems;
    this.proNumber = data.proNumber;
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<ShpmtRecycledPro>(this.proItems);
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onSelectClick() {
    this.dialogRef.close(this.selectedPro);
  }

  selectPro(pro) {
    this.selectedPro = pro;
  }
}
