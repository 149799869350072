export enum BoardSourcesOfChangeEnum {
  SELECTION_CHANGE = 'SELECTION-CHANGE',
  FILTER_CHANGE = 'FILTER-CHANGE',
  BOARD_ACTIVATING_VIEW = 'BOARD-ACTIVATING-VIEW',
  BOARD_DATA_FETCHER = 'BOARD DATA FETCHER',
  ACTIVE_VIEW_CHANGE = 'ACTIVE-VIEW-CHANGE',
  SAVE_VIEW_AS = 'SAVE-VIEW-AS',
  REFRESH = 'DATA SOURCE REFRESH',
  AG_GRID_SORT = 'AG-GRID-SORT',
  SET_VISIBLE_COLUMNS = 'SET-VISIBLE-COLUMNS',
  GRID_SETTINGS_UPDATE = 'GRID-SETTINGS-UPDATE',
}
