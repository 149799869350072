/**
 * Logged in User Role
 */
export enum UserRole {
  Requester = 'Requester',
  Approver = 'Approver',
  Auditor = 'Auditor',
  CorrectionsRequestor = 'CorrectionRequestor',
  TST_LTL_DSP_RQSTR = 'TST_LTL_DSP_RQSTR',
  TST_LTL_DSP_OCC_APPROVER = 'TST_LTL_DSP_OCC_APPROVER',
  TST_LTL_DSP_WNI_APPROVER = 'TST_LTL_DSP_WNI_APPROVER',
  TST_LTL_DSP_CORR_AUDITOR = 'TST_LTL_DSP_CORR_AUDITOR',
  TST_LTL_DSP_NAE = 'TST_LTL_DSP_NAE',
  TST_LTL_DSP_CUST_CARE_SUPV = 'TST_LTL_DSP_CUST_CARE_SUPV',
  TST_LTL_DSP_SVC_CTR_MANAGER = 'TST_LTL_DSP_SVC_CTR_MANAGER',
  TST_LTL_DSP_SMRS = 'TST_LTL_DSP_SMRS',
  TST_LTL_DSP_DNS = 'TST_LTL_DSP_DNS',
  TST_LTL_DSP_DOO = 'TST_LTL_DSP_DOO',
  TST_LTL_DSP_DIR_CUST_CARE = 'TST_LTL_DSP_DIR_CUST_CARE',
  TST_LTL_DSP_VPAS = 'TST_LTL_DSP_VPAS',
  TST_LTL_DSP_VPAO = 'TST_LTL_DSP_VPAO',
  TST_LTL_DSP_VP_MARKETING = 'TST_LTL_DSP_VP_MARKETING',
  TST_LTL_DSP_SVP_SALES = 'TST_LTL_DSP_SVP_SALES',
  TST_LTL_DSP_SVP_OPS = 'TST_LTL_DSP_SVP_OPS',
  TST_LTL_DSP_VP_FINANCE = 'TST_LTL_DSP_VP_FINANCE',
  TST_LTL_DSP_ADMIN = 'TST_LTL_DSP_ADMIN',
  TST_LTL_DSP_COLL_REVIEWER = 'TST_LTL_DSP_COLL_REVIEWER',
  TST_LTL_DSP_COLL_APPROVER = 'TST_LTL_DSP_COLL_APPROVER',
}
