import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DeclinationExplanationDialogFormNamesEnum,
  DeclinationExplanationDialogMaxLenghtsEnum,
} from '../../../../enums';

export class DeclinationExplanationDialogFormBuilder {
  static create(fb: FormBuilder): FormGroup {
    return fb.group({
      [DeclinationExplanationDialogFormNamesEnum.REASON_CODE]: ['', Validators.required],
      [DeclinationExplanationDialogFormNamesEnum.REASON_EXPLANATION]: [
        '',
        Validators.maxLength(DeclinationExplanationDialogMaxLenghtsEnum.REASON_EXPLANATION),
      ],
    });
  }
}
