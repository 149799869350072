import { Pipe, PipeTransform } from '@angular/core';
import { InterfaceEmployee } from '@xpo-ltl/sdk-disputes';
import * as _ from 'lodash';

@Pipe({
  name: 'employee',
})
export class EmployeePipe implements PipeTransform {
  transform(assignedToEmployeeDtl: InterfaceEmployee): any {
    const firstName =
      _.get(assignedToEmployeeDtl, 'firstName') !== undefined ? _.get(assignedToEmployeeDtl, 'firstName') : '';
    const lastName =
      _.get(assignedToEmployeeDtl, 'lastName') !== undefined ? _.get(assignedToEmployeeDtl, 'lastName') : '';
    return `${firstName}  ${lastName}`;
  }
}
