import { DisputeRolesEnum } from './dispute-roles.enum';

export class DisputeRolesEnumHelper {
  static values(): string[] {
    const values = [];
    for (const tab in DisputeRolesEnum) {
      if (tab) {
        values.push(DisputeRolesEnum[tab]);
      }
    }
    return values;
  }
}
