export enum DisputeFilingFormNamesEnum {
  FORM_GROUP_NAME = 'DisputeFiling',
  DISPUTE_TYPE = 'DisputeType',
  DISPUTE_RECEIVED_DATE = 'DisputeReceivedDate',
  DISPUTE_FILING_DATE = 'DisputeFilingDate',
  DISPUTE_CREATED_DATE = 'DisputeCreatedDate',
  FILED_BY = 'FiledBy',
  FILED_BY_NAME = 'FiledByName',
  FILE_METHOD = 'FileMethod',
  CUSTOMER_REFERENCE_NUMBER = 'CustomerReferenceNumber',
  DISPUTE_DETAILS = 'Dispute Details',
  INVOICE_DETAILS = 'Invoice Details',
  UPLOAD_DOCUMENTS = 'Upload Documents',
  COMPANY_OR_INDIVIDUAL = 'Company or Individual',
  DISPUTE_STATUS = 'DisputeStatus',
}
