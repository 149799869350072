export enum DocumentMimeType {
  DOC = 'msword',
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  GIF = 'gif',
  EMAIL = 'email',
  MSG = 'msg',
  EML = 'eml',
  XLS = 'vnd.ms-excel',
  XSLX = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX = 'vnd.openxmlformats-officedocument.wordprocessingml.document',
}
