import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import * as _ from 'lodash';
import { CountryPickerService, ICountry } from 'ngx-country-picker';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Country } from '../classes/country';

@Injectable()
export class AppConstantsService implements OnDestroy {
  unsubscriber = new Unsubscriber();
  countries$: Observable<ICountry[]>;
  filteredCountryNames$: Observable<string[]>;
  countryNamesSubject: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);
  countryNames$ = this.countryNamesSubject.asObservable();

  // #region isDrawerOpen

  private isDrawerOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDrawerOpen$ = this.isDrawerOpenSubject.asObservable();
  get isDrawerOpen() {
    return this.isDrawerOpenSubject.value;
  }
  set isDrawerOpen(value: boolean) {
    this.isDrawerOpenSubject.next(value);
  }

  // #endregion

  constructor(private countryPicker: CountryPickerService) {
    this.countries$ = this.countryPicker.getCountries();
    this.countries$
      .pipe(
        take(1),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((countries) => {
        let names = _.map(countries, (country: ICountry) => {
          const countryName = new Country();
          countryName.code = country.cca2;
          countryName.name = country.name.common;
          return countryName;
        });
        names.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        const index = names.map((e) => e.name).indexOf('United States');
        if (index !== -1) {
          const usa = names[index];
          names.splice(index, 1);
          names = [usa, ...names];
        }
        this.countryNamesSubject.next(names);
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
