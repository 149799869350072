<app-base-dispute-dialog
  [title]="title"
  (closeButtonClick)="handleCloseButtonClick()"
  [hideCloseDialogButton]="hideCloseDialogButton"
  [buttons]="disputeRouteGuardDialogButtons"
  (buttonClick)="handleClick($event)"
>
  <div class="confirmation-content">
    <p class="confirmation-content--message" [innerHTML]="message"></p>
  </div>
</app-base-dispute-dialog>
