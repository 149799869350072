import { Injectable, Injector } from '@angular/core';
import { DisputeShipmentCurrencyCd } from '@xpo-ltl/sdk-common';
import * as Handlebars from 'handlebars/dist/cjs/handlebars';
import * as _ from 'lodash';
import moment from 'moment-timezone';

import { HumanizePipe } from '../../pipes/humanize.pipe';
import { DisputesRegistrationService } from './disputes-registration.service';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatingService extends DisputesRegistrationService {
  private humanizePipe = new HumanizePipe();
  constructor(protected injector: Injector) {
    super(injector);
  }

  compileTemplateList(templates) {
    const templatesCompiled = [];
    _.each(templates, (template) => {
      const templateCompiled = Object.assign({}, template);
      templateCompiled.body = this.compileSingleTemplate(template.body);
      templatesCompiled.push(templateCompiled);
    });
    return templatesCompiled;
  }

  compileSingleTemplate(template) {
    const NA = 'N/A'; // displayed when the value is not found
    const dispute = this.disputeDataService.dispute;
    const currentDate = moment().format('LL');
    const claimantName = _.get(dispute, 'claimant.name1', NA);
    const claimantAdress1 = _.get(dispute, 'claimant.addr1', NA);
    const claimantAdress2 = _.get(dispute, 'claimant.addr2', '');
    const city = _.get(dispute, 'claimant.cityName', '');
    const state = _.get(dispute, 'claimant.stateCd', '');
    const zipCode = _.get(dispute, 'claimant.postalCd', '');
    const requestingOnBehalfOfName = _.get(dispute, 'onBehalfOf.name1', NA);
    const requestingOnBehalfOfAdress = _.get(dispute, 'onBehalfOf.addr1', NA);
    const disputeType = this.humanizePipe.transform(_.get(dispute, 'dispute.typeCd', NA));
    const customerRefNbr = _.get(dispute, 'dispute.customerRefNbr', NA);
    const disputeId = _.get(dispute, 'dispute.disputeId', NA);
    const totalDisputeAmountUsd = _.get(dispute, 'totalUSDRequestedAdjustmentAmount', NA);
    const totalDisputeAmountCad = _.get(dispute, 'totalCADRequestedAdjustmentAmount', NA);
    const examinerFirstNAme = _.get(this.userService, 'currentUser.givenName', '');
    const proCount = _.get(dispute, 'totalProCount', NA);
    const examinerLastName = _.get(this.userService, 'currentUser.lastName', '');
    const examinerName = examinerFirstNAme + ' ' + examinerLastName;
    const userDetails = {
      JOB_DESCRIPTION: _.get(
        this.userService,
        'loggedInEmplData.employee.employments[0].jobPositions[0].jobDescription',
        ''
      ),
      JOB_ADDRESS: _.get(this.userService, 'loggedInEmplData.employee.businessAddressLine1', ''),
      ZIP: _.get(this.userService, 'loggedInEmplData.employee.businessAddressZipCd', ''),
      CITY: _.get(this.userService, 'loggedInEmplData.employee.businessAddressCity', ''),
      STATE_CD: _.get(this.userService, 'loggedInEmplData.employee.businessAddressStateCd', ''),
      COUNTRY: _.get(this.userService, 'currentUser.countryCd', ''),
    };
    // TODO implement multiple PRO in the email template
    const shipments = this.disputeDataService.shipments.map((shipment) => {
      return {
        PRO_NUMBER: shipment.proNbr,
        REQ_ADJUSTED_AMOUNT: shipment.requestedAdjustmentDeltaAmount,
        CURRENCY: shipment.currencyCd,
        PICKUP_DATE: moment(shipment.pickupDate).format('L'),
        SHIPPER_NAME: shipment.shipperName,
        BILL_TO_NAME: shipment.billToName,
        CONSIGNEE_NAME: shipment.consigneeName,
      };
    });

    const templateData = {
      CURRENT_DATE: currentDate,
      CLAIMANT_NAME: claimantName,
      CLAIMANT_ADRESS1: claimantAdress1,
      CLAIMANT_ADRESS2: claimantAdress2 === null ? '' : claimantAdress2,
      CITY: city,
      STATE: state,
      ZIP_CODE: zipCode,
      REQ_BEHALF_OF_NAME: requestingOnBehalfOfName === claimantName ? '' : requestingOnBehalfOfName,
      REQ_BEHALF_OF_ADRESS: requestingOnBehalfOfAdress,
      DISPUTE_TYPE: disputeType,
      CUSTOMER_REF_NUMBER: customerRefNbr,
      DISPUTE_ID: disputeId,
      TOTAL_PRO_COUNT: proCount,
      TOTAL_AMT_USD: totalDisputeAmountUsd !== 0 ? DisputeShipmentCurrencyCd.USD + ' ' + totalDisputeAmountUsd : '',
      TOTAL_AMT_CAD: totalDisputeAmountCad !== 0 ? DisputeShipmentCurrencyCd.CAD + ' ' + totalDisputeAmountCad : '',
      EXAMINER_NAME: examinerName,
      SHIPMENTS: shipments,
      USER_DETAIL: userDetails,
    };

    return Handlebars.compile(template)(templateData);
  }
}
